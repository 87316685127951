



import Vue from "vue";
import moment from "moment";
import { FieldCurrencyConverter } from '@/entities/field';

// TODO refactor, move to separate classes, make it refreshable by schedule, get key from settings
let quotations = [] as any;
(async () => {
  const resp = await fetch(
    "https://api.feedler.pl/quotations?key=testing-feedler"
  );
  quotations = await resp.json();

  // TODO remove after deploying to all devices
  window.localStorage.removeItem("quotations");
})();

export default Vue.extend({
  name: "CurrencyConverterEditor",
  components: {},
  props: ["field", "item"],
  data() {
    return {};
  },
  methods: {},
  computed: {
    displayValue(): any {
      if (!this.field.currencyConverter) {
        return {text: "not configured", color: "red"};
      }

      const currencyConverter = this.field.currencyConverter as FieldCurrencyConverter;

      const targetCurrency = currencyConverter.targetCurrency.toUpperCase();
      const sourceCurrency = this.item[currencyConverter.sourceCurrencyField].toUpperCase(); // TODO check for null, field.currencyConverter.sourceCurrencyField
      const sourceField = currencyConverter.sourceField;

      if (!sourceCurrency) {
        return {text: "not configured yet", color: "red"};
      }

      if (sourceCurrency == targetCurrency) {
        // eslint-disable-next-line vue/no-mutating-props,vue/no-side-effects-in-computed-properties
        this.item[this.field.name + "&"] = 1.0 * this.item[sourceField];
        return {text: this.item[sourceField], color: ""};
      }

      const quotation = quotations.find(
        (q: any) =>
          q.name == targetCurrency + sourceCurrency ||
          q.name == sourceCurrency + targetCurrency
      );

      if (!this.item || !this.item[sourceField] || !quotation) {
        console.log("no quotation found for pair with", sourceCurrency, targetCurrency, quotations);
        return {text: "no quotation", color: "red"};
      }

      let color = "";
      const diff = moment(quotation.time).diff(moment(), "days")
      if (diff < 0) color = "orange";
      if (diff < -2) color = "red";

      const value = quotation.name.startsWith(sourceCurrency) ? this.item[sourceField] * quotation.bid : this.item[sourceField] / quotation.ask;

      // here we update a calculated field value so other parts of bluetable (and scripts) can use it
      // eslint-disable-next-line vue/no-mutating-props,vue/no-side-effects-in-computed-properties
      this.item[this.field.name + "&"] = value;
      return {text: value.toFixed(2), color};
    },
  },
});
