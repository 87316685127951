import { BaseItem } from '@/entities/base';
import { builtin, dataset, datasetIcon, datasetLabel, defaultValue, hidden, label, options, type } from '@/decorators';
import { FieldOption, FieldType } from '@/entities/field';

@datasetLabel("Files")
@datasetIcon({ icon: "mdi-file-multiple-outline" })
@dataset("files")
export class FileItem extends BaseItem {
  @label("Content type")
  @builtin
  contentType = ""

  @type(FieldType.Number)
  @builtin
  @label("Size")
  size = 0

  @label("Location type")
  @builtin
  @type("dropdown")
  @defaultValue("bluetable")
  @options([
    new FieldOption("bluetable", "bluetable"),
    new FieldOption("OneDrive", "onedrive"),
    new FieldOption("GDrive", "gdrive"),
    new FieldOption("URL", "url"),
  ])
  locationType = ""

  @label("Location")
  @builtin
  @hidden
  location = ""
}