import {
  Decoration,
  DecorationSet,
  EditorView,
  ViewPlugin,
  ViewUpdate,
  WidgetType,
} from '@codemirror/view';
import { iterateTreeInVisibleRanges, isCursorInRange } from '../../../node_modules/@retronav/ixora/dist/util';
import { Range } from '@codemirror/state';

class HorizontalRuleWidget extends WidgetType {
  toDOM(): HTMLElement {
    const dom = document.createElement('hr');
    return dom;
  }
}

const componentPlugin = ViewPlugin.fromClass(
  class {
    decorations: DecorationSet;
    constructor(view: EditorView) {
      this.decorations = this.createDecorations(view);
    }
    update(update: ViewUpdate) {
      if (
        update.docChanged ||
        update.viewportChanged ||
        update.selectionSet
      ) {
        this.decorations = this.createDecorations(update.view);
      }
    }

    private createDecorations(view: EditorView): DecorationSet {
      const widgets: Range<Decoration>[] = [];
      iterateTreeInVisibleRanges(view, {
        enter: ({ name, from, to }) => {
          if (name !== 'HorizontalRule') return;
          if (isCursorInRange(view, [from, to])) return;

          widgets.push(
            Decoration.replace({
              widget: new HorizontalRuleWidget()
            }).range(from, to)
          );
        }
      });
      return Decoration.set(widgets, true);
    }
  },
  { decorations: (v) => v.decorations }
);

export const horizontalRule = {
  componentPlugin,
}
