import { ConnectionAdapter, Item, SendChangesetResponse } from './syncEngine';
import { API } from 'aws-amplify';

export class ApiConnectionAdapter implements ConnectionAdapter {
  async sendChangeset(version: number, changedItems: Item[], limit : number): Promise<SendChangesetResponse> {
    const result = await API.post("bluetablesync", "/sync", {
      body: {
        version,
        changedItems: changedItems,
        limit,
      }
    });

    return result;
  }
}
