





import Vue from "vue";
import WidgetFrame from '@/components/widgets/WidgetFrame.vue';
import NotesFileList from '@/components/NotesFileList.vue';
import { sortByProperty } from '@/helpers';
import { ProxiedDatasetItem } from '@/sync/ProxiedDataset';
import { PowerStorage } from '@/powerStorage';

export default Vue.extend({
  name: "NotesWidget",
  components: {
    WidgetFrame,
    NotesFileList,
  },
  props: ["maxItems"],
  data() {
    return {
      storageEntity: PowerStorage.create("notes"),
    };
  },
  computed: {
    items(): ProxiedDatasetItem[] {
      if (!this.storageEntity || !this.storageEntity.items) return [];
      return this.storageEntity.items;
    },
    recentItems(): any {
      return this.items.slice().sort(sortByProperty("_updatedAt")).reverse().slice(0, this.maxItems || 5);
    },
  },
  methods: {
  },
});
