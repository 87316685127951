import { regexParserExtension } from './regex-parser-extension';
import { HighlightStyle, syntaxHighlighting } from "@codemirror/language"

const markdownExtension = regexParserExtension("mentionsLink", /(@)([\w+\-_]+)()/, "Link", /\w+@[\w.]+/)

const highlightStylePlugin = syntaxHighlighting(HighlightStyle.define([
  {
    tag: markdownExtension.resolveTag,
    color: "#48D1CC",
    textDecoration: "none"
  },
  {
    tag: markdownExtension.markTag,
    color: "#999",
    textDecoration: "none",
  }
]));

export const mentionsLink = {
  ...markdownExtension,
  highlightStylePlugin,
}