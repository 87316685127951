import {
  Decoration,
  DecorationSet,
  EditorView,
  ViewPlugin,
  ViewUpdate,
  WidgetType,
} from '@codemirror/view';
import { checkRangeOverlap, invisibleDecoration, iterateTreeInVisibleRanges, editorLines, checkRangeSubset } from '../../../node_modules/@retronav/ixora/dist/util';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import { CombinedVueInstance, ExtendedVue } from 'vue/types/vue';
import { Range, EditorState } from '@codemirror/state';
import { RangeSet, StateField } from '@codemirror/state'
import { syntaxTree } from '@codemirror/language';

function isCursorInRange(state: EditorState, range: [number, number]) {
  return state.selection.ranges.some((selection) =>
    checkRangeOverlap(range, [selection.from, selection.to])
  );
}

export const vueBlockComponentPlugin = (delimResolveName: string, delimMarkName: string, component: any) => {
  const componentClass = Vue.extend(component);

  class VueWidget extends WidgetType {
    private widget = {} as CombinedVueInstance<Record<never, any> & Vue, object, object, object, Record<keyof ExtendedVue<any, any, any, any, any>, any>>

    constructor(readonly content: string) {
      super();
    }
    toDOM(view: EditorView): HTMLElement {
      console.log("creating widget for", this.content);

      this.widget = new componentClass({
        propsData: {
          value: this.content,
        },
        vuetify,
      });
      this.widget.$mount();

      (this.widget.$el as HTMLElement).classList.add("vueComponentPlugin");

      return this.widget.$el as HTMLElement;
    }
    eq(widget: WidgetType): boolean {
      return this.content == (widget as VueWidget).content;
    }
  }

  const decorate = (state: EditorState) => {
    const widgets: Range<Decoration>[] = []

    syntaxTree(state).iterate({
      enter: ({ type, from, to }) => {
        if (type.name !== delimResolveName) return;
        if (isCursorInRange(state, [from - 1, to + 1])) return;
        if (state.selection.main.head > from && state.selection.main.head < to) return;

        widgets.push(
          Decoration.replace({
            widget: new VueWidget(state.sliceDoc(from, to)),
            block: true,
          }).range(from, to)
        );
      },
    })

    return widgets.length > 0 ? RangeSet.of(widgets) : Decoration.none
  }

  const blockField = StateField.define<DecorationSet>({
    create(state) {
      return decorate(state)
    },
    update(value, transaction) {
      if (transaction.docChanged || transaction.newSelection)
        return decorate(transaction.state)

      return value.map(transaction.changes)
    },
    provide(field) {
      return EditorView.decorations.from(field)
    },
  })


  // const vueComponentPlugin = ViewPlugin.fromClass(
  //   class {
  //     decorations: DecorationSet;
  //     constructor(view: EditorView) {
  //       this.decorations = this.createDecorations(view);
  //     }
  //     update(update: ViewUpdate) {
  //       if (
  //         update.docChanged ||
  //         update.viewportChanged ||
  //         update.selectionSet
  //       ) {
  //         this.decorations = this.createDecorations(update.view);
  //       }
  //     }

  //     private createDecorations(view: EditorView): DecorationSet {
  //       const widgets: Range<Decoration>[] = [];
  //       iterateTreeInVisibleRanges(view, {
  //         enter: ({ name, from, to }) => {
  //           if (name !== delimResolveName) return;
  //           // if (isCursorInRange(view, [from, to])) return;

  //           widgets.push(
  //             Decoration.widget({
  //               widget: new BorderWidget(),
  //               block: true,
  //             }).range(from)
  //           );

  //           return;
  //           // const lines = editorLines(view, from, to);

  //           // lines.forEach((line) => {
  //           //   const lineDec = Decoration.line({
  //           //     class: delimResolveName,
  //           //   });
  //           //   widgets.push(lineDec.range(line.from));
  //           // });

  //           // if (
  //           //   lines.every(
  //           //     (line) => !isCursorInRange(view, [line.from, line.to])
  //           //   )
  //           // ) {
  //           //   const marks = Array.from(
  //           //     view.state.sliceDoc(from, to).matchAll(/^(---)/gm)
  //           //   )
  //           //     .map((x) => from + x.index!)
  //           //     .map((i) =>
  //           //       Decoration.replace({
  //           //         widget: new BorderWidget()
  //           //       }).range(i, i + 3)
  //           //     );
  //           //   lines.forEach((line) => {
  //           //     if (
  //           //       !marks.some((mark) =>
  //           //         checkRangeSubset(
  //           //           [line.from, line.to],
  //           //           [mark.from, mark.to]
  //           //         )
  //           //       )
  //           //     )
  //           //       marks.push(
  //           //         Decoration.widget({
  //           //           widget: new BorderWidget()
  //           //         }).range(line.from)
  //           //       );
  //           //   });

  //           //   widgets.push(...marks);
  //           // }
  //         }
  //       });

  //       return widgets.length > 0 ? RangeSet.of(widgets) : Decoration.none
  //     }
  //   },
  //   { decorations: (v) => v.decorations }
  // );

  // return vueComponentPlugin;
  return blockField;
}