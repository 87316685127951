import { Subject } from 'rxjs';
import { Item, createConnectableAndConnect } from './syncEngine';
import { StorageAdapter } from "./StorageAdapter";

export abstract class ObservableStorageAdapter implements StorageAdapter {
  protected itemCreatedSubject = new Subject<Item>();
  public readonly itemCreated = createConnectableAndConnect(this.itemCreatedSubject);
  protected itemUpdatedSubject = new Subject<Item>();
  public readonly itemUpdated = createConnectableAndConnect(this.itemUpdatedSubject);
  protected itemDeletedSubject = new Subject<string>();
  public readonly itemDeleted = createConnectableAndConnect(this.itemDeletedSubject);

  abstract release(): Promise<void>;
  abstract items: Item[];
  abstract createItem(item: Item): Promise<Item>;
  abstract updateItem(item: Item): Promise<boolean>;
  abstract deleteItem(id: string): Promise<void>;
  abstract getItem(id: string, includeDeleted?: boolean | undefined): Promise<Item | null>;
  abstract getAllItems(includeDeleted?: boolean | undefined): Promise<Item[]>;
  abstract getItemsForSync(version: number): Promise<Item[]>;
  abstract getItemsByDataset(datasetName: string, includeDeleted?: boolean | undefined): Promise<Item[]>;
}
