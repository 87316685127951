








import Vue from "vue";
import router from "../../router";

export default Vue.extend({
  name: "InternalLinkWidget",
  components: {},
  props: ["value"],
  created() {
    const match = this.value.match(
      /\[\[(?<name>[^\]|]+)[|]*(?<overrideName>[^[\]]+)*\]\]/
    );
    if (!match) {
      return;
    }

    const [, name, overrideName] = match;

    this.name = name;
    this.overrideName = overrideName;
  },
  computed: {
    title(): string {
      if (this.overrideName) {
        return this.overrideName;
      }
      return this.name;
    },
  },
  data() {
    return {
      name: "",
      overrideName: "loading...",
      router,
    };
  },
  methods: {},
});
