















import Vue from "vue";
import TagsInputWidget from "@/components/widgets/TagsInputWidget.vue";

export default Vue.extend({
  name: "TagsInputDialog",
  components: { TagsInputWidget },
  data() {
    return {
      show: false,
    };
  },
});
