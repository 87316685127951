var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('span',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', 's']),expression:"['ctrl', 's']"}]}),_vm._l((_vm.datasetsEntity.items.filter(function (x) { return x.quickAccessShortcut; })),function(d){return _c('span',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['alt', d.quickAccessShortcut]),expression:"['alt', d.quickAccessShortcut]"}],key:d.name,on:{"shortkey":function($event){return _vm.$router.push(("/datasets/" + (d.name)))}}})}),_c('v-app-bar',{staticClass:"window-controls",attrs:{"app":"","color":"primary","dark":"","clipped-left":"","dense":"","height":"32px"}},[_c('v-app-bar-nav-icon',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', 'b']),expression:"['ctrl', 'b']"}],on:{"click":function($event){$event.stopPropagation();return _vm.toggleSidebar.apply(null, arguments)},"shortkey":_vm.toggleSidebar}}),_c('span',{staticClass:"title ml-3 mr-5",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/')}}},[_c('span',{staticClass:"font-weight-light"},[_vm._v("blue")]),_vm._v("table ")]),(_vm.isDev)?_c('span',[_vm._v(" developer environment")]):_vm._e(),_c('v-spacer',{staticClass:"window-controls-drag"},[_vm._v(" ")]),_c('icon-button',{attrs:{"to":"/","icon":"mdi-home","title":"Home","shortkey":{ first: ['ctrl', 'h'], second: ['alt', '`'] },"small":""}}),_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.eventBus.$emit('openNotesSearchDialog')}}},[_c('v-list-item-title',[_vm._v("Notes search")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.eventBus.$emit('openGlobalSearchDialog')}}},[_c('v-list-item-title',[_vm._v("Global search")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.eventBus.$emit('openEmbeddingsSearchDialog')}}},[_c('v-list-item-title',[_vm._v("Embeddings search")])],1)],1)],1),_vm._l((_vm.getTopLevelMenuItems()),function(item){return _c('v-menu',{key:item,staticClass:"hidden-md-and-down",attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item))])]}}],null,true)},[_c('v-list',_vm._l((_vm.datasetsEntity.items.filter(function (x) { return x.location == item; })),function(d){return _c('v-list-item',{key:d._id,attrs:{"link":"","to":("/datasets/" + (d.name))}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(
                d.icon && d.icon.icon
                  ? d.icon.icon
                  : 'mdi-file-table-box-outline'
              )}})],1),_c('v-list-item-title',[_vm._v(_vm._s(d.label))])],1)}),1)],1)}),_c('icon-button',{attrs:{"to":"/notes/notes","shortkey":['ctrl', '`'],"icon":"mdi-note-text-outline","title":"Notes","small":""}}),_c('icon-button',{attrs:{"to":"/calendar","icon":"mdi-calendar-outline","title":"Calendar","small":""}}),_c('span',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['alt', 'w']),expression:"['alt', 'w']"}],on:{"shortkey":function($event){return _vm.$router.push('/week')}}}),_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-graph-outline")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":"/graph/notes"}},[_c('v-list-item-title',[_vm._v("Notes")])],1),_c('v-list-item',{attrs:{"link":"","to":"/graph/ppv"}},[_c('v-list-item-title',[_vm._v("PPV")])],1)],1)],1),_c('icon-button',{attrs:{"to":"/dashboard/finance","icon":"mdi-finance","title":"Finance dashboard","small":""}}),_c('icon-button',{attrs:{"to":"/budget","icon":"mdi-currency-usd","title":"Budget","small":""}}),_c('icon-button',{attrs:{"to":"/datasets","exact":"","icon":"mdi-database-outline","title":"Manage datasets","small":""}}),_c('icon-button',{attrs:{"to":"/sync","icon":_vm.syncIcon,"title":"Sync status","small":""}}),_c('icon-button',{attrs:{"to":"/lab","icon":"mdi-flask-outline","title":"Lab","small":""}}),_c('span',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['alt', 'w']),expression:"['alt', 'w']"}],on:{"shortkey":function($event){return _vm.$router.push('/review/week')}}}),_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-search")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":"/review/week"}},[_c('v-list-item-title',[_vm._v("Weekly review")])],1),_c('v-list-item',{attrs:{"link":"","to":"/review/month"}},[_c('v-list-item-title',[_vm._v("Monthly review")])],1),_c('v-list-item',{attrs:{"link":"","to":"/review/quarter"}},[_c('v-list-item-title',[_vm._v("Quarterly review")])],1)],1)],1),_c('icon-button',{attrs:{"to":"/settings","icon":"mdi-cog","title":"Settings","small":""}}),_c('icon-button',{attrs:{"icon":"mdi-page-layout-sidebar-right","title":"Quick access sidebar","small":""},on:{"click":function($event){return _vm.toggleRightSidebar()}}})],2),_c('v-navigation-drawer',{attrs:{"width":"400px","app":"","right":"","clipped":"","disable-resize-watcher":""},model:{value:(_vm.showRightDrawer),callback:function ($$v) {_vm.showRightDrawer=$$v},expression:"showRightDrawer"}},[_c('sidebar-right')],1),_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","disable-resize-watcher":""},model:{value:(_vm.leftDrawer),callback:function ($$v) {_vm.leftDrawer=$$v},expression:"leftDrawer"}},[_c('v-list',[(_vm.user && _vm.user.username)?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.user.username)+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.logout}},[_c('v-icon',[_vm._v("mdi-logout")])],1)],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.datasetsEntity.items),function(d){return _c('v-list-item',{key:d._id,attrs:{"link":"","to":("/datasets/" + (d.name))}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(
              d.icon && d.icon.icon
                ? d.icon.icon
                : 'mdi-file-table-box-outline'
            )}})],1),_c('v-list-item-title',[_vm._v(_vm._s(d.label || d.name))])],1)}),1)],1),_c('v-main',{staticStyle:{"background-color":"#f0f1f2"}},[_c('div',{staticClass:"overflow-y-auto",staticStyle:{"position":"absolute","top":"0","bottom":"0","right":"0","padding":"5px","left":"0"}},[(_vm.isDev && _vm.isKeepAliveCacheEnabled)?_c('router-view',{key:_vm.routerViewKey}):_vm._e(),(!_vm.isDev || !_vm.isKeepAliveCacheEnabled)?_c('keep-alive',{attrs:{"max":5}},[_c('router-view',{key:_vm.routerViewKey})],1):_vm._e()],1)]),_c('taskbar'),_c('dataset-selector-dialog'),_c('global-search-dialog'),_c('embeddings-search-dialog'),_c('notes-search-dialog'),_c('quick-add-dialog'),_c('tags-input-dialog')],2)}
var staticRenderFns = []

export { render, staticRenderFns }