/*eslint no-prototype-builtins: "off"*/

import { entities } from './entities';
import { PowerStorage } from './powerStorage';
import { sortByProperty } from './helpers';
import { ProxiedDatasetItem } from '@/sync/ProxiedDataset';
import { ItemsManager } from '@/items';
import { Field, FieldRelationship } from '@/entities/field';

// ._id || .id for backward compatibility reasons
class RelationshipManager {
  public ready = false

  public async getRelatedItem(item: ProxiedDatasetItem, field: Field | string): Promise<ProxiedDatasetItem | null> {
    const items = await this.getRelatedItems(item, field);

    if (items.length == 0) {
      return null
    }

    return items[0];
  }

  public async getRelatedItems(item: ProxiedDatasetItem, field: Field | string): Promise<ProxiedDatasetItem[]> {
    let f: Field | undefined;

    if (typeof field === 'string' || field instanceof String) {
      f = ItemsManager.getFieldByName(item, field as string);
    } else {
      f = field;
    }

    if (!f) {
      throw new Error("no such field");
    }
    if (!f.relationship) {
      throw new Error("no relationship for a field");
    }

    const storageEntity = await PowerStorage.create(f.relationship.dataset).refresh();
    const ids = item[f.name].map((x: any) => x._id || x.id);
    return storageEntity.items.filter(x => ids.contains(x._id || x.id));
  }

  public getValueSingle(item: any, field: any) {
    // console.log("getValueSingle", item, field)
    if (!item || !item[field.name] || item[field.name].length == 0 || (item[field.name][0]._id == undefined && item[field.name][0].id == undefined)) {
      return null;
    }
    return item[field.name][0]
  }

  public getValueMultiple(item: any, field: any) {
    // console.log("getValueMultiple", item, field)
    // console.log(item[field.name])
    if (!item || !item[field.name]) {
      return [];
    }
    return item[field.name].filter((x: any) => x.id || x._id);
  }

  public getItems(relationship: FieldRelationship) {
    const items = [...PowerStorage.create(relationship.dataset).items]
      .filter((x: any) => x.hasOwnProperty(relationship.property)).sort(sortByProperty(relationship.property));
    console.log("relationship getItems", relationship.dataset, items);
    return items;
  }

  public async destroyRelationship(deletedField: Field) {
    const remoteDataset = entities.datasets.items.find(
      (x: any) => x.name == deletedField.relationship?.dataset
    );
    if (!remoteDataset) {
      console.warn("remote dataset not found");
      return;
    }

    console.log("found remote dataset", remoteDataset);

    remoteDataset.fields = remoteDataset.fields.filter(
      (f: Field) =>
        f.name != deletedField.relationship?.writeTargetProperty
    );

    await entities.datasets.save(remoteDataset);
  }

  public async removeRelationships(item: any, field: any) {
    for (const rem of item[field.name] || []) {
      const rel = PowerStorage.create(field.relationship.dataset)?.getById(rem.id || rem._id) as any
      // console.log("removing remote for", rel)
      if (!rel) { // to handle lost keys
        continue
      }

      rel[field.relationship.writeTargetProperty] = (rel[field.relationship.writeTargetProperty] || []).filter((x: any) => (x.id || x._id) != (item.id || item._id))

      await PowerStorage.create(field.relationship.dataset)?.save(rel)
    }
  }

  // TODO remove storageEntity
  public async updateRelationships(item: any, field: any, relatedItems: any, storageEntity: PowerStorage) {
    console.log("updateRelationships", { item, field, relatedItems, storageEntity });
    if (!field.relationship) {
      throw "cannot finish updateRelationship call as field.relationship is null!"
    }

    // to simplify calls
    const relationship = field.relationship as FieldRelationship;

    // filter out manually typed names as they won't be related to any reasonable item
    // this is due to the issues with handling v-autocomplete
    relatedItems = relatedItems.filter((x: any) => x && !(typeof x === 'string' || x instanceof String))

    // find items removed from field
    const removed = []
    for (const ex of (item[field.name] || [])) {
      let exists = false
      for (const rel of relatedItems) {
        if ((ex.id || ex._id) == (rel.id || rel._id)) {
          exists = true
          break
        }
      }
      if (!exists) {
        // console.log("item removed", ex)
        removed.push(ex)
      }
    }

    // local update
    item[field.name] = (relatedItems || []).map((rel: any) => {
      const targetItem = {
        id: (rel.id || rel._id)
      } as any
      targetItem[relationship.property] = rel[relationship.property];

      // add additional properties to cache fo a quicker access
      for (const property of (relationship.additionalProperties || [])) {
        targetItem[property] = rel[property];
      }

      return targetItem;
    });

    // removing items from remote entity
    for (const rem of removed) {
      const rel = PowerStorage.create(relationship.dataset)?.getById(rem.id || rem._id) as any
      // console.log("removing remote for", rel);

      // to handle errors while duplicating
      if (!rel) {
        continue;
      }

      rel[relationship.writeTargetProperty] = (rel[relationship.writeTargetProperty] || []).filter((x: any) => (x.id || x._id) != (item.id || item._id))

      await PowerStorage.create(relationship.dataset)?.save(rel)
    }

    // adding items to remote entity
    for (const relatedItem of relatedItems) {
      const rel = PowerStorage.create(relationship.dataset)?.getById(relatedItem.id || relatedItem._id) as any

      // console.log("updateRelationships UPDATED2", item[field.name]);

      //console.log("updateRelationship setting remote for", rel);
      // ensure default
      rel[relationship.writeTargetProperty] ||= [];

      // console.log("rel", rel[field.relationship.writeTargetProperty])

      // create minified version of relation with only id and selected property for display and search
      const targetItem = {
        id: item._id
      } as any
      targetItem[relationship.writeSourceProperty] = item[relationship.writeSourceProperty] || "<< empty >>"

      // add additional properties to cache them for quicker access
      for (const additionalProperty of (relationship.writeSourceAdditionalProperties || [])) {
        targetItem[additionalProperty] = item[additionalProperty] || "<< empty >>";
      }

      // check if other side of relationship supports multiple values
      if (field.relationship.writeMultiple) {
        // extend list on other side
        rel[field.relationship.writeTargetProperty] = [...rel[field.relationship.writeTargetProperty].filter((x: any) => (x.id || x._id) != targetItem._id), targetItem]
      } else {
        // remote side doesn't support multiple values, so replace the value, but first clear previous element
        for (const remove of (rel[field.relationship.writeTargetProperty] || []).filter((x: any) => (x.id || x._id) != targetItem.id)) {
          const removeEntity = storageEntity.items.find((x: any) => x._id == (remove.id || remove._id));
          if (!removeEntity) continue;

          removeEntity[field.name] = removeEntity[field.name].filter((x: any) => (x.id || x._id) != (rel.id || rel._id));

          await storageEntity.save(removeEntity);
        }

        rel[field.relationship.writeTargetProperty] = [targetItem];
      }

      await PowerStorage.create(field.relationship.dataset)?.save(rel);
    }

    await storageEntity.save(item);
  }
}

export const relationships = new RelationshipManager()