
























import { PowerStorage } from "@/powerStorage";
import { ProxiedDatasetItem } from "@/sync/ProxiedDataset";
import moment from "moment";
import Vue from "vue";
import { sortByPropertyNumber } from "../../helpers";

export default Vue.extend({
  name: "TagsMostPopularWidget",
  components: {},
  props: ["days"],
  data() {
    return {
      storageEntity: PowerStorage.create(
        "tags",
        undefined,
        undefined,
        this.days
      ),
    };
  },
  async created() {
    await this.storageEntity.refresh();
  },
  computed: {
    mostPopularTags(): any {
      const counts = this.storageEntity.items
        // we add additional filtering as by default ProxiedDataset filters only by syncTimestamp or updatedAt, but we want only items createdAt in last this.days
        .filter(i => !this.days || moment(i._createdAt).isSameOrAfter(moment().add(-this.days, "days"), "days"))
        .reduce(
        (acc: any, it: ProxiedDatasetItem) => {
          acc[it.name] = acc[it.name] + 1 || 1;
          return acc;
        },
        {}
      );

      let tags = [];

      for (const k in counts) {
        if (counts[k] < 2) {
          continue;
        }
        tags.push({
          name: k,
          value: counts[k],
        });
      }

      return tags.sort(sortByPropertyNumber("value")).reverse().slice(0, 20);
    },
  },
  methods: {
    async addTag(tag: any) {
      await this.storageEntity.create({
        name: tag.name,
        value: 1,
        time: moment().toISOString(),
      });

      this.$emit("tagAdded");
    },
  },
});
