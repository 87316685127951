



import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "TimeAgo",
  components: {},
  props: ["timestamp", "outputFormat"],
  data() {
    return {
      agoMode: true,
    };
  },
  computed: {
    displayValue(): string {
      return this.agoMode
        ? moment(this.timestamp).fromNow()
        : moment(this.timestamp).format(
            this.outputFormat ?? "HH:mm YYYY-MM-DD"
          );
    },
  },
});
