import { regexParserExtension } from './regex-parser-extension';
import { HighlightStyle, syntaxHighlighting } from '@codemirror/language';
import { vueInlineComponentPlugin } from './vue-inline-component-plugin.ts';
import InternalLinkWidget from '@/components/codemirror-plugins/InternalLinkWidget.vue';

const markdownExtension = regexParserExtension("internalLink", /(\[\[)([^\]]+)(\]\])/, "Link", /(!\[\[)([^\]]+)(\]\])/)

const highlightStylePlugin = syntaxHighlighting(HighlightStyle.define([
  {
    tag: markdownExtension.resolveTag,
    color: "lightblue",
    textDecoration: "none"
  },
  {
    tag: markdownExtension.markTag,
    color: "#999",
    textDecoration: "none",
  }
]));

const componentPlugin = vueInlineComponentPlugin(markdownExtension.delimResolveName, markdownExtension.delimMarkName, InternalLinkWidget)

export const internalLink = {
  ...markdownExtension,
  highlightStylePlugin,
  componentPlugin,
}
