import moment from 'moment/moment';

export enum FieldType {
  Text = "text",
  Number = "number",
  Dropdown = "dropdown",
  Checkbox = "checkbox",
  Relationship = "relationship",
  Reference = "reference",
  Expand = "expand",
  Rating = "rating",
  Progress = "progress",
  Parent = "parent",
  Datetime = "datetime",
  Date = "date",
  OpenItem = "openItem",
  Eval = "eval",
  Rollup = "rollup",
  Code = "code",
  Markdown = "markdown",
  Url = "url",
  Files = "files",
  Currency = "currency",
  Recurrence = "recurrence",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  DoneAt = "doneAt",
  Checklist = "checklist",
  CurrencyConverter = "currencyConverter",
  ScriptButton = "scriptButton",
  Color = "color",
}

export class Field {
  constructor(
    public name = "",
    public label = "",
    public type = "text",
    public hidden = false,
    public options = null as FieldOption[] | null,
    public relationship = null as FieldRelationship | null,
    public style = "",
    public width = "",
    public minWidth = "",
    public maxWidth = "",
    public textAlign = "",
    public readonly = false,
    public required = false,
    public expand = "",
    public defaultValue = null as any,
    public code = "",
    public rollup = null as FieldRollup | null,
    public builtin = false,
    public button = null as FieldButton | null,
    public scriptButton = null as FieldScriptButton | null,
    public recurrence = null as FieldRecurrence | null,
    public currencyConverter = null as FieldCurrencyConverter | null,
    public format = null as any,
    public summaryType = "",
  ) {
    if (!label) {
      this.label = name.charAt(0).toUpperCase() + name.slice(1);
    }
  }

  static createItemWithDefaults(obj = {} as any, fields: Field[]): any {
    for (const f of fields) {
      if (f.name in obj) {
        continue;
      }
      if (f.defaultValue) {
        console.log(
          "setting default for field",
          f.name,
          "to",
          f.defaultValue
        );

        if (f.type == "date") {
          switch (f.defaultValue) {
            case "today":
              obj[f.name] = moment().format("YYYY-MM-DD");
              break;
            case "tomorrow":
              obj[f.name] = moment().add(1, "days").format("YYYY-MM-DD");
              break;
          }
          continue;
        }

        obj[f.name] = f.defaultValue;
      }
    }

    return obj;
  }

  static create(name: string, label?: string, type = "text") {
    return new Field(name, label, type)
  }

  static createDelete() {
    return new Field("delete", " ", "delete");
  }

  static createActions() {
    return new Field("actions", " ", "actions");
  }

  static createSource() {
    return new Field("source", " ", "source");
  }

  static createParent() {
    return new Field("parent", " ", "parent");
  }

  static getSortingFunction(field: Field) {
    switch (field.type) {
      case "datetime":
      case "date":
        return (a: any, b: any): number => {
          if (a && b) {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
          }
          if (!a && b) {
            return -1;
          }
          if (a && !b) {
            return 1;
          }
          return 0;
        }

      case "dropdown":
        return (a: any, b: any): number => {
          const indexByValue = function (value: string, items: FieldOption[]): number {
            for (let i = 0; i < items.length; i++) {
              if (items[i].value == value) {
                return i;
              }
            }
            return -1;
          }

          const idxA = indexByValue(a, field.options!)
          const idxB = indexByValue(b, field.options!)

          return idxA - idxB;
        }

      default:
        return undefined;
    }
  }

  public withReadonly(readonly = true) {
    this.readonly = readonly;
    return this;
  }

  public withHidden(hidden = true) {
    this.hidden = hidden;
    return this;
  }

  public withBuiltIn(builtin = true) {
    this.builtin = builtin;
    return this;
  }

  public withDefault(defaultValue: any) {
    this.defaultValue = defaultValue;
    return this;
  }

  public withOptions(options: FieldOption[]) {
    this.type = "dropdown";
    this.options = options;
    return this;
  }

  public withButton(button: FieldButton) {
    this.type = "button";
    this.button = button;
    return this;
  }
}

export class FieldButton {
  constructor(
    public action = "",
    public text = "",
  ) {
  }
}

export class FieldRollup {
  constructor(
    public relationshipField = "",
    public remoteField = "",
    public rollupType = "",
  ) {
  }
}

export class FieldScriptButton {
  constructor(
    public scriptId = "",
    public text = "",
    public color = "",
  ) {
  }
}

export class FieldRecurrence {
  constructor(
    public repeatEvery = 0,
    public repeatUnit = "", // days, months etc.
    public repeatBasedOn = "", // specific date, field value or now
    public repeatBasedOnField = "", // specify field
    public repeatBasedOnDate = "", // specify date
    public expireType = "", // number of repeated times, specific date
    public expireCount = 0, // number for repeated times, after every duplication decrease by one
    public expireDate = "", // expire after that date
    public setStatus = "", // default status to set for duplicated item
  ) {
  }
}

export class FieldRelationship {
  constructor(
    public dataset = "",
    public property = "", // remote property to read from and save on this side
    public multiple = true,
    public writeTargetProperty = "", // property to write to on the other side
    public writeSourceProperty = "", // local property to write to the other side
    public writeMultiple = true, // property to write - does it support multiple values?
    public additionalProperties: string[] | null = [], // list of additional properties to read from the other side and cache here
    public writeSourceAdditionalProperties: string[] | null = [], // additional local properties to write to the other side
  ) {
  }
}

export class FieldOption {
  constructor(
    public label = "",
    public value = "",
    public color = "",
    public colorText = "black",
    public builtin = false,
    public statusGroup = "notStarted",
  ) {
  }
}

export class FieldCurrencyConverter {
  constructor(
    public targetCurrency = "",
    public sourceField = "",
    public sourceCurrencyField = "",
  ) {
  }
}