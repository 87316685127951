import { render, staticRenderFns } from "./Taskbar.vue?vue&type=template&id=5fb35401&scoped=true&"
import script from "./Taskbar.vue?vue&type=script&lang=ts&"
export * from "./Taskbar.vue?vue&type=script&lang=ts&"
import style0 from "./Taskbar.vue?vue&type=style&index=0&id=5fb35401&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb35401",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBottomNavigation,VBtn,VIcon})
