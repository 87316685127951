





























































import { PowerStorage } from "@/powerStorage";
import Vue from "vue";
import moment from "moment";
import { sortByProperty } from "../../helpers";

export default Vue.extend({
  name: "MetricsGroupWidget",
  components: {},
  props: [
    "title",
    "dataset",
    "sortBy",
    "metrics",
    "limit",
    "staleDays",
    "days",
    "fromDate",
    "toDate",
  ],
  data() {
    return {
      storageEntity: null as null | PowerStorage,
    };
  },
  methods: {
    formatValue(value: any) {
      if (isNaN(value)) {
        return "-";
      }
      return Math.round(value);
    },
  },
  computed: {
    items(): any {
      if (!this.storageEntity)
        return [];

      if (this.fromDate && this.toDate) {
        return this.storageEntity.items.filter((x: any) => moment(x[this.sortBy], "YYYY-MM-DD").isBetween(this.fromDate, this.toDate, "days", "[]"));
      }

      return this.storageEntity!.items;
    },
    recentItems(): any {
      return this.items
        .slice()
        .sort(sortByProperty(this.sortBy))
        .slice(-this.limit);
    },
    item(): any {
      return this.recentItems.at(-1);
    },
    fieldData(): any {
      return (fieldName: string) => {
        return (
          this.recentItems
            .map((x: any) => x[fieldName])
            .filter((x: any) => x || x == 0) || []
        );
      };
    },
    stale(): boolean {
      return this.item
        ? moment(this.item[this.sortBy], "YYYY-MM-DD").isBefore(
          moment().add(-this.staleDays || 0, "days")
        )
        : true;
    },
  },
  async created() {
    this.storageEntity = await PowerStorage.create(this.dataset, undefined, undefined, this.days).subscribe();
  },
});
