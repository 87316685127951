import { regexParserExtension } from './regex-parser-extension';
import { HighlightStyle, syntaxHighlighting } from "@codemirror/language"
import { vueInlineComponentPlugin } from './vue-inline-component-plugin.ts';
import GlobalLink from '@/components/codemirror-plugins/GlobalLink.vue';
import { CompletionContext } from "@codemirror/autocomplete"
import { search } from '@/search';

const markdownExtension = regexParserExtension("globalLink", /({{)([^}]+)(}})/, "Link");

const highlightStylePlugin = syntaxHighlighting(HighlightStyle.define([
  {
    tag: markdownExtension.resolveTag,
    color: "orange",
    textDecoration: "none"
  },
  {
    tag: markdownExtension.markTag,
    color: "#999",
    textDecoration: "none",
  }
]));

const componentPlugin = vueInlineComponentPlugin(markdownExtension.delimResolveName, markdownExtension.delimMarkName, GlobalLink)

async function createAutocompletions() {
  await search.load();

  const autocompletions = [] as any;

  for (const x of search.getNameIndex()) {
    if (!x.name) {
      return;
    }
    autocompletions.push({
      label: x.name,
      apply: `${x.id}|${x.name}`,
      type: "text",
    })
  }
  return autocompletions;
}

async function autocompletion(context: CompletionContext) {
  const word = context.matchBefore(/{{[^}]*/)
  if (!word) {
    return null;
  }
  return {
    from: word.from + 2,
    options: await createAutocompletions(),
  }
}

export const globalLink = {
  ...markdownExtension,
  componentPlugin,
  highlightStylePlugin,
  autocompletion,
}