














import Vue from "vue";

export default Vue.extend({
  name: "WidgetFrame",
  components: {},
  props: ["title", "link"],
  data() {
    return {};
  },
  methods: {},
});
