


































import Vue from "vue";
import { PowerStorage } from '@/powerStorage';
import { ProxiedDatasetItem } from '@/sync/ProxiedDataset';
import { eventBus } from "@/bus";

export default Vue.extend({
  name: "NotesSearchDialog",
  components: {},
  data() {
    return {
      show: false,
      selected: null as any,
      storageEntity: PowerStorage.create("notes"),
    };
  },
  created() {
    eventBus.$on("openNotesSearchDialog", () => this.show = true);
  },
  methods: {
    close() {
      this.show = false;
      this.selected = '';
    },
    select(item: ProxiedDatasetItem) {
      this.show = false;
      if (!item) {
        return;
      }
      this.$router.push(`/notes/notes/items/${item._id}`);
    },
  },
});
