
































import { PowerStorage } from "@/powerStorage";
import { ProxiedDatasetItem } from "@/sync/ProxiedDataset";
import Vue from "vue";
import { eventBus } from "../bus";
import ItemDialog from "./ItemDialog.vue";
import { SearchItem } from "@/search";

export default Vue.extend({
  name: "Taskbar",
  components: { ItemDialog },
  props: [],
  data() {
    return {
      items: [] as ProxiedDatasetItem[],
      currentDialogItem: null as null | ProxiedDatasetItem,
    };
  },
  created() {
    eventBus.$on("openItemDialog", this.onOpenItemDialog);
  },
  methods: {
    onItemDialogClosed() {
      this.items = this.items.filter(
        (x) => x._id != this.currentDialogItem!._id
      );
      this.currentDialogItem = null;
    },
    onItemDialogMinimized() {
      this.currentDialogItem = null;
    },
    clearItems() {
      this.items = [];
    },
    async onOpenItemDialog(requestedItem: ProxiedDatasetItem & SearchItem) {
      console.log("opening dialog for", requestedItem);

      // as item may not be loaded yet (e.g. no related dataset was loaded), we reload it
      const storage = await PowerStorage.create(requestedItem.dataset || requestedItem._dataset).refresh();
      const item = storage.getById(requestedItem.id || requestedItem._id);
      if (!item) {
        console.log("item for id", requestedItem._id || requestedItem.id, "no longer exists", item);
        return;
      }

      const dataset = (
        await PowerStorage.create("datasets").refresh()
      ).getByName(item._dataset);
      item._datasetIcon = dataset?.icon?.icon;

      this.currentDialogItem = item;
      if (!this.items.find((x) => x._id == item._id)) {
        this.items.push(item);
      }

      if(this.items.length > 6) {
        this.items.shift();
      }
    },
  },
});
