import { CompletionContext } from "@codemirror/autocomplete"

export function tagsAutocompletion(f: any) {
  return (context: CompletionContext) => {
    const word = context.matchBefore(/#[^\s]*/)
    if (!word) {
      return null;
    }
    // if (word.from == word.to && !context.explicit)
    //   return null
    return {
      from: word.from + 1,
      options: f(),
      // options:
      //   [
      //     { label: "match", type: "keyword" },
      //     { label: "hello", type: "variable", info: "(World)" },
      //     { label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro" }
      //   ]
    }
  }
}
