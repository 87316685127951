











import Vue from "vue";

export default Vue.extend({
  name: "UrlEditor",
  components: {},
  props: ["value"],
  data() {
    return {
      internalValue: this.value,
    };
  },
  methods: {
    openUrl() {
      window.open(this.internalValue);
    }
  },
});
