

























































































import { PowerStorage } from "@/powerStorage";
import Vue from "vue";
import { relationships } from "../relationships";
import { sortByProperty } from '../helpers';
import { ProxiedDatasetItem } from "@/sync/ProxiedDataset";
import { eventBus } from "@/bus";
import { ItemsManager } from '@/items';

export default Vue.extend({
  name: "RelationshipEditor",
  components: {},
  props: ["field", "item", "expandedField", "storageEntity", "singleItemView"],
  data() {
    return {
      searchInput: null as null | string,
      relationships,
      items: [] as any[],
      relationshipStorageEntity: null as null | PowerStorage,
    };
  },
  async created() {
    this.relationshipStorageEntity = await PowerStorage.create(this.field.relationship.dataset).refresh()
    this.items = this.relationshipStorageEntity.items
      .filter((x: any) => x[this.field.relationship.property] != undefined)
      .sort(sortByProperty(this.field.relationship.property));
  },
  methods: {
    openItemDialog(item: ProxiedDatasetItem) {
      eventBus.$emit("openItemDialog", item);
    },
    getParentPrefix(item: ProxiedDatasetItem) {
      const parentFields = ItemsManager.getFieldsForDataset(this.relationshipStorageEntity!.dataset, "parent");
      if (parentFields.length != 1) {
        return "";
      }

      const parentFieldName = parentFields[0].name;

      let prefix = "";

      let currItem: any = item;
      while (currItem != null) {
        try {
          const parentId = currItem[parentFieldName];
          if (!parentId) {
            break;
          }

          const parent = this.relationshipStorageEntity!.getByIds(parentId);
          if (!parent || parent.length == 0) {
            break;
          }

          prefix = parent[0][this.field.relationship.property] + " / " + prefix;
          currItem = parent[0];
        } catch (e) {
          console.warn(e)
        }
      }

      return prefix;
    }
  },
});
