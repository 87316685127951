

















































































































































































































































import { scopeEval } from "@/helpers";
import { PowerStorage } from "@/powerStorage";
import Vue from "vue";
import { relationships } from "../relationships";
import DateTimePicker from "@/components/DateTimePicker.vue";
import UrlEditor from "@/components/UrlEditor.vue";
import CurrencyEditor from "./CurrencyEditor.vue";
import RecurrenceEditor from "./RecurrenceEditor.vue";
import ReadOnlyDateEditor from "./ReadOnlyDateEditor.vue";
import RelationshipEditor from "./RelationshipEditor.vue";
import DropdownEditor from "./DropdownEditor.vue";
import FilesEditor from "./FilesEditor.vue";
import NameEditor from "./NameEditor.vue";
import CurrencyConverterEditor from "./CurrencyConverterEditor.vue";
import IconEditor from '@/components/IconEditor.vue';
import ScriptButton from "@/components/editors/ScriptButton.vue";
import RollupEditor from "@/components/RollupEditor.vue";
import ColorPicker from '@/components/ColorPicker.vue';
import { Field, FieldOption } from '@/entities/field';

export default Vue.extend({
  name: "FieldEditor",
  components: {
    ColorPicker,
    RollupEditor,
    DateTimePicker,
    UrlEditor,
    CurrencyEditor,
    RecurrenceEditor,
    ReadOnlyDateEditor,
    RelationshipEditor,
    DropdownEditor,
    FilesEditor,
    NameEditor,
    CurrencyConverterEditor,
    IconEditor,
    ScriptButton,
  },
  props: ["field", "item", "storageEntity", "expandedField", "singleItemView"],
  data() {
    return {
      relationships,
    };
  },
  computed: {
    label(): any {
      return this.singleItemView ? this.field.label || this.field.name : "";
    },
  },
  methods: {
    focus() {
      const r = this.$refs["editor"] as any;
      if (r && r.focus) {
        r.focus();
      }
      if (r && r.el && r.el.focus) {
        r.el.focus();
      }
    },
    getChildrenCounts(item: any) {
      const children = this.storageEntity.items.filter(
        (x: any) => x.parent == item._id
      );

      const statusField = this.storageEntity.fields.find(
        (f: Field) => f.name == "status"
      );
      if (!statusField || !statusField.options) {
        return [];
      }

      return statusField.options
        .map((fo: FieldOption) => {
          return {
            ...fo,
            count: children.filter((c: any) => c.status == fo.value).length,
          };
        })
        .filter((x: any) => x.count > 0);
    },
    executeScopedEval(scope: any, code: string) {
      try {
        return scopeEval(scope, code);
      } catch (e) {
        return e;
      }
    },
  },
});
