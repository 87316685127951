




import Vue from "vue";
import { defaultValueCtx, Editor, rootCtx } from "@milkdown/core";
import { nord } from "@milkdown/theme-nord";
import { commonmark } from "@milkdown/preset-commonmark";
import { gfm } from "@milkdown/preset-gfm";
import { listener, listenerCtx } from "@milkdown/plugin-listener";
import { history } from "@milkdown/plugin-history";
import { clipboard } from "@milkdown/plugin-clipboard";
import { slash } from "@milkdown/plugin-slash";
import { prism } from "@milkdown/plugin-prism";
// import { block } from "@milkdown/plugin-block";
import { indent } from "@milkdown/plugin-indent";
import { cursor } from "@milkdown/plugin-cursor";
import { debounce } from "@/helpers";
import { upload, uploadPlugin, Uploader } from "@milkdown/plugin-upload";
import type { Node } from "prosemirror-model";
import { Storage } from "aws-amplify";
import internalLink from "@/components/milkdown-plugins/internal-link";

async function uploadFile(file: File) {
  const upload = await Storage.put(
    `files/tmp/${file.name}`,
    // `files/${this.datasetName}/${this.itemId}/${file.name}`,
    file,
    {
      contentType: file.type,
    }
  );

  const url = await Storage.get(upload.key);

  return url;
}

export default Vue.extend({
  name: "MilkdownEditor",
  props: ["value"],
  data() {
    return {
      markdown: "",
    };
  },
  beforeDestroy() {
    if (this.markdown) {
      this.$emit("input", this.markdown);
    }
  },
  mounted() {
    const f = (m: string) => this.$emit("input", m);
    const emitDebounced = debounce(f, 2000);

    const uploader: Uploader = async (files, schema) => {
      const images: File[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (!file) {
          continue;
        }

        // You can handle whatever the file type you want, we handle image here.
        if (!file.type.includes("image")) {
          continue;
        }

        images.push(file);
      }

      const nodes: Node[] = await Promise.all(
        images.map(async (image) => {
          const src = await uploadFile(image);
          const alt = image.name;
          return schema.nodes.image.createAndFill({
            src,
            alt,
          }) as Node;
        })
      );

      return nodes;
    };

    Editor.make()
      .config((ctx) => {
        ctx.set(rootCtx, this.$refs.editor as any);
        if (this.value) {
          ctx.set(defaultValueCtx, this.value);
        }
        ctx.get(listenerCtx).markdownUpdated((ctx, markdown, prevMarkdown) => {
          this.markdown = markdown;
          emitDebounced(markdown);
        });
      })
      .use(nord)
      .use(commonmark)
      .use(gfm)
      .use(listener)
      .use(history)
      .use(clipboard)
      .use(slash)
      .use(prism)
      // .use(block)
      .use(indent)
      .use(cursor)
      .use(
        upload.configure(uploadPlugin, {
          uploader,
        })
      )
      .use(internalLink)
      .create();
  },
});
