import { CompletionContext } from "@codemirror/autocomplete"
import { PowerStorage } from '../../powerStorage';

async function createAutocompletions() {
  const storage = await PowerStorage.create("notes").refresh(true);

  return storage.items
    .filter((x: any) => x.name)
    .map((x: any) => ({
      label: x.name,
      apply: x.name,
      type: "text",
    }));

  // const autocompletions = [] as any;
  // PowerStorage.allById.forEach((v, k) => {
  //   if (!v.name) {
  //     return;
  //   }
  //   autocompletions.push({
  //     label: v.name,
  //     apply: v.name,
  //     type: "text",
  //   })
  // });
  // return autocompletions;
}

export async function internalLinkAutocompletion(context: CompletionContext) {
  const word = context.matchBefore(/\[\[[^\]]*/)
  if (!word) {
    return null;
  }
  // if (word.from == word.to && !context.explicit)
  //   return null
  return {
    from: word.from + 2,
    options: await createAutocompletions(),
    // options: autocompletions,
    // [
    //   { label: "match", type: "keyword" },
    //   { label: "hello", type: "variable", info: "(World)" },
    //   { label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro" }
    // ]
  }
}
