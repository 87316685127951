import { syntaxTree } from '@codemirror/language';
import {
  Decoration,
  DecorationSet,
  EditorView,
  ViewPlugin,
  ViewUpdate,
  WidgetType,
} from '@codemirror/view';
import { checkRangeOverlap, invisibleDecoration } from '../../../node_modules/@retronav/ixora/dist/util';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import { CombinedVueInstance, ExtendedVue } from 'vue/types/vue';

export const vueInlineComponentPlugin = (delimResolveName: string, delimMarkName: string, component: any) => {
  const componentClass = Vue.extend(component);

  class VueWidget extends WidgetType {
    private widget = {} as CombinedVueInstance<Record<never, any> & Vue, object, object, object, Record<keyof ExtendedVue<any, any, any, any, any>, any>>

    constructor(readonly content: string) {
      super();
    }
    toDOM(view: EditorView): HTMLElement {
      // console.log("creating widget for", this.content);

      this.widget = new componentClass({
        propsData: {
          value: this.content,
        },
        vuetify,
      });
      this.widget.$mount();

      (this.widget.$el as HTMLElement).classList.add("vueComponentPlugin");

      return this.widget.$el as HTMLElement;
    }
    eq(widget: WidgetType): boolean {
      return this.content == (widget as VueWidget).content;
    }
  }

  function getComponentAnchor(view: EditorView) {
    const widgets = [] as any;

    for (const { from, to } of view.visibleRanges) {
      syntaxTree(view.state).iterate({
        from,
        to,
        enter: ({ type, from, to, node }) => {
          if (type.name !== delimResolveName) return;
          const parent = node.parent;
          // FIXME: make this configurable
          const blackListedParents = ['Image'];
          if (parent && !blackListedParents.includes(parent.name)) {
            const marks = parent.getChildren(delimMarkName);
            const ranges = view.state.selection.ranges;
            const cursorOverlaps = ranges.some(({ from, to }) =>
              checkRangeOverlap([from, to], [parent.from, parent.to])
            );
            if (!cursorOverlaps) {
              widgets.push(
                ...marks.map(({ from, to }) =>
                  invisibleDecoration.range(from, to)
                ),
                invisibleDecoration.range(from, to)
              );
            }

            const dec = Decoration.widget({
              widget: new VueWidget(
                view.state.sliceDoc(from, to)
              ),
              side: 1
            });
            widgets.push(dec.range(to, to));
          }
        }
      });
    }

    return Decoration.set(widgets, true);
  }

  const vueComponentPlugin = ViewPlugin.fromClass(
    class {
      decorations: DecorationSet = Decoration.none;
      constructor(view: EditorView) {
        this.decorations = getComponentAnchor(view);
      }
      update(update: ViewUpdate) {
        if (
          update.docChanged ||
          update.viewportChanged ||
          update.selectionSet
        )
          this.decorations = getComponentAnchor(update.view);
      }
    },
    { decorations: (v) => v.decorations }
  );

  return vueComponentPlugin;
}