




import {runUserCode} from "@/evaluator";
import {PowerStorage} from "@/powerStorage";
import Vue from "vue";

export default Vue.extend({
  name: "ScriptButton",
  components: {},
  props: ["text", "color", "scriptId", "item"],
  data() {
    return {
      running: false,
    };
  },
  methods: {
    async runScript() {
      this.running = true;
      console.log("executing script", this.scriptId, "for item", this.item);

      try {
        const code = (
          (await PowerStorage.create("scripts").refresh()).getById(this.scriptId)
        )?.content;

        if (!code) {
          console.log("script not found")
        }

        await runUserCode(code, {item: this.item});
      } finally {
        this.running = false;
      }
    },
  },
});
