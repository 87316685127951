import { PowerStorage } from '@/powerStorage';
import { ProxiedDatasetItem } from '@/sync/ProxiedDataset';

export class TemplatesManager {
  private storageEntity = PowerStorage.create("templates");

  public async saveAsTemplate(item: ProxiedDatasetItem) {
    const template = {} as any;

    console.log("copying ", item);

    // TODO copy using json serialization to avoid assigning references

    for (const key of Object.keys(item)) {
      if (["targetDataset"].includes(key)) {
        continue;
      }
      if (key.startsWith("_") || key.endsWith("$")) {
        continue;
      }

      // TODO exclude items from template settings as the one that shouldn't be copied
      // TODO clear checklists both on dedicated fields and on content field

      template[key] = item[key];
    }

    template.targetDataset = item._dataset;

    await this.storageEntity.create(template);
  }

  async applyTemplate(sourceTemplateItem: any, targetItem : any) {
    console.log("applying template", sourceTemplateItem);
    const template = JSON.parse(JSON.stringify(sourceTemplateItem));

    for (const key of Object.keys(template)) {
      if (["targetDataset", "name", "isTrusted"].includes(key)) {
        continue;
      }
      if (key.startsWith("_") || key.endsWith("$")) {
        continue;
      }

      // TODO exclude items from template settings as the one that shouldn't be copied
      // TODO clear checklists both on dedicated fields and on content field

      // ugly as we mutate prop, probably needs refactor
      targetItem[key] = template[key];
    }
  }
}

export const templatesManager = new TemplatesManager();