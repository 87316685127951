




































import Vue from "vue";
import { Storage } from "aws-amplify";

export default Vue.extend({
  name: "FilesEditor",
  components: {},
  props: ["value", "itemId", "datasetName"],
  data() {
    return {
      internalValue: this.value || [],
      progressBarActive: false,
      progress: 0,
    };
  },
  methods: {
    async openFile(file: any) {
      const url = await Storage.get(file.key);
      window.open(url, "_blank");
    },
    async removeFile(file: any) {
      await Storage.remove(file.key);
      this.internalValue = this.internalValue.filter(
        (x: any) => x.key != file.key
      );

      this.$emit("change", this.internalValue);
    },
    async filesSubmitted(files: File[]) {
      for (const file of files) {
        console.log("processing", file.name);

        this.progressBarActive = true;
        this.progress = 0;

        try {
          const upload = await Storage.put(
            `files/${this.datasetName}/${this.itemId}/${file.name}`,
            file,
            {
              contentType: file.type,
              progressCallback: (progress) => {
                this.progress = (progress.loaded / progress.total) * 100;
              },
            }
          );

          this.internalValue.push({
            key: upload.key,
            name: file.name,
            size: file.size,
            contentType: file.type,
          });
        } finally {
          this.progressBarActive = false;
        }
      }

      console.log(this.internalValue);

      this.$emit("change", this.internalValue);
    },
  },
});
