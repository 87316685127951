import { InputRule } from 'prosemirror-inputrules';
import { AtomList, createNode } from "@milkdown/utils";
import { RemarkPlugin } from '@milkdown/core';
import directive from 'remark-directive';

const id = 'iframe';
const iframe = createNode(() => ({
  id,
  schema: () => ({
    inline: true,
    attrs: {
      src: { default: null },
    },
    group: 'inline',
    marks: '',
    parseDOM: [
      {
        tag: 'iframe',
        getAttrs: (dom) => {
          if (!(dom instanceof HTMLElement)) {
            throw new Error();
          }
          return {
            src: dom.getAttribute('src'),
          };
        },
      },
    ],
    toDOM: (node) => ['iframe', { ...node.attrs, class: 'iframe' }, 0],
    toMarkdown: {
      match: (node) => node.type.name === id,
      runner: (state, node) => {
        state.addNode('textDirective', undefined, undefined, {
          name: 'iframe',
          attributes: {
            src: node.attrs.src,
          },
        });
      },
    },
    parseMarkdown: {
      match: (node) => node.type === 'textDirective' && node.name === 'iframe',
      runner: (state, node, type) => {
        state.addNode(type, { src: (node.attributes as { src: string }).src });
      },
    },
    inputRules: (nodeType: any) => [
      new InputRule(/:iframe\{src="(?<src>[^"]+)?"?\}/, (state, match, start, end) => {
        const [okay, src = ''] = match;
        const { tr } = state;
        if (okay) {
          tr.replaceWith(start, end, nodeType.create({ src }));
        }

        return tr;
      }),
    ],
  }),
  remarkPlugins: () => [directive as RemarkPlugin],
}));

const AST = {
  name: 'iframe',
  attributes: { src: 'https://saul-mirone.github.io' },
  type: 'textDirective',
};


const iframePlugin = AtomList.create([iframe()]);

export default iframePlugin;