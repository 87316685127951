import { connectable, ObservableInput } from 'rxjs';

export interface Item {
  syncTimestamp?: number
  syncNeeded?: number
  id: string
  dataset: string
  fields: any
  fieldsUpdatedAt: any
  createdAt: number
  updatedAt: number
  deletedAt?: number
  version: number
}

export const isSyncNeeded = (item:Item) =>{
  return item.syncNeeded;
}

export const markItemForSync = (item: Item) => {
  item.syncNeeded = Math.min((item.syncNeeded || 0) + 1, 2);
}

export const markItemSynced = (item: Item) => {
  item.syncNeeded = (item.syncNeeded || 0) - 1;
  if (item.syncNeeded <= 0) {
    item.syncNeeded = undefined; // so it's not saved to IndexedDB index
  }
}

let timestampOffset = 0;

export function getTimestampOffset() {
  return timestampOffset;
}

export function setTimestampOffset(serverTimestamp: number) {
  timestampOffset = serverTimestamp - (new Date().getTime());
  // console.log("updating timestamp offset", timestampOffset);
}

export function getCurrentTimestamp(): number {
  return new Date().getTime() + timestampOffset;
}

export const createConnectableAndConnect = <T>(input: ObservableInput<T>) => {
  const c = connectable(input);
  c.connect();
  return c;
}

export interface SendChangesetResponse {
  changedItems: Item[]
  version: number
  serverTimestamp: number,
  trimmed: boolean
}

export interface ConnectionAdapter {
  sendChangeset(version: number, changedItems: Item[], limit : number): Promise<SendChangesetResponse>;
}