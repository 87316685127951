



import Vue from "vue";

export default Vue.extend({
  name: "TagWidget",
  components: {},
  props: ["value"],
});
