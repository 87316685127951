import { regexParserExtension } from './regex-parser-extension';
import { HighlightStyle, syntaxHighlighting } from "@codemirror/language"
import { vueInlineComponentPlugin } from './vue-inline-component-plugin.ts';
import TagWidget from "./TagWidget.vue";

const markdownExtension = regexParserExtension("tagsLink", /(?!https*:\/\/\S+)(#)(\d*[AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻża-zA-Z\-._]+\d*)()/, "Link", /http.:\/\/.*#/);

const highlightStylePlugin = syntaxHighlighting(HighlightStyle.define([
  {
    tag: markdownExtension.resolveTag,
    color: "pink",
    textDecoration: "none"
  },
  {
    tag: markdownExtension.markTag,
    color: "#999",
    textDecoration: "none",
  }
]));

const componentPlugin = vueInlineComponentPlugin(markdownExtension.delimResolveName, markdownExtension.delimMarkName, TagWidget)

export const tagsLink = {
  ...markdownExtension,
  highlightStylePlugin,
  componentPlugin,
}