





import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  name: "ReadOnlyDateEditor",
  components: {},
  props: ["value"],
  data() {
    return {};
  },
  computed: {
    internalValue() {
      if (!this.value) {
        return "not set";
      }
      return moment(this.value).fromNow();
    },
    getStyle() {
      if (!this.value) {
        return "color: #ddd";
      }
      return "";
    },
  },
  methods: {},
});
