
























import Vue from "vue";
import EditorJs from "@/components/EditorJs.vue";
import MilkdownEditor from "@/components/MilkdownEditor.vue";
import { ItemsManager } from "@/items";
import CodeMirrorEditor from "@/components/CodeMirrorEditor.vue";

export default Vue.extend({
  name: "ContentEditor",
  components: {
    EditorJs,
    MilkdownEditor,
    CodeMirrorEditor,
  },
  props: ["item", "tagsAutocompletionFunction"],
  data() {
    return {};
  },
  methods: {
    async onInput(e: any) {
      // we're not using itemsManager.updateItem as we're sure the fields updated are not status one or any relationship one, also we're updating multiple fields which is not supported by updateItem method

      // eslint-disable-next-line
      this.item.content = e;

      if (
        this.activeEditor == "milkdown" ||
        this.activeEditor == "codemirror"
      ) {
        const tagsRegex = /(?<!\S)(?<!#)(?<!\/)(?<!\\)(?:#)([^\s#]+)/gm;
        const matches = [...e.matchAll(tagsRegex)];
        const tags = matches.map((m) => m[1]);

        console.log("detected tags", tags);

        // eslint-disable-next-line
        this.item.contentTags$ = [...new Set(tags)];
      }

      // eslint-disable-next-line vue/no-mutating-props
      this.item.embeddingsCalculationRequired$ = this.item.content != null && this.item.content.length > 1;

      // console.log("CME onInput", e);

      await ItemsManager.saveItem(this.item);
    },
    onFileUploaded(e: any) {
      console.log("on file uploaded", e);
      // eslint-disable-next-line
      this.item.attachments ||= [];
      // eslint-disable-next-line
      this.item.attachments.push(e);
    },
  },
  computed: {
    activeEditor(): string {
      if (
        this.item &&
        this.item.content &&
        typeof this.item.content === "object"
      ) {
        return "editorjs";
      } else {
        return "codemirror";
      }
    },
  },
});
