import { getCurrentTimestamp, Item, SendChangesetResponse, markItemForSync, markItemSynced } from './syncEngine';
import { StorageAdapter } from "./StorageAdapter";

export class SyncServer {

  constructor(readonly storageAdapter: StorageAdapter) {
  }

  public async processChanges(version: number, changedItems: Item[], limit = 0): Promise<SendChangesetResponse> {
    const currentTimestamp = getCurrentTimestamp();
    const confirmChange = [] as Item[];

    for (const changedItem of changedItems) {
      // process by item
      // console.log("processChanges for item", changedItem);

      // get existing item data
      let item = await this.storageAdapter.getItem(changedItem.id, true);

      if (item && item.deletedAt) {
        console.log("Sync: ignoring changes for item", item, "as it was previously deleted");
        // acknowledge to the client that it can remove it from local db
        confirmChange.push(item);
        continue;
      }

      if (!item) {
        if (changedItem.deletedAt) {
          console.log("Sync: confirming deletion for item", item);
          // here server acknowledges all items that were created and deleted on client side before the sync happened
          confirmChange.push(changedItem);
          continue;
        }
        changedItem.syncTimestamp = currentTimestamp;
        markItemSynced(changedItem);
        item = await this.storageAdapter.createItem(changedItem);
        console.log("Sync: processChanges created new item", item);
        continue;
      }

      if (changedItem.deletedAt) {
        item.deletedAt = changedItem.deletedAt;

        item.syncTimestamp = currentTimestamp;
        markItemForSync(item);

        await this.storageAdapter.updateItem(item);
        continue;
      }

      let changesMade = false;
      // process each field changes
      for (const f in changedItem.fields) {
        if (f.startsWith("_")) continue; // ignore meta fields

        // console.log("processChanges for field", f);

        // if field change timestamp is newer than existing - update that field for item and add to accepted changes
        if (!item || !item.fields[f] || !item.fieldsUpdatedAt[f] || changedItem.fieldsUpdatedAt[f] > item.fieldsUpdatedAt[f]) {
          if (JSON.stringify(item.fields[f]) == JSON.stringify(changedItem.fields[f])) { // a little ugly...
            continue;
          }

          // console.log("updating field", f, "from >", item.fields[f], "< to >", changedItem.fields[f], "<");
          // update field
          item.fields[f] = changedItem.fields[f];
          item.fieldsUpdatedAt[f] = changedItem.fieldsUpdatedAt[f];

          if (!f.endsWith("$")) {
            item.updatedAt = Math.max(item.updatedAt, changedItem.fieldsUpdatedAt[f]);
          }

          changesMade = true;
        }
      }

      if (!changesMade && !item.syncNeeded) {
        confirmChange.push(changedItem);
        continue;
      }

      console.log("Sync: clearing syncNeeded for", item.id);
      item.syncTimestamp = currentTimestamp;
      markItemSynced(item);

      // save modified items to storage

      const updateResult = await this.storageAdapter.updateItem(item);
      if (!updateResult)
        throw new Error("Sync: error while updating item " + item.id);
    }

    // query storage for changes that happened on the server from last sync time and queue them in outbox
    let serverChangedItems = await this.storageAdapter.getItemsForSync(version);
    let trimmed = false;

    if(limit > 0 && serverChangedItems.length > limit) {
      console.log("number of items returned above limit, trimming")
      serverChangedItems = serverChangedItems.slice(0, limit)
      trimmed = true;
    }

    // confirm that the items were processed by the server so client can mark them as synced
    serverChangedItems.push(...confirmChange);

    console.log("Sync: processChanged generated changed items", serverChangedItems);

    // notify storage adapter that it's no longer needed
    await this.storageAdapter.release();

    const newVersion = Math.max(...serverChangedItems.map((i: Item) => i.version), version) - (trimmed ? 1 : 0); // fix to send previous version if it's possible partial result

    // return outbox to caller
    return {
      changedItems: serverChangedItems,
      version: newVersion || version,
      serverTimestamp: getCurrentTimestamp(),
      trimmed,
    }
  }
}
