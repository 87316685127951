// eslint-disable-next-line
// @ts-ignore
import cron from 'cron-scheduler';
import { PowerStorage } from '@/powerStorage';
import { generateEmbeddingsForItem } from '@/embeddings';

// TODO add some mechanism for preventing multiple runs

export const setup = () => {
  // scheduleJob("* * * * *", () => {
  //   console.log('this will run every minute')
  // });

  scheduleJob("0 0 * * *", () => {
    window.location.reload();
  });

  // TODO add some randomization as the below schedule can cause all clients to generate embeddings at the same time
  scheduleJob("*/5 * * * *", async () => {
    const storageEntity = await PowerStorage.create("notes").refresh();

    for (const item of storageEntity.items.filter(i => i.embeddingsCalculationRequired$ || !i.embeddings$)) {
      console.log("recalculating embeddings for item", item._id, item.name);

      item.embeddings$ = await generateEmbeddingsForItem(item);
      item.embeddingsCalculationRequired$ = undefined;
    }
  });
}

export const scheduleJob = (cronExpression: string, func: any) => {
  cron({ on: cronExpression }, func);
}