import { BaseItem, DatasetItem } from '@/entities/base';
import { builtin, dataset, datasetIcon, datasetLabel, hidden, label, type } from '@/decorators';
import { FieldType } from '@/entities/field';

@datasetLabel("Weekly reviews")
@datasetIcon({ icon: "mdi-calendar-search" })
@dataset("weekly-reviews")
export class WeeklyReview extends BaseItem  {
  @type(FieldType.Checklist)
  @label("Checklist")
  @hidden
  checklist = null
}

@datasetLabel("Monthly reviews")
@datasetIcon({ icon: "mdi-calendar-search" })
@dataset("monthly-reviews")
export class MonthlyReview extends BaseItem  {
  @type(FieldType.Checklist)
  @label("Checklist")
  @hidden
  checklist = null
}

@datasetLabel("Quarterly reviews")
@datasetIcon({ icon: "mdi-calendar-search" })
@dataset("quarterly-reviews")
export class QuarterlyReview extends BaseItem  {
  @type(FieldType.Checklist)
  @label("Checklist")
  @hidden
  checklist = null
}
