































import Vue from "vue";
import { FieldOption } from '@/entities/field';

export default Vue.extend({
  name: "DropdownEditor",
  components: {},
  props: ["value", "options", "solo", "dot"],
  data() {
    return {
      internalValue: this.value || "",
    };
  },
  watch: {
    value()  {
      this.internalValue = this.value;
    }
  },
  computed: {
    getBackground(): string {
      const selectedOption = this.options.find(
        (x: any) => x.value == (this.internalValue.value || this.internalValue) // to support return-object for v-select
      ) as FieldOption;

      if (selectedOption && selectedOption.color) {
        return selectedOption.color;
      }

      return "";
    },
    isFontWhite(): boolean {
      const selectedOption = this.options.find(
        (x: any) => x.value == (this.internalValue?.value || this.internalValue) // to support return-object for v-select
      ) as FieldOption;

      if (selectedOption && selectedOption.color) {
        return selectedOption.colorText == "white";
      }

      return false;
    },
  },
});
