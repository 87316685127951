import { CompletionContext, Completion } from "@codemirror/autocomplete"
import { EditorView } from 'codemirror';
import moment from 'moment';
import { from } from 'rxjs';

function createCommand(name: string, fn: (view: EditorView, completion: Completion, from: number, to: number) => void, type?: string) {
  return {
    label: name,
    apply: (view: EditorView, completion: Completion, from: number, to: number) => {
      view.dispatch({
        changes: {
          from: from - 1,
          to: to,
        },
      });
      fn(view, completion, from - 1, to -1);
    },
    type: type || "method",
  }
}

function createInsertCommand(name : string, insert :string) {
  return createCommand(name, (v, c, f, t) => {
    v.dispatch({
      changes: {
        from: f,
        insert,
      },
    });
  }, "text");
}

async function createAutocompletions() {
  return [
    createInsertCommand("task", "- [ ] "),
    createInsertCommand("now", moment().format("yyyy-MM-DD HH:mm:ss")),
    createInsertCommand("today", moment().format("yyyy-MM-DD")),
    createInsertCommand("code", "\n```\n\n```\n"),
    createCommand("line-to-action-item", (v, c, f, t) => {
      const line = v.state.doc.lineAt(f);
      const lineText = line.text.trim();

      console.log("converting current line to action item", line);
    }),
  ];
}

async function autocompletion(context: CompletionContext) {
  const word = context.matchBefore(/\/[^\s]*/)
  if (!word) {
    return null;
  }
  return {
    from: word.from + 1,
    options: await createAutocompletions(),
  }
}

export const slashCommand = {
  autocompletion,
}