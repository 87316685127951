import { regexParserExtension } from './regex-parser-extension';
import { HighlightStyle, syntaxHighlighting } from '@codemirror/language';
import { vueInlineComponentPlugin } from './vue-inline-component-plugin.ts';
import AttachmentLinkWidget from './AttachmentLinkWidget.vue';

const markdownExtension = regexParserExtension("attachment", /(!\[\[)([^\]]+)(\]\])/, "Image")

const highlightStylePlugin = syntaxHighlighting(HighlightStyle.define([
  {
    tag: markdownExtension.resolveTag,
    color: "darkred",
    textDecoration: "none"
  },
  {
    tag: markdownExtension.markTag,
    color: "#999",
    textDecoration: "none",
  }
]));

const componentPlugin = vueInlineComponentPlugin(markdownExtension.delimResolveName, markdownExtension.delimMarkName, AttachmentLinkWidget)

export const attachmentLink = {
  ...markdownExtension,
  highlightStylePlugin,
  componentPlugin,
}
