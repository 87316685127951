









import Vue from "vue";
import { Storage } from "aws-amplify";

export default Vue.extend({
  name: "AttachmentLinkWidget",
  components: {},
  props: ["value"],
  async created() {
    const match = this.value.match(
      /!\[\[(?<name>[^\]|]+)[|]*(?<overrideName>[^[\]]+)*\]\]/
    );
    if (!match) {
      return;
    }

    const [, name, overrideName] = match;

    this.name = name;
    this.overrideName = overrideName;

    if (this.isImage) {
      this.imageSrc = await Storage.get(this.name);
    }
  },
  computed: {
    title(): string {
      if (this.overrideName) {
        return this.overrideName;
      }
      return this.name;
    },
    icon(): string {
      if (this.name.endsWith(".pdf")) return "mdi-file-pdf-box";
      if (this.name.endsWith(".zip")) return "mdi-folder-zip";
      return "mdi-file";
    },
    isImage(): boolean {
      if (this.name.endsWith(".png")) return true;
      return false;
    },
  },
  data() {
    return {
      name: "",
      overrideName: "loading...",
      imageSrc: "",
    };
  },
  methods: {
    async download() {
      const url = await Storage.get(this.name);
      window.open(url, "_blank");
    },
  },
});
