



































import Vue from "vue";
import { search, SearchItem } from "@/search";
import { eventBus } from "@/bus";

export default Vue.extend({
  name: "GlobalSearchDialog",
  components: {},
  data() {
    return {
      show: false,
      selected: null as any,
      items: null as SearchItem[] | null,
    };
  },
  created() {
    eventBus.$on("openGlobalSearchDialog", () => this.show = true);
  },
  methods: {
    close() {
      this.show = false;
      this.selected = '';
    },
    async load() {
      await search.load();
      this.items = [...search.getNameIndex()];
    },
    select(item: SearchItem) {
      this.show = false;
      if (!item) {
        return;
      }
      eventBus.$emit("openItemDialog", item);
    },
  },
});
