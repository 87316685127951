import {
  Decoration,
  DecorationSet,
  EditorView,
  ViewPlugin,
  ViewUpdate,
  WidgetType,
} from '@codemirror/view';
import { iterateTreeInVisibleRanges, isCursorInRange } from '../../../node_modules/@retronav/ixora/dist/util';
import { Range } from '@codemirror/state';
import { HighlightStyle, syntaxHighlighting } from "@codemirror/language"
import { vueBlockComponentPlugin } from './vue-block-component-plugin';
import TableWidget from "@/components/codemirror-plugins/TableWidget.vue";

const componentPlugin = vueBlockComponentPlugin("Table", "Table", TableWidget);

const highlightStylePlugin = syntaxHighlighting(HighlightStyle.define([
  // TODO move styles from main CM file here
]));

export const table = {
  componentPlugin,
  highlightStylePlugin,
}
