




















import { entities } from "@/entities";
import { PowerStorage } from "@/powerStorage";
import Vue from "vue";
import router from "../../router";
import DropdownEditor from "@/components/DropdownEditor.vue";
import { itemsManager } from "../../items";
import { eventBus } from "@/bus";
import { ProxiedDatasetItem } from "@/sync/ProxiedDataset";
import { search } from "@/search";

export default Vue.extend({
  name: "GlobalLink",
  components: { DropdownEditor },
  props: ["value"],
  async created() {
    const match = this.value.match(
      /{{(?<name>[^}|]+)[|]*(?<overrideName>[^}]+)*}}/
    );
    if (!match) {
      this.overrideName = "not matched";
      return;
    }

    await entities.registerAllDatasets();

    const [, id, overrideName] = match;

    this.overrideName = overrideName;

    await search.load();

    const item = await search.getById(id);
    if (!item) {
      this.name = "not found";
      return;
    }

    this.item = item;
    this.name = item.name;

    this.field = PowerStorage.create(item._dataset).getFieldByName("status");
  },
  methods: {
    openItemDialog(item: ProxiedDatasetItem) {
      eventBus.$emit("openItemDialog", item);
    },
  },
  computed: {
    title(): string {
      if (this.overrideName) {
        return this.overrideName;
      }
      return this.name;
    },
  },
  data() {
    return {
      name: "",
      router,
      item: null as any,
      field: null as any,
      itemsManager,
      overrideName: "loading...",
    };
  },
});
