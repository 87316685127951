import { PowerStorage } from './powerStorage';
import { ProxiedDatasetItem } from './sync/ProxiedDataset';

export interface SearchItem {
  name: string,
  dataset: string,
  id: string,
}

export class Search {
  private indexByName = new Map<string, SearchItem>();
  private indexById = new Map<string, SearchItem>();

  async load() {
    const indexableDatasets = (await PowerStorage.create("datasets").refresh())
      .items
      .filter((x: any) => !x.nonIndexable);
    // console.log("indexing", indexableDatasets.length, "datasets");

    for (const d of indexableDatasets) {
      // console.log("indexing", d.name);
      const items = (await PowerStorage.create(d.name).refresh()).items;

      for (const item of items) {
        if (!item.name) continue;
        const searchItem = { name: item.name, dataset: item._dataset, id: item._id } as SearchItem;
        this.indexByName.set(item.name, searchItem);
        this.indexById.set(item._id, searchItem);

        // TODO what about deleted items?
      }
    }

    console.log("indexing completed for", indexableDatasets.length, "datasets");
  }

  getNameIndex() {
    return this.indexByName.values();
  }

  async getById(id: string): Promise<ProxiedDatasetItem | undefined> {
    const item = this.indexById.get(id);
    if (!item) return undefined;

    return (await PowerStorage.create(item.dataset).refresh()).getById(item.id);
  }
}

export const search = new Search();