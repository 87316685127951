// we use it as a function so IndexedDB don't throw errors about cloning
import { builtin, dataset, defaultValue, hidden, label, options, type } from '@/decorators';
import { ProxiedDatasetItem } from '@/sync/ProxiedDataset';
import { Field, FieldButton, FieldOption } from '@/entities/field';

const getDefaultStatusOptions = () => ([
  new FieldOption("Done", "done", "#72c75a", "white", true, "done"),
  new FieldOption("In progress", "inProgress", "#5882b5", "white", undefined, "active"),
  new FieldOption("Waiting", "waiting", "#eed935", "black", undefined, "active"),
  new FieldOption("To do", "todo", "#6c7683", "white", undefined, "notStarted"),
]);
export const defaultDatasetFields = [
  Field.createParent().withBuiltIn(),
  Field.create("name", "Name").withBuiltIn(),
  Field.create("status", "Status").withBuiltIn().withOptions(getDefaultStatusOptions()).withDefault("todo"),
  Field.create("content", "Content", "markdown").withBuiltIn().withHidden(),
  Field.create("attachments", "Attachments", "files").withBuiltIn().withHidden(),
];

export class EmptyItem implements ProxiedDatasetItem {
  readonly _id: string = ""
  readonly _updatedAt: string = ""
  readonly _createdAt: string = ""
  readonly _dataset: string = ""
}

export class BaseItem implements ProxiedDatasetItem {
  readonly _id: string = ""
  readonly _updatedAt: string = ""
  readonly _createdAt: string = ""
  readonly _dataset: string = ""

  @builtin
  @type("parent")
  @label(" ")
  parent: string | null = null

  @builtin
  @label("Name")
  name = ""

  @builtin
  @type("dropdown")
  @options(getDefaultStatusOptions())
  @defaultValue("todo")
  @label("Status")
  status = ""

  @builtin
  @type("markdown")
  @hidden
  @label("Content")
  content = ""

  @builtin
  @type("files")
  @hidden
  @label("Attachments")
  attachments = [] as any

  public static create(obj: any) {
    return Object.assign(new BaseItem(), obj);
  }
}

@dataset("datasets")
export class DatasetItem extends BaseItem {
  @type("icon")
  @label("Icon")
  @builtin
  icon = ""

  @builtin
  label = ""

  @type("checkbox")
  @label("Non indexable")
  @builtin
  nonIndexable = false;

  @label("Location")
  @builtin
  location = ""

  @label("Quick access shortcut")
  @builtin
  quickAccessShortcut = ""

  @hidden
  @builtin
  @defaultValue(defaultDatasetFields)
  fields: Field[] = []

  @builtin
  @type("button")
  @label(" ")
  readonly editDatasetSchema = new FieldButton("editDatasetSchema")

  @builtin
  @type("button")
  @label(" ")
  readonly openDataset = new FieldButton("openDataset")

  @hidden
  @builtin
  cognitoIdentityId = ""

  @hidden
  @builtin
  mailForwardingAddress = ""
}