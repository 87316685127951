


















import Vue from "vue";

export default Vue.extend({
  name: "TableWidget",
  components: {},
  props: ["value"],
  data() {
    return {
      headerCols: [],
      linesCols: [],
    };
  },
  created() {
    let lines = this.value.split("\n");
    const headerLine = lines[0];
    lines.splice(0, 2);

    const r = /([^|]+)/g;

    this.headerCols = headerLine.match(r).map((x: string) => x.trim());

    this.linesCols = lines.map((l: string) =>
      l.match(r)!.map((x: string) => x.trim())
    );
  },
});
