








import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "HabitsRoutinesCircle",
  components: {},
  props: ["habit", "date", "storageEntity"],
  async created() {
    await this.storageEntity.refresh();
  },
  computed: {
    exists(): boolean {
      return (
        this.storageEntity.items.filter(
          (x: any) =>
            (
              x.name == this.habit.tag ||
              x.name == this.habit.name.toLowerCase()
            ) &&
            x.time &&
            moment(x.time).isSame(moment(this.date, "YYYY-MM-DD"), "day")
        ).length > 0
      );
    },
  },
  data() {
    return {};
  },
  methods: {
    async add() {
      await this.storageEntity.save({
        name: this.habit.tag || this.habit.name.toLowerCase(),
        time: moment(this.date, "YYYY-MM-DD").toISOString(),
        value: 1,
      });

      this.$emit("change");
    },
  },
});
