


























import Vue from "vue";
import HabitsRoutinesWidgetCircle from "@/components/HabitsRoutinesCircle.vue";
import { PowerStorage } from "@/powerStorage";
import moment from "moment";

export default Vue.extend({
  name: "HabitsTable",
  components: {
    HabitsRoutinesWidgetCircle,
  },
  props: ["maxDays", "fromDate", "toDate", "filterHomeScreen"],
  async created() {
    await this.tagsStorageEntity.refresh();
    await this.habitsStorageEntity.refresh();

    const fromDate = this.maxDays
      ? moment().add(-this.maxDays, "days")
      : moment(this.fromDate);
    const toDate = this.maxDays
      ? moment().add(1, "day").startOf("day")
      : moment(this.toDate);

    for (var m = moment(fromDate); m.isBefore(toDate); m.add(1, "days")) {
      this.days.push(m.format("YYYY-MM-DD"));
    }

    this.days.reverse();

    this.items = this.habitsStorageEntity.items.filter(
      (x: any) => !this.filterHomeScreen || x.showOnHomeView
    );

    for (const item of this.items) {
      await Promise.all([
        this.updateStreak(item),
        this.updatePeriods(item),
      ]);
    }

    this.$forceUpdate();

    // how it should work like:
    // 1. get all data of habits for N days (N not necessary equals maxDays, maxDays are for display only)
    // 2. group habits according to frequency
    // 3. calculate streak based on frequency and actual hits in periods
    // 4. display groups (eg twice a week shows two or more circles - to consider if this is a good idea)

    this.tags = this.tagsStorageEntity.items.filter((x: any) =>
      moment(x._created).isSameOrAfter(fromDate)
    );
  },
  data() {
    return {
      tagsStorageEntity: PowerStorage.create(
        "tags",
        undefined,
        undefined,
        this.maxDays
      ),
      habitsStorageEntity: PowerStorage.create("habits-routines"),
      items: [] as any,
      tags: [] as any,
      days: [] as any,
      moment,
      forceUpdate: 0,
    };
  },
  methods: {
    getBgStyle(habit: any, d: string) {
      if (!habit.frequency) {
        console.log("no frequency for ", habit.name);
        return "";
      }
      const date = moment(d, "YYYY-MM-DD").startOf("day");
      if (
        habit.frequency.startsWith("week") ||
        ["weekly", "twiceAWeek"].includes(habit.frequency)
      ) {
        // console.log("getBgStyle", d, date, date.week(), date.isoWeek());
        if (date.isoWeek() % 2 == 0) {
          return "background-color: #efefef";
        }
      }
      if (
        habit.frequency.startsWith("month") ||
        ["monthly", "twiceAMonth"].includes(habit.frequency)
      ) {
        if (date.month() % 2 == 0) {
          return "background-color: #efefef";
        }
      }
      if (habit.frequency.startsWith("quarter")) {
        if (date.quarter() % 2 == 0) {
          return "background-color: #efefef";
        }
      }
      return "";
    },
    async updatePeriods(habit: any) {
      const today = moment().startOf("day");
      const frequency = habit.frequency;
      habit["periods&"] = [1];

      if(!frequency) {
        return;
      }

      if (frequency == "daily") {
        for (let i = 0; i < this.maxDays; i++) {
          habit["periods&"].push(1);
        }
      }

      if (frequency == "weekly" || frequency == "twiceAWeek") {
        if (today.isoWeekday() != 1) {
          habit["periods&"].push(today.isoWeekday() - 1);
        }
        for (let i = 0; i < this.maxDays; i++) {
          habit["periods&"].push(7);
        }
      }

      if (frequency == "monthly" || frequency == "twiceAMonth") {
        habit["periods&"].push(today.date() - 1);
        for (let i = 0; i < this.maxDays; i++) {
          habit["periods&"].push(30 - today.day());
        }
      }

      return;
    },
    async updateStreak(habit: any, refresh = false) {
      if (refresh) {
        await this.tagsStorageEntity.refresh();
      }

      habit["streak&"] = 0;

      for (let i = 0; i < 30; i++) {
        if (
          this.tagsStorageEntity.items.filter(
            (x: any) =>
              x.name == habit.tag &&
              x.time &&
              moment(x.time).isSame(moment().add(-i, "days"), "day")
          ).length == 0
        ) {
          habit["streak&"] = i;
          this.$forceUpdate();
          return;
        }
      }

      habit["streak&"] = "+30";
    },
  },
});
