



import Vue from "vue";
import {PowerStorage} from "@/powerStorage";

export default Vue.extend({
  name: "RollupEditor",
  components: {},
  props: ["item", "field"],
  data() {
    return {
      displayValue: "???",
    };
  },
  async created() {
    const relationshipField = PowerStorage.create(this.item._dataset).getFieldByName(this.field.rollup?.relationshipField);
    if (!relationshipField || !relationshipField.relationship) {
      this.displayValue = "invalid";
      return;
    }

    const ids = (this.item[relationshipField.name] || []).map((y: any) => y.id || y._id);
    const remoteDataset = await PowerStorage.create(relationshipField.relationship.dataset).refresh();
    const rollupItems = remoteDataset.items
      .filter((x: any) => ids.includes(x._id))
      .map((x: any) => x[this.field.rollup!.remoteField]);

    console.log("rollup items for", this.item.name, rollupItems);

    let value = 0;
    switch (this.field.rollup?.rollupType) {
      case "sum":
        value = rollupItems.reduce((acc, val) => (acc += 1 * (val || 0)), 0);
        (this.item as any)[`${this.field.name}&`] = value;
        this.displayValue = value.toFixed(2);
        break;

      default:
        this.displayValue = "not implemented yet";
    }
  },
  methods: {},
});
