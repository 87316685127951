var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(("background-color: " + _vm.internalValue + "; color: " + _vm.textColor + "; padding: 3px; text-align: center"))},on),[_vm._v(" "+_vm._s(_vm.textColor ? "Abc" : " ")+" ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pb-0"},[_c('v-color-picker',{attrs:{"dot-size":"1","mode":"rgba","show-swatches":"","swatches-max-height":"176","swatches":[
          ['#800000', '#E50000', '#FF4081', '#FF7FAB', '#F900EA'],
          ['#EA80FC', '#BF55EC', '#9B59B6', '#7C4DFF', '#0231E8'],
          ['#81B1FF', '#3397DD', '#3082B7', '#04A9F4', '#02BCD4'],
          ['#1BBC9C', '#2ECD6F', '#F9D900', '#AF7E2E', '#FF7800'],
          ['#E65100', '#B5BCC2', '#667684'] ]},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.select}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }