























































import { ProxiedDatasetItem } from "@/sync/ProxiedDataset";
import Vue from "vue";
import { eventBus } from "../bus";
import { ItemsManager } from '@/items';
import moment from 'moment/moment';
import { relationships } from '@/relationships';
import { PowerStorage } from '@/powerStorage';
import { Calendar, CalendarEvent } from '@/entities/calendar';
import { ActionItem } from '@/entities/ppv';

export default Vue.extend({
  name: "NameEditor",
  components: {},
  props: [
    "readonly",
    "type",
    "placeholder",
    "value",
    "default",
    "childrenItemsCounts",
    "item",
    "dataset",
    "singleItemView",
  ],
  data() {
    return {
      checklistLength: 0,
      checklistDone: 0,
    };
  },
  created() {
    const checklist = this.item.checklist || [];
    this.checklistLength = checklist.length;
    this.checklistDone = checklist.filter((x: any) => x.done).length;
  },
  computed: {
    hasRecurrenceField(): boolean {
      return ItemsManager.hasRecurrenceSet(this.item);
    },
    showAddTimeslot(): boolean {
      // this is ugly hack to show only for action items...
      return this.dataset == "action-items";
    },
  },
  methods: {
    async addTimeslot(item: ProxiedDatasetItem) {
      console.log("adding timeslot for item", item);

      // TODO probably makes sense to move it to some helper and optimize
      const calendarStorageEntity = await PowerStorage.create<Calendar>("calendar-calendars").refresh();
      const calendarEventsStorageEntity = PowerStorage.create<CalendarEvent>("calendar-events");
      const actionItemsStorageEntity = PowerStorage.create<ActionItem>("action-items");

      const defaultCalendar = calendarStorageEntity.items.find(x => x.name.toLowerCase() == "default");

      console.log("default calendar", defaultCalendar);

      const newEvent = await calendarEventsStorageEntity.create({
        name: this.value,
        startTime: moment().toISOString(),
        endTime: moment().add(30, "minutes").toISOString(),
        allDay: false,
        busy: true,
      });

      await relationships.updateRelationships(newEvent, calendarEventsStorageEntity.getFieldByName("actionItem"), [this.item], actionItemsStorageEntity);
      await relationships.updateRelationships(newEvent, calendarEventsStorageEntity.getFieldByName("calendar"), [defaultCalendar], calendarStorageEntity);
    },
    openItemDialog(item: ProxiedDatasetItem) {
      eventBus.$emit("openItemDialog", item);
    },
    focus() {
      const r = this.$refs["text"] as any;
      if (r && r.focus) {
        r.focus();
      }
    },
  },
});
