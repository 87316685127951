var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v("Latest tags "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","to":"/datasets/tags"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)],1),_c('v-card-text',[_c('v-list',{staticClass:"py-0"},_vm._l((_vm.latestTags),function(tag){return _c('v-list-item',{key:tag._id},[_c('v-list-item-title',[_c('v-chip',{attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.addTag(tag)}}},[_vm._v(_vm._s(tag.name))])],1),_c('v-list-item-subtitle',[_c('time-ago',{attrs:{"timestamp":tag.time}})],1),_c('v-list-item-action',{staticClass:"my-0"},[_c('v-btn',{attrs:{"small":"","text":"","icon":""},on:{"click":function($event){return _vm.deleteTag(tag)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1)],1),_c('v-list-item-action',{staticClass:"my-0"},[_c('v-btn',{attrs:{"icon":"","small":"","to":{
              name: 'chart',
              params: {
                id: ("tags-" + (tag.name)),
              },
              query: {
                series: [
                  {
                    name: tag.name,
                    //lastDays: 60,
                    //yTitle: 'Count',
                    //title: 'Daily sums for tag ' + tag.name,
                    dataset: 'tags',
                    valueField: 'value',
                    timeField: 'time',
                    aggregationType: 'sum',
                    aggregationWindow: 'day',
                    type: 'column',
                    filterByName: tag.name,
                  } ],
              },
            }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chart-line")])],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }