var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-container',{staticClass:"pa-1",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('power-table-widget',{attrs:{"dataset-name":"action-items","title":"Action items for today","tableSettings":{
                    hideHeaders: true,
                    fieldsVisible: [
                      'parent',
                      'name',
                      'status',
                      'doDate',
                      'dueDate',
                      'actions',
                      'project' ],
                    sortBy: ['status'],
                  },"filterRowFunction":_vm.onlyTodayAndOverdue}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"6"}},[_c('notes-widget',{staticClass:"mb-3",attrs:{"max-items":5}}),_c('habits-routines-widget',{attrs:{"maxDays":7,"filterHomeScreen":true}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"6"}},[_c('tags-input-widget',{staticClass:"mb-3"}),_c('tags-most-popular-widget',{staticClass:"mt-3",attrs:{"days":60}}),_c('tags-latest-widget',{staticClass:"mt-3"})],1)],1)],1)],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"4"}},[_c('calendar-widget',{staticClass:"mb-3"}),_c('metrics-group-widget',{staticClass:"mt-3",attrs:{"dataset":"oura","sortBy":"summary_date","limit":"21","days":"22","staleDays":"2","metrics":[
                    {
                      label: 'Readiness score',
                      field: 'score_readiness',
                    },
                    {
                      label: 'Sleep score',
                      field: 'score_sleep',
                    },
                    {
                      label: 'Activity score',
                      field: 'score_activity',
                    } ],"title":"Oura metrics summary"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.dailyLists),function(list){return _c('v-col',{key:list.datasetName,staticClass:"pa-2",attrs:{"cols":"12","sm":"4"}},[_c('power-table-widget',{attrs:{"dataset-name":list.datasetName,"title":list.title,"tableSettings":{
              hideHeaders: true,
              fieldsVisible: ['name', 'actions'],
            },"filterRowFunction":_vm.onlyCreatedToday}})],1)}),1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }