var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{ 'mt-3': _vm.singleItemView },style:(_vm.getStyle())},on),[_vm._v(_vm._s(_vm.getDisplayValue()))])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"type":"number","solo":"","flat":"","hide-details":"","placeholder":"Repeat every"},model:{value:(_vm.internalValue.repeatEvery),callback:function ($$v) {_vm.$set(_vm.internalValue, "repeatEvery", $$v)},expression:"internalValue.repeatEvery"}}),_c('v-select',{attrs:{"placeholder":"Unit","solo":"","flat":"","hide-details":"","items":['days', 'months', 'years']},model:{value:(_vm.internalValue.repeatUnit),callback:function ($$v) {_vm.$set(_vm.internalValue, "repeatUnit", $$v)},expression:"internalValue.repeatUnit"}}),_c('v-select',{attrs:{"placeholder":"Based on date","solo":"","flat":"","hide-details":"","items":[
          //'specific date',
          'field',
          'date of closure' ]},model:{value:(_vm.internalValue.repeatBasedOn),callback:function ($$v) {_vm.$set(_vm.internalValue, "repeatBasedOn", $$v)},expression:"internalValue.repeatBasedOn"}}),_c('dropdown-editor',{attrs:{"solo":true,"options":_vm.statusOptions},model:{value:(_vm.internalValue.setStatus),callback:function ($$v) {_vm.$set(_vm.internalValue, "setStatus", $$v)},expression:"internalValue.setStatus"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.internalValue = {};
          _vm.$emit('change', undefined);
          _vm.menu = false;}}},[_vm._v("Clear")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.$emit('change', _vm.internalValue);
          _vm.menu = false;}}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }