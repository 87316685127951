import { PowerStorage } from "./powerStorage";
import { ProxiedDatasetItem } from './sync/ProxiedDataset';
import { settings } from './entities';

const scope = {
  // eslint-disable-next-line
  axios: require("axios"),

  settings,

  createItem: async (dataset: string, template = {}) => {
    return PowerStorage.create(dataset).create(template);
  },

  deleteItem: async (dataset: string, item: ProxiedDatasetItem) => {
    return PowerStorage.create(dataset).remove(item);
  },

  getItemById: async (dataset: string, id: string) => {
    return (await PowerStorage.create(dataset).refresh()).getById(id);
  },

  getItemByName: async (dataset: string, name: string) => {
    return (await PowerStorage.create(dataset).refresh()).getByName(name);
  },

  getItems: async (dataset: string) => {
    return (await PowerStorage.create(dataset).refresh()).items;
  },

  getFieldByName: async (dataset: string, name: string) => {
    const storage = PowerStorage.create(dataset);
    await storage.ensureFields();
    return storage.getFieldByName(name);
  },
}

export const runUserCode = async (code: string, context = {} as any) => {
  code = code.replace(/^```.*\n/gm, "");
  code = code.replace(/```$.*/gm, "");

  const finalCode = `
        return (async () => {
          ${code}
        })()`;

  return await Function(...Object.keys(scope), `"use strict"; ${finalCode}`).bind(context)(...Object.values(scope));
}