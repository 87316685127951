import { BaseItem, EmptyItem } from '@/entities/base';
import { dataset, datasetIcon, datasetLabel, defaultValue, expand, hidden, label, options, readonly, relationship, type, width } from '@/decorators';
import { FieldOption, FieldRelationship, FieldType } from '@/entities/field';

@datasetLabel("Accounts")
@datasetIcon({ icon: "mdi-currency-usd" })
@dataset("finance-accounts")
export class AccountItem extends BaseItem {
  @label(" ")
  @type(FieldType.Expand)
  @expand("transfers")
  expand = ""

  @label("Account number")
  @hidden
  public accountNumber = ""

  @label("Currency")
  @width("70px")
  public currency = ""


  @hidden
  @label("Transfers")
  @type(FieldType.Relationship)
  @relationship(new FieldRelationship("finance-transfers", "name", true, "account", "name", false))
  public transfers = null
}

@datasetLabel("Transfers")
@datasetIcon({ icon: "mdi-cash-multiple" })
@dataset("finance-transfers")
export class TransferItem extends BaseItem {
  @label("Amount")
  @width("120px")
  @readonly
  public amount = 0

  @label("Amount PLN")
  @width("120px")
  @readonly
  public amountPln = 0

  @label("Exchange rate")
  @readonly
  @hidden
  public exchangeRate = 0

  @label("Date")
  @width("150px")
  public date = ""

  @label("Currency")
  @readonly
  @width("70px")
  public currency = ""

  @label("Other account number")
  @readonly
  public otherAccountNumber = ""

  @label("Other name")
  @readonly
  public otherName = ""

  @label("Internal")
  @type("checkbox")
  public internal = false

  @label("Account")
  @type(FieldType.Relationship)
  @relationship(new FieldRelationship("finance-accounts", "name", false, "transfers", "name", true))
  public account = null

  @label("Category")
  @type(FieldType.Relationship)
  @relationship(new FieldRelationship("finance-categories", "name", false, "transfers", "name", true))
  public category = null

  @label("Category suggested")
  @type("checkbox")
  public categorySuggested = false
}

@datasetLabel("Budget categories")
@datasetIcon({ icon: "mdi-cash-multiple" })
@dataset("finance-categories")
export class CategoryItem extends BaseItem {
  @hidden
  @label("Transfers")
  @type(FieldType.Relationship)
  @relationship(new FieldRelationship("finance-transfers", "name", true, "category", "name", false))
  public transfers = null

  @hidden
  @label("Rules")
  @type(FieldType.Relationship)
  @relationship(new FieldRelationship("finance-rules", "name", true, "category", "name", false))
  public rules = null
}

const getMatchTypeOptions = () => ([
  new FieldOption("Exact", "exact"),
  new FieldOption("Regexp", "regexp"),
  new FieldOption("Contains", "contains"),
]);

const getMatchFieldOptions = () => ([
  new FieldOption("Other name", "otherName"),
  new FieldOption("Other account number", "otherAccountNumber"),
  new FieldOption("Name", "name"),
]);

@datasetLabel("Categorization rules")
@datasetIcon({ icon: "mdi-filter-outline" })
@dataset("finance-rules")
export class CategorizationRuleItem extends BaseItem {
  @label("Match field")
  @type("dropdown")
  @options(getMatchFieldOptions())
  @defaultValue("otherName")
  public matchField = ""

  @label("Match type")
  @type("dropdown")
  @options(getMatchTypeOptions())
  @defaultValue("contains")
  public matchType = ""

  @label("Expression")
  public matchExpression = ""

  @label("Order")
  public order = 0

  @label("Category")
  @type(FieldType.Relationship)
  @relationship(new FieldRelationship("finance-categories", "name", false, "rules", "name", true))
  public category = null as any[] | null

  @label("Mark internal")
  @type("checkbox")
  public markInternal = false
}
