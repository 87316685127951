











































































import Vue from "vue";
import { entities } from "../entities";
import { relationships } from "../relationships";
import InternalRow from "./InternalRow.vue";
import { itemsManager } from "../items";
import { Field, FieldOption, FieldType } from '@/entities/field';

export default Vue.extend({
  name: "InternalPowerTable",
  components: {
    InternalRow,
  },
  data() {
    return {
      addingInProgress: false,
      relationships,
      entities,
      itemsManager,
      expandedFields: {} as any,
    };
  },
  props: [
    "storageEntity",
    "fields",
    "items",
    "group",
    "id",
    "filterLinkedItem",
    "filterField",
    "parentField",
    "search",
    "settings",
    "hideNewItemRow",
  ],
  computed: {
    parentItems(): any[] {
      return this.items.filter((x: any) => !this.parentField || !x[this.parentField.name]);
    },
    filteredFields(): Field[] {
      return [...this.fields, Field.createActions()].filter(
        (f: any) =>
          !f.hidden &&
          !(this.settings.fieldsHidden || []).includes(f.name) &&
          ((this.settings.fieldsVisible || []).length == 0 ||
            (this.settings.fieldsVisible || []).includes(f.name))
      );
    },
    headers(): any {
      return this.filteredFields.map((f: any) => {
        return {
          text: f.label || f.text || f.name,
          value: f.name,
          sort: Field.getSortingFunction(f),
          sortable: ![
            "delete",
            "expand",
            "source",
            "parent",
            "actions",
          ].includes(f.type),
        };
      });
    },
    summary(): any {
      return (field: Field) => {
        switch (field.summaryType) {
          case "sum":
            return (this.parentItems.reduce((acc, cv) => acc + 1.0 * (cv[`${field.name}&`] || cv[field.name] || 0), 0) || 0).toFixed(2);

          case "average":
            if (this.parentItems.length == 0)
              return 0;
            return (this.parentItems.reduce((acc, cv, _, arr) => acc + 1.0 * (cv[`${field.name}&`] || cv[field.name] || 0) / arr.length, 0) || 0).toFixed(2);

          default:
            return "Invalid summary type";
        }
      }
    },
  },
  methods: {
    async add(objTemplate = {} as any) {
      this.addingInProgress = true;
      try {
        const template = Field.createItemWithDefaults(objTemplate, this.fields);
        const obj = await this.storageEntity.create(template);

        if (
          this.group &&
          this.group.add &&
          this.group.field.type != FieldType.Relationship
        ) {
          obj[this.group.field.name] = this.group.add;
        }

        if (this.group && this.group.field.type == FieldType.Relationship) {
          console.log("setting relationship for newly added item");
          await this.storageEntity.save(obj);
          relationships.updateRelationships(
            obj,
            this.group.field,
            this.group.add || [],
            this.storageEntity
          );
        }

        if (this.filterLinkedItem && this.filterField) {
          await this.storageEntity.save(obj);
          relationships.updateRelationships(
            obj,
            this.storageEntity.fields.find(
              (f: Field) =>
                f.name == this.filterField.relationship.writeTargetProperty
            )!,
            [this.filterLinkedItem],
            this.storageEntity
          );
        }

        console.log("add()", obj);

        await this.storageEntity.save(obj);

        this.$nextTick(() => {
          const r = this.$refs[obj._id];
          if (r) {
            (r as any).focus("name");
          }
        });
      } finally {
        this.addingInProgress = false;
      }
    },

    async promoteToItem(item: any) {
      item.parent = undefined;
      await this.storageEntity.save(item);
    },

    customFilter(value: any, _search: string | null, _item: any): boolean {
      if (!this.search) {
        return true;
      }
      if (typeof value === "string" || value instanceof String) {
        return value.toLowerCase().includes(this.search);
      }
      if (value && value.length && value.length > 0 && value[0]._id) {
        return JSON.stringify(value).toLowerCase().includes(this.search);
      }
      if (value && value.label) {
        return value.label.toLowerCase().includes(this.search);
      }

      return value == this.search;
    },
    expandByField(field: Field, item: any, expand: any) {
      if (
        this.expandedFields[item._id] &&
        this.expandedFields[item._id].name == field.name
      ) {
        this.expandedFields[item._id] = null;
      } else {
        this.expandedFields[item._id] = field;
      }

      expand(this.expandedFields[item._id] != null);
    },
  },
});
