



































import Vue from "vue";

export default Vue.extend({
  name: "ColorPicker",
  components: {},
  props: ["value", "textColor"],
  data() {
    return {
      menu: false,
      internalValue: this.value,
    };
  },
  methods: {
    select() {
      this.$emit("input", this.internalValue.hex);
      this.$emit("change", this.internalValue.hex);
      this.menu = false;
    },
  },
  watch: {
    value() {
      this.internalValue = this.value;
    },
  },
});
