
















import Vue from "vue";

export default Vue.extend({
  name: "IconButton",
  components: {},
  props: ["icon", "title", "shortkey"],
  data() {
    return {};
  },
  methods: {},
});
