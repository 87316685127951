











































































































































































































































































































































import Vue from "vue";
import { Auth, Hub, Logger } from "aws-amplify";
import { entities, settings } from "@/entities";
import DatasetSelectorDialog from "@/components/DatasetSelectorDialog.vue";
import QuickAddDialog from "@/components/QuickAddDialog.vue";
import GlobalSearchDialog from "@/components/GlobalSearchDialog.vue";
import NotesSearchDialog from "@/components/NotesSearchDialog.vue";
import Taskbar from "@/components/Taskbar.vue";
import { syncClient } from "@/powerStorage";
import { SyncStatus } from "@/sync/SyncClient";
import IconButton from "@/components/IconButton.vue";
import TagsInputDialog from "@/components/TagsInputDialog.vue";
import EmbeddingsSearchDialog from '@/components/EmbeddingsSearchDialog.vue';
import { eventBus } from "@/bus";
import SidebarRight from '@/components/SidebarRight.vue';

const logger = new Logger("AppLayout");

export default Vue.extend({
  name: "AppLayout",

  components: {
    EmbeddingsSearchDialog,
    TagsInputDialog,
    IconButton,
    DatasetSelectorDialog,
    NotesSearchDialog,
    QuickAddDialog,
    GlobalSearchDialog,
    Taskbar,
    SidebarRight,
  },

  props: ["user"],

  computed: {
    routerViewKey(): any {
      const helper = this.$route.meta?.routeKey;
      const key = helper ? helper(this.$route) : this.$route.path;
      return key;
    },
    isKeepAliveCacheEnabled(): boolean {
      return !settings.get('keepAliveCacheDisabled');
    },
    isDev(): any {
      return window.location.port == "8080";
    },
    syncIcon(): string {
      switch (syncClient.status) {
        case SyncStatus.NeedsSync:
          return "mdi-lan-connect";

        case SyncStatus.Synced:
          return "mdi-lan-check";

        case SyncStatus.Syncing:
          return "mdi-lan-pending";

        case SyncStatus.Failed:
          return "mdi-lan-disconnect";

        default:
          return "mdi-help-circle-outline";
      }
    },
    showRightDrawer(): boolean {
      console.log("debug", this.$route.name);
      return !["home", "calendar"].includes(this.$route.name || "") && this.rightDrawer;
    }
  },

  methods: {
    async logout() {
      try {
        await Auth.signOut();
        // TODO clear all local storage
        location.reload();
      } catch (error: any) {
        alert(error.message);
      }
    },
    getTopLevelMenuItems() {
      return [
        ...new Set(
          entities.datasets.items
            .filter((x: any) => x.location)
            .map((x: any) => x.location)
        ),
      ];
    },
    toggleSidebar() {
      this.leftDrawer = !this.leftDrawer;
    },
    toggleRightSidebar() {
      this.rightDrawer = !this.rightDrawer;
    },
    datasetQuickAccess(e: any) {
      console.log("datasetQuickAccess", e);
    },
  },

  async beforeCreate() {
    await entities.registerAllDatasets();
  },

  async mounted() {
    this.authListener = Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          logger.info("user signed in");
          break;
        case "signUp":
          logger.info("user signed up");
          break;
        case "signOut":
          logger.info("user signed out");
          document.location.reload();
          break;
        case "signIn_failure":
          logger.error("user sign in failed");
          break;
        case "tokenRefresh":
          logger.info("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          logger.error("token refresh failed");
          document.location.reload();
          break;
        case "autoSignIn":
          logger.info("Auto Sign In after Sign Up succeeded");
          break;
        case "autoSignIn_failure":
          logger.error("Auto Sign In after Sign Up failed");
          break;
        case "configured":
          logger.info("the Auth module is configured");
      }
    });
  },

  beforeDestroy() {
    if (this.authListener) {
      this.authListener();
    }
  },

  data() {
    return {
      eventBus,
      ready: false,
      leftDrawer: false,
      rightDrawer: false,
      syncing: true,
      syncClient,
      authListener: null as any,
      networkActive: false,
      datasetsEntity: entities.datasets,
    };
  },
});
