import { settings } from "@/entities";
import { Configuration, OpenAIApi } from "openai";
import { ProxiedDatasetItem } from '@/sync/ProxiedDataset';
import { sortByPropertyNumber } from '@/helpers';
import { PowerStorage } from '@/powerStorage';

export const generateEmbeddings = async (text: string) => {
  await settings.load();

  const openai = new OpenAIApi(new Configuration({
    apiKey: settings.get("openaiApiKey"),
  }  ));

  const embedding = await openai.createEmbedding({
    model: "text-embedding-ada-002",
    input: text,
  });

  console.log("used", embedding.data.usage.total_tokens, "tokens");

  return embedding.data.data[0].embedding;
}

export const generateEmbeddingsForItem = async(item:ProxiedDatasetItem) => {
  return generateEmbeddings("Title:\n" + item.name + "\n\nContent:\n\n" + item.content);
}

function dotProduct(a: number[], b: number[]): number {
  return a.reduce((acc, val, i) => acc + val * b[i], 0);
}

function magnitude(a: number[]): number {
  return Math.sqrt(a.reduce((acc, val) => acc + val * val, 0));
}

export function cosineSimilarity(a: number[], b: number[]): number {
  const dot = dotProduct(a, b);
  const magA = magnitude(a);
  const magB = magnitude(b);
  return dot / (magA * magB);
}

export const calculateCosineVectorsForDataset = async (dataset : string, searchTerm : string) => {
  const items = (await PowerStorage.create(dataset).refresh()).items.filter(i => i.embeddings$);

  const embeddings = await generateEmbeddings(searchTerm);

  for (const i of items) {
    i["relatedScore&"] = cosineSimilarity(embeddings, i.embeddings$);
  }

  console.log("items with score", items);

  return items.sort(sortByPropertyNumber("relatedScore&")).reverse();
}
