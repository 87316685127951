


































import Vue from "vue";

export default Vue.extend({
  name: "SourceEditorDialog",
  components: {},
  props: ["item", "show"],
  data() {
    return {
      jsonText: JSON.stringify(this.item, null, '  ')
    };
  },
  methods: {
    async save() {
      console.log("saving changes in json...");

      const obj = JSON.parse(this.jsonText);

      console.log(obj);

      for(let k in obj) {
        if(k.startsWith("_")) {
          continue;
        }

        // eslint-disable-next-line vue/no-mutating-props
        this.item[k] = obj[k]
      }
    }
  },
});
