








































































import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  name: "DateTimePicker",
  components: {},
  props: ["value", "format", "singleItemView", "type"],
  data() {
    return {
      menu: false,
      selectedDate: this.value,
    };
  },
  methods: {
    select(date: string) {
      let outDate: any = null;
      switch (date) {
        case "none":
          // no action
          break;

        case "today":
          outDate = moment();
          break;

        case "tomorrow":
          outDate = moment().add(1, "day");
          break;

        case "next sunday":
          outDate = moment().day(7);
          break;

        case "next monday":
          outDate = moment().day(8);
          break;

        case "end of month":
          outDate = moment().endOf("month");
          break;

        default:
          outDate = moment(date, "YYYY-MM-DD");
      }

      this.$emit("change", outDate ? outDate.format("YYYY-MM-DD") : null);
      this.menu = false;
    },
  },
  computed: {
    getDisplayValue() {
      if (!this.value) {
        return "not set";
      }

      if (this.format && this.format.absoluteDates) {
        if(this.type == "datetime") {
          return moment(this.value, "YYYY-MM-DDTHH:mm:ss").format(
            this.format.dateFormat || "YYYY-MM-DD HH:mm"
          );
        }

        return moment(this.value, "YYYY-MM-DD").format(
          this.format.dateFormat || "YYYY-MM-DD"
        );
      }

      return moment(this.value, "YYYY-MM-DD")
        .from(moment().startOf("day"))
        .replaceAll("a few seconds ago", "today");
    },
    getStyle() {
      if (!this.value) {
        return "color: #ddd";
      }

      if (this.format && this.format.disableColors) {
        return "";
      }

      const diff = moment(this.value as string, "YYYY-MM-DD").diff(
        moment().startOf("day"),
        "days"
      );

      if (diff < (this.format && -this.format.redColorThreshold) || 0) {
        return "color: red";
      }

      if (diff <= ((this.format && -this.format.orangeColorThreshold) || 0)) {
        return "color: orange";
      }

      return "";
    },
  },
});
