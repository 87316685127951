import { render, staticRenderFns } from "./NotesWidget.vue?vue&type=template&id=43e2d713&scoped=true&"
import script from "./NotesWidget.vue?vue&type=script&lang=ts&"
export * from "./NotesWidget.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43e2d713",
  null
  
)

export default component.exports