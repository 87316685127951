import { ObservableStorageAdapter } from "./ObservableStorageAdapter";
import { Item } from "./syncEngine";
import { deleteDB, openDB } from 'idb';
import Vue from "vue";
import { VersionStorageAdapter } from './StorageAdapter';

export class LocalStorageAdapter extends ObservableStorageAdapter implements VersionStorageAdapter {
  public items = {} as any;
  private readonly prefix: string;
  private database: any;

  constructor(name?: string) {
    super();
    this.prefix = name ? name + "_" : "";
  }

  public async deleteDatabase() {
    return deleteDB(this.prefix + "items");
  }

  private db() {
    if (!this.database) {
      console.log("opening localdb...")
      this.database = openDB(this.prefix + "items", 2, {
        upgrade(db, oldVersion, newVersion, transaction, event) {
          console.log("upgrading localdb from", oldVersion, "to", newVersion);

          if (oldVersion == 0) {
            const store = db.createObjectStore('items', {
              keyPath: 'id',
              autoIncrement: false,
            });
            store.createIndex("dataset", "dataset");
            store.createIndex("syncTimestamp", "syncTimestamp");
          }

          if (oldVersion < 2) {
            const store = transaction.objectStore("items");
            store.createIndex("syncNeeded", "syncNeeded");
          }
        },
      });
    }
    return this.database;
  }

  async createItem(item: Item): Promise<Item> {
    try {
      await (await this.db()).add("items", item);
    } catch (e) {
      console.error("error while adding item", item, "to local db:", e);
      throw e;
    }
    this.itemCreatedSubject.next(item);
    return item;
  }

  async setVersion(version: number): Promise<void> {
    if (version > await this.getVersion()) {
      console.log("setVersion", version);
      window.localStorage.setItem(this.prefix + "version", version.toString())
    }
  }

  async getVersion(): Promise<number> {
    return parseInt(window.localStorage.getItem(this.prefix + "version") || "0", 10);
  }

  async updateItem(item: Item): Promise<boolean> {
    console.log("localdb updating", item.id, item);
    await (await this.db()).put("items", this.clone(item));
    this.itemUpdatedSubject.next(item);
    return true;
  }

  async deleteItem(id: string): Promise<void> {
    console.log("localdb deleting", id);
    await (await this.db()).delete("items", id);
    this.itemDeletedSubject.next(id);
    Vue.delete(this.items, id);
  }

  async getItem(id: string, includeDeleted = false): Promise<Item | null> {
    const item = await (await this.db()).get("items", id);
    return item && (includeDeleted || !item.deletedAt) ? item : null;
  }

  async getAllItems(includeDeleted = false): Promise<Item[]> {
    const items = await (await this.db()).getAll("items");

    for (const item of items) {
      Vue.set(this.items, item.id, item);
    }

    return items.filter((i: any) => i && (includeDeleted || !i.deletedAt));
  }

  async getItemsByDataset(datasetName: string, includeDeleted?: boolean | undefined): Promise<Item[]> {
    const items = await (await this.db()).getAllFromIndex("items", "dataset", datasetName);

    for (const item of items) {
      Vue.set(this.items, item.id, item);
    }

    return items.filter((i: any) => i && (includeDeleted || !i.deletedAt));
  }

  async getItemsForSync(version: number): Promise<Item[]> {
    const items = (await (await this.db()).getAllFromIndex("items", "syncNeeded", IDBKeyRange.lowerBound(1, false)));
    // console.log(this.prefix, "items for sync", items);

    for (const item of items) {
      Vue.set(this.items, item.id, item);
    }

    return items;
  }

  async getItemsCount() {
    return (await (await this.db()).count("items"));
  }

  async release(): Promise<void> {
    // do nothing
    return;
  }

  private clone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }
}