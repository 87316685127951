import { entities } from './entities';
import { debounce } from '@/helpers';


export class Settings {
  public async load() {
    return entities.settings.refresh();
  }

  public get(name: string, defaultValue?: any): any {
    const item = entities.settings.items.find((x: any) => x.name == name);
    if (!item) {
      return defaultValue;
    }

    return item.value;
  }

  public async set(name: string, value: any) {
    const item = entities.settings.items.find((x: any) => x.name == name);

    if (!item) {
      return await entities.settings.create({
        name,
        value,
      });
    }

    item.value = value;

    return entities.settings.update(item);
  }

  public generateComputedSettings(...names: string[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const debouncedSet = debounce((name, value)=>this.set(name, value), 500);

    return names.reduce((aggr: any, name) => {
      aggr[name] = {
        get() {
          return that.get(name);
        },
        set(value: any) {
          debouncedSet(name,value);
        }
      }
      return aggr;
    }, {});
  }
}
