






























































import Vue from "vue";
import { search } from "@/search";
import { eventBus } from "@/bus";
import { calculateCosineVectorsForDataset } from "@/embeddings";
import GptChat from '@/components/GptChat.vue';

export default Vue.extend({
  name: "EmbeddingsSearchDialog",
  components: {
    GptChat,
  },
  data() {
    return {
      show: false,
      selected: null as any,
      searchTerms: "",
      searching: false,
      items: null as any[] | null,
      chatMode: false,
      contextMessages: [] as string[],
    };
  },
  created() {
    eventBus.$on("openEmbeddingsSearchDialog", () => this.show = true);
  },
  methods: {
    async search() {
      this.searching = true;
      try {
        this.items = await calculateCosineVectorsForDataset("notes", this.searchTerms)

        console.table(this.items);
      } finally {
        this.searching = false;
      }
    },
    async startChat() {
      await this.search();

      this.contextMessages = this.items!.filter(i => i["relatedScore&"] > 0.8).map(i => i.content);
      console.log("context messages", this.contextMessages);
      this.chatMode = true;
    },
    close() {
      this.show = false;
      this.selected = '';
    },
    async load() {
      await search.load();
      this.items = [...search.getNameIndex()];
    },
    open(item: any) {
      if (!item) {
        return;
      }
      eventBus.$emit("openItemDialog", item);
    },
  },
});
