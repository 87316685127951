var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('tr',{key:_vm.item._id,staticClass:"data",attrs:{"data-category-id":_vm.item._id,"data-id":_vm.item._id}},_vm._l((_vm.fields),function(field){return _c('td',{key:field.name,class:field.type,style:(_vm.getCellStyle(field, _vm.item))},[_c('field-editor',{ref:field.name,refInFor:true,attrs:{"field":field,"item":_vm.item,"storageEntity":_vm.storageEntity,"expandedField":_vm.expandedField},on:{"change":function($event){return _vm.itemsManager.updateItem(_vm.item, field, $event)},"expand":function($event){return _vm.$emit('expand', $event)}}}),(field.type == 'delete')?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('remove', _vm.item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),(field.type == 'openItem')?_c('v-btn',{attrs:{"small":"","icon":"","to":("/datasets/" + (_vm.storageEntity.dataset) + "/items/" + (_vm.item._id))}},[_c('v-icon',[_vm._v("mdi-arrow-expand")])],1):_vm._e(),(field.type == 'expand')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.$emit(
            'expand',
            _vm.storageEntity.fields.find(function (x) { return x.name == field.expand; })
          )}}},[_c('v-icon',[_vm._v(_vm._s(_vm.isExpanded ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1):_vm._e(),(
          field.type == 'parent' &&
          _vm.storageEntity.items.some(function (x) { return x.parent == _vm.item._id; })
        )?_c('v-btn',{style:(("margin-left: " + (_vm.level * 10) + "px")),attrs:{"icon":"","x-small":""},on:{"click":function($event){_vm.nestedExpand = !_vm.nestedExpand;
          _vm.$forceUpdate();}}},[_c('v-icon',[_vm._v(_vm._s(_vm.nestedExpand ? "mdi-chevron-down" : "mdi-chevron-right")+" ")])],1):_vm._e(),(field.type == 'parent')?_c('v-btn',{style:(_vm.storageEntity.items.some(function (x) { return x.parent == _vm.item._id; })
            ? ''
            : 'margin-left: 20px'),attrs:{"x-small":"","icon":""},on:{"click":function($event){
            var _obj;
_vm.$emit('add', ( _obj = {}, _obj[_vm.parentField.name] = _vm.item._id, _obj ));
          _vm.nestedExpand = true;
          _vm.$forceUpdate();}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-source-branch-plus")])],1):_vm._e(),(field.type == 'actions')?_c('source-editor-dialog',{attrs:{"show":_vm.dialog,"item":_vm.item},on:{"closed":function($event){_vm.dialog = false}}}):_vm._e(),(field.type == 'actions')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[(_vm.item.parent)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('promoteToItem', _vm.item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-arrow-up")])],1),_c('v-list-item-title',[_vm._v("Convert to item")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){_vm.dialog = true}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-code-json")])],1),_c('v-list-item-title',[_vm._v("View source")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('remove', _vm.item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1):_vm._e()],1)}),0),(_vm.nestedExpand && _vm.parentField)?_c('fragment',[_vm._l((_vm.storageEntity.items.filter(
        function (x) { return x[_vm.parentField.name] == _vm.item._id; }
      )),function(nestedItem){return _c('fragment',{key:nestedItem.id || nestedItem._id},[_c('internal-row',_vm._g({attrs:{"storageEntity":_vm.storageEntity,"item":nestedItem,"isExpanded":_vm.isExpanded,"expand":_vm.expand,"fields":_vm.fields,"parentField":_vm.parentField,"level":_vm.level + 1}},_vm.$listeners))],1)}),_c('tr',{staticClass:"add"},[_c('td',[_vm._v(" ")]),_c('td',{staticStyle:{"text-align":"center"}},[_c('v-btn',{attrs:{"color":"primary","x-small":"","text":""},on:{"click":function($event){
      var _obj;
return _vm.$emit('add', ( _obj = {}, _obj[_vm.parentField.name] = _vm.item._id, _obj ))}}},[_vm._v(" New subitem for "+_vm._s(_vm.item.name)+" ")])],1),_c('td',{attrs:{"colspan":_vm.fields.length - 2}},[_vm._v("   ")])])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }