var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","to":("/datasets/" + _vm.dataset)}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)],1),(_vm.stale)?_c('v-card-subtitle',[_vm._v("Data is stale!")]):_vm._e(),_c('v-card-text',[_c('v-container',[(_vm.item)?_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.metrics),function(m){return _c('v-col',{key:m.field,staticClass:"text-center",attrs:{"cols":Math.floor(12 / _vm.metrics.length)}},[_c('div',{staticClass:"text-h2",domProps:{"textContent":_vm._s(_vm.formatValue(_vm.fieldData(m.field).at(-1)))}}),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(m.label))]),_c('v-btn',{attrs:{"icon":"","small":"","to":{
              name: 'chart',
              params: {
                id: (_vm.dataset + "-" + (m.field)),
              },
              query: {
                series: [
                  {
                    name: m.label,
                    dataset: _vm.dataset,
                    type: 'areaspline',
                    valueField: m.field,
                    timeField: _vm.sortBy,
                  } ],
              },
            }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chart-line")])],1),(_vm.fieldData(m.field).length)?_c('v-sparkline',{attrs:{"smooth":"8","value":_vm.fieldData(m.field)}}):_vm._e()],1)}),1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }