




























































import Vue from "vue";

export default Vue.extend({
  name: "IconEditor",
  components: {},
  props: ["value", "dense"],
  methods: {
    saveIcon(icon: any) {
      this.icon = icon;
      this.selectedIcon = icon;
      this.showDialog = false;
      const event = {
        icon: this.selectedIcon,
      };
      this.$emit("input", event);
      this.$emit("change", event);
    },
    clearIcon() {
      this.icon = "";
      this.selectedIcon = "mdi-border-none-variant";
      this.showDialog = false;
      this.$emit("input", null);
      this.$emit("change", null);
    },
  },
  computed: {
    icons(): string[] {
      if (this.searchIcon) {
        return this.allIcons.filter((i) => i.includes(this.searchIcon));
      }
      return this.allIcons;
    },
  },
  watch: {
    value(newIcon, oldIcon) {
      this.selectedIcon = newIcon?.icon || "mdi-border-none-variant";
    },
  },
  data() {
    return {
      showDialog: false,
      icon: "",
      searchIcon: "",
      selectedIcon: this.value?.icon || "mdi-border-none-variant",
      allIcons: [
        "mdi-bullseye-arrow",
        "mdi-bullseye",
        "mdi-target",
        "mdi-target-variant",
        "mdi-notebook-outline",
        "mdi-notebook",
        "mdi-notebook-multiple",
        "mdi-pillar",
        "mdi-receipt-text-outline",

        "mdi-account",
        "mdi-account-alert",
        "mdi-account-box",
        "mdi-account-box-outline",
        "mdi-account-check",
        "mdi-account-circle",
        "mdi-account-key",
        "mdi-tooltip-account",
        "mdi-account-minus",
        "mdi-account-multiple",
        "mdi-account-multiple-outline",
        "mdi-account-multiple-plus",
        "mdi-account-network",
        "mdi-account-outline",
        "mdi-account-plus",
        "mdi-account-remove",
        "mdi-account-search",
        "mdi-account-star",
        "mdi-account-switch",
        "mdi-airballoon",
        "mdi-airplane",
        "mdi-airplane-off",
        "mdi-alarm",
        "mdi-alarm-check",
        "mdi-alarm-multiple",
        "mdi-alarm-off",
        "mdi-alarm-plus",
        "mdi-album",
        "mdi-alert",
        "mdi-alert-box",
        "mdi-alert-circle",
        "mdi-alert-octagon",
        "mdi-alpha",
        "mdi-alphabetical",
        "mdi-amazon",
        "mdi-google-cloud",
        "mdi-ambulance",
        "mdi-android",
        "mdi-android-debug-bridge",
        "mdi-android-studio",
        "mdi-apple",
        "mdi-apple-finder",
        "mdi-apple-ios",
        "mdi-apple-safari",
        "mdi-apps",
        "mdi-archive",
        "mdi-arrange-bring-forward",
        "mdi-arrange-bring-to-front",
        "mdi-arrange-send-backward",
        "mdi-arrange-send-to-back",
        "mdi-arrow-all",
        "mdi-arrow-bottom-left",
        "mdi-arrow-bottom-right",
        "mdi-arrow-collapse",
        "mdi-arrow-down",
        "mdi-arrow-down-bold",
        "mdi-arrow-down-bold-circle",
        "mdi-arrow-down-bold-circle-outline",
        "mdi-arrow-down-bold-hexagon-outline",
        "mdi-arrow-expand",
        "mdi-arrow-left",
        "mdi-arrow-left-bold",
        "mdi-arrow-left-bold-circle",
        "mdi-arrow-left-bold-circle-outline",
        "mdi-arrow-left-bold-hexagon-outline",
        "mdi-arrow-right",
        "mdi-arrow-right-bold",
        "mdi-arrow-right-bold-circle",
        "mdi-arrow-right-bold-circle-outline",
        "mdi-arrow-right-bold-hexagon-outline",
        "mdi-arrow-top-left",
        "mdi-arrow-top-right",
        "mdi-arrow-up",
        "mdi-arrow-up-bold",
        "mdi-arrow-up-bold-circle",
        "mdi-arrow-up-bold-circle-outline",
        "mdi-arrow-up-bold-hexagon-outline",
        "mdi-at",
        "mdi-attachment",
        "mdi-auto-fix",
        "mdi-auto-upload",
        "mdi-baby",
        "mdi-backburger",
        "mdi-backup-restore",
        "mdi-bank",
        "mdi-barcode",
        "mdi-barley",
        "mdi-barrel",
        "mdi-basket",
        "mdi-basket-fill",
        "mdi-basket-unfill",
        "mdi-battery",
        "mdi-battery-10",
        "mdi-battery-20",
        "mdi-battery-30",
        "mdi-battery-40",
        "mdi-battery-50",
        "mdi-battery-60",
        "mdi-battery-70",
        "mdi-battery-80",
        "mdi-battery-90",
        "mdi-battery-alert",
        "mdi-battery-charging-100",
        "mdi-battery-charging-20",
        "mdi-battery-charging-30",
        "mdi-battery-charging-40",
        "mdi-battery-charging-60",
        "mdi-battery-charging-80",
        "mdi-battery-charging-90",
        "mdi-battery-minus",
        "mdi-battery-negative",
        "mdi-battery-outline",
        "mdi-battery-plus",
        "mdi-battery-positive",
        "mdi-battery-unknown",
        "mdi-beach",
        "mdi-beaker",
        "mdi-beaker-outline",
        "mdi-beer",
        "mdi-bell",
        "mdi-bell-off",
        "mdi-bell-outline",
        "mdi-bell-ring",
        "mdi-bell-ring-outline",
        "mdi-bell-sleep",
        "mdi-beta",
        "mdi-bike",
        "mdi-binoculars",
        "mdi-bio",
        "mdi-biohazard",
        "mdi-bitbucket",
        "mdi-black-mesa",
        "mdi-blinds",
        "mdi-block-helper",
        "mdi-blogger",
        "mdi-bluetooth",
        "mdi-bluetooth-audio",
        "mdi-bluetooth-connect",
        "mdi-bluetooth-settings",
        "mdi-bluetooth-transfer",
        "mdi-blur",
        "mdi-blur-linear",
        "mdi-blur-off",
        "mdi-blur-radial",
        "mdi-bone",
        "mdi-book",
        "mdi-book-multiple",
        "mdi-book-open",
        "mdi-book-variant",
        "mdi-bookmark",
        "mdi-bookmark-check",
        "mdi-bookmark-music",
        "mdi-bookmark-outline",
        "mdi-bookmark-plus",
        "mdi-bookmark-remove",
        "mdi-border-all",
        "mdi-border-bottom",
        "mdi-border-color",
        "mdi-border-horizontal",
        "mdi-border-inside",
        "mdi-border-left",
        "mdi-border-none",
        "mdi-border-outside",
        "mdi-border-right",
        "mdi-border-top",
        "mdi-border-vertical",
        "mdi-bowling",
        "mdi-box",
        "mdi-briefcase",
        "mdi-briefcase-check",
        "mdi-briefcase-download",
        "mdi-briefcase-upload",
        "mdi-brightness-1",
        "mdi-brightness-2",
        "mdi-brightness-3",
        "mdi-brightness-4",
        "mdi-brightness-5",
        "mdi-brightness-6",
        "mdi-brightness-7",
        "mdi-brightness-auto",
        "mdi-broom",
        "mdi-brush",
        "mdi-bug",
        "mdi-bulletin-board",
        "mdi-bullhorn",
        "mdi-bus",
        "mdi-cake",
        "mdi-cake-variant",
        "mdi-calculator",
        "mdi-calendar",
        "mdi-calendar-blank",
        "mdi-calendar-check",
        "mdi-calendar-clock",
        "mdi-calendar-multiple",
        "mdi-calendar-multiple-check",
        "mdi-calendar-plus",
        "mdi-calendar-remove",
        "mdi-calendar-text",
        "mdi-calendar-today",
        "mdi-camcorder",
        "mdi-camcorder-off",
        "mdi-camera",
        "mdi-camera-front",
        "mdi-camera-front-variant",
        "mdi-camera-iris",
        "mdi-camera-party-mode",
        "mdi-camera-rear",
        "mdi-camera-rear-variant",
        "mdi-camera-switch",
        "mdi-camera-timer",
        "mdi-candycane",
        "mdi-car",
        "mdi-car-wash",
        "mdi-carrot",
        "mdi-cart",
        "mdi-cart-outline",
        "mdi-cash",
        "mdi-cash-100",
        "mdi-cash-multiple",
        "mdi-cash-usd",
        "mdi-cast",
        "mdi-cast-connected",
        "mdi-castle",
        "mdi-cat",
        "mdi-cellphone",
        "mdi-cellphone-android",
        "mdi-cellphone-dock",
        "mdi-cellphone-iphone",
        "mdi-cellphone-link",
        "mdi-cellphone-link-off",
        "mdi-cellphone-settings",
        "mdi-chair-school",
        "mdi-chart-arc",
        "mdi-chart-areaspline",
        "mdi-chart-bar",
        "mdi-chart-histogram",
        "mdi-chart-line",
        "mdi-chart-pie",
        "mdi-check",
        "mdi-check-all",
        "mdi-checkbox-blank",
        "mdi-checkbox-blank-circle",
        "mdi-checkbox-blank-circle-outline",
        "mdi-checkbox-blank-outline",
        "mdi-checkbox-marked",
        "mdi-checkbox-marked-circle",
        "mdi-checkbox-marked-circle-outline",
        "mdi-checkbox-marked-outline",
        "mdi-checkbox-multiple-blank",
        "mdi-checkbox-multiple-blank-outline",
        "mdi-checkbox-multiple-marked",
        "mdi-checkbox-multiple-marked-outline",
        "mdi-checkerboard",
        "mdi-chevron-double-down",
        "mdi-chevron-double-left",
        "mdi-chevron-double-right",
        "mdi-chevron-double-up",
        "mdi-chevron-down",
        "mdi-chevron-left",
        "mdi-chevron-right",
        "mdi-chevron-up",
        "mdi-church",
        "mdi-city",
        "mdi-clipboard",
        "mdi-clipboard-account",
        "mdi-clipboard-alert",
        "mdi-clipboard-arrow-down",
        "mdi-clipboard-arrow-left",
        "mdi-clipboard-check",
        "mdi-clipboard-outline",
        "mdi-clipboard-text",
        "mdi-clippy",
        "mdi-clock",
        "mdi-clock-fast",
        "mdi-close",
        "mdi-close-box",
        "mdi-close-box-outline",
        "mdi-close-circle",
        "mdi-close-circle-outline",
        "mdi-close-network",
        "mdi-closed-caption",
        "mdi-cloud",
        "mdi-apple-icloud",
        "mdi-cloud-check",
        "mdi-cloud-circle",
        "mdi-cloud-download",
        "mdi-cloud-outline",
        "mdi-cloud-off-outline",
        "mdi-cloud-upload",
        "mdi-cloud-refresh",
        "mdi-cloud-lock",
        "mdi-cloud-lock-outline",
        "mdi-cloud-question",
        "mdi-cloud-tags",
        "mdi-cloud-print",
        "mdi-cloud-print-outline",
        "mdi-cloud-search",
        "mdi-cloud-search-outline",
        "mdi-code-array",
        "mdi-code-braces",
        "mdi-code-equal",
        "mdi-code-greater-than",
        "mdi-code-less-than",
        "mdi-code-less-than-or-equal",
        "mdi-code-not-equal",
        "mdi-code-not-equal-variant",
        "mdi-code-string",
        "mdi-code-tags",
        "mdi-codepen",
        "mdi-coffee",
        "mdi-coffee-to-go",
        "mdi-color-helper",
        "mdi-comment",
        "mdi-comment-account",
        "mdi-comment-account-outline",
        "mdi-comment-alert",
        "mdi-comment-alert-outline",
        "mdi-comment-check",
        "mdi-comment-check-outline",
        "mdi-comment-multiple-outline",
        "mdi-comment-outline",
        "mdi-comment-plus-outline",
        "mdi-comment-processing",
        "mdi-comment-processing-outline",
        "mdi-comment-remove-outline",
        "mdi-comment-text",
        "mdi-comment-text-outline",
        "mdi-compare",
        "mdi-compass",
        "mdi-compass-outline",
        "mdi-console",
        "mdi-content-copy",
        "mdi-content-cut",
        "mdi-content-duplicate",
        "mdi-content-paste",
        "mdi-content-save",
        "mdi-content-save-all",
        "mdi-contrast",
        "mdi-contrast-box",
        "mdi-contrast-circle",
        "mdi-cow",
        "mdi-credit-card",
        "mdi-credit-card-multiple",
        "mdi-crop",
        "mdi-crop-free",
        "mdi-crop-landscape",
        "mdi-crop-portrait",
        "mdi-crop-square",
        "mdi-crosshairs",
        "mdi-crosshairs-gps",
        "mdi-crown",
        "mdi-cube",
        "mdi-cube-outline",
        "mdi-cube-unfolded",
        "mdi-cup",
        "mdi-cup-water",
        "mdi-currency-btc",
        "mdi-currency-eur",
        "mdi-currency-gbp",
        "mdi-currency-inr",
        "mdi-currency-rub",
        "mdi-currency-try",
        "mdi-currency-usd",
        "mdi-cursor-default",
        "mdi-cursor-default-outline",
        "mdi-cursor-move",
        "mdi-cursor-pointer",
        "mdi-database",
        "mdi-database-minus",
        "mdi-database-outline",
        "mdi-database-plus",
        "mdi-debug-step-into",
        "mdi-debug-step-out",
        "mdi-debug-step-over",
        "mdi-decimal-decrease",
        "mdi-decimal-increase",
        "mdi-delete",
        "mdi-delete-variant",
        "mdi-deskphone",
        "mdi-desktop-mac",
        "mdi-desktop-tower",
        "mdi-details",
        "mdi-deviantart",
        "mdi-diamond",
        "mdi-dice-1",
        "mdi-dice-2",
        "mdi-dice-3",
        "mdi-dice-4",
        "mdi-dice-5",
        "mdi-dice-6",
        "mdi-directions",
        "mdi-disqus",
        "mdi-division",
        "mdi-division-box",
        "mdi-dns",
        "mdi-domain",
        "mdi-dots-horizontal",
        "mdi-dots-vertical",
        "mdi-download",
        "mdi-drag",
        "mdi-drag-horizontal",
        "mdi-drag-vertical",
        "mdi-drawing",
        "mdi-drawing-box",
        "mdi-drone",
        "mdi-dropbox",
        "mdi-drupal",
        "mdi-duck",
        "mdi-dumbbell",
        "mdi-earth",
        "mdi-earth-off",
        "mdi-eject",
        "mdi-elevation-decline",
        "mdi-elevation-rise",
        "mdi-elevator",
        "mdi-email",
        "mdi-email-open",
        "mdi-email-outline",
        "mdi-emoticon",
        "mdi-emoticon-cool",
        "mdi-emoticon-devil",
        "mdi-emoticon-happy",
        "mdi-emoticon-neutral",
        "mdi-emoticon-poop",
        "mdi-emoticon-sad",
        "mdi-emoticon-tongue",
        "mdi-engine",
        "mdi-engine-outline",
        "mdi-equal",
        "mdi-equal-box",
        "mdi-eraser",
        "mdi-escalator",
        "mdi-evernote",
        "mdi-exclamation",
        "mdi-exit-to-app",
        "mdi-export",
        "mdi-eye",
        "mdi-eye-off",
        "mdi-eyedropper",
        "mdi-eyedropper-variant",
        "mdi-facebook",
        "mdi-facebook-messenger",
        "mdi-factory",
        "mdi-fan",
        "mdi-fast-forward",
        "mdi-ferry",
        "mdi-file",
        "mdi-file-cloud",
        "mdi-file-delimited",
        "mdi-file-document",
        "mdi-file-excel-box",
        "mdi-file-find",
        "mdi-file-image",
        "mdi-file-multiple",
        "mdi-file-music",
        "mdi-file-outline",
        "mdi-file-pdf",
        "mdi-file-pdf-box",
        "mdi-file-powerpoint",
        "mdi-file-powerpoint-box",
        "mdi-file-presentation-box",
        "mdi-file-video",
        "mdi-file-word",
        "mdi-file-word-box",
        "mdi-film",
        "mdi-filmstrip",
        "mdi-filmstrip-off",
        "mdi-filter",
        "mdi-filter-outline",
        "mdi-filter-remove-outline",
        "mdi-filter-variant",
        "mdi-fire",
        "mdi-firefox",
        "mdi-fish",
        "mdi-flag",
        "mdi-flag-checkered",
        "mdi-flag-outline",
        "mdi-flag-triangle",
        "mdi-flag-variant",
        "mdi-flash",
        "mdi-flash-auto",
        "mdi-flash-off",
        "mdi-flashlight",
        "mdi-flashlight-off",
        "mdi-flip-to-back",
        "mdi-flip-to-front",
        "mdi-floppy",
        "mdi-flower",
        "mdi-folder",
        "mdi-folder-account",
        "mdi-folder-download",
        "mdi-folder-google-drive",
        "mdi-folder-image",
        "mdi-folder-lock",
        "mdi-folder-lock-open",
        "mdi-folder-move",
        "mdi-folder-multiple",
        "mdi-folder-multiple-image",
        "mdi-folder-multiple-outline",
        "mdi-folder-outline",
        "mdi-folder-plus",
        "mdi-folder-remove",
        "mdi-folder-upload",
        "mdi-food",
        "mdi-food-apple",
        "mdi-food-variant",
        "mdi-football",
        "mdi-football-helmet",
        "mdi-format-align-center",
        "mdi-format-align-justify",
        "mdi-format-align-left",
        "mdi-format-align-right",
        "mdi-format-bold",
        "mdi-format-clear",
        "mdi-format-color-fill",
        "mdi-format-float-center",
        "mdi-format-float-left",
        "mdi-format-float-none",
        "mdi-format-float-right",
        "mdi-format-header-1",
        "mdi-format-header-2",
        "mdi-format-header-3",
        "mdi-format-header-4",
        "mdi-format-header-5",
        "mdi-format-header-6",
        "mdi-format-header-decrease",
        "mdi-format-header-equal",
        "mdi-format-header-increase",
        "mdi-format-header-pound",
        "mdi-format-indent-decrease",
        "mdi-format-indent-increase",
        "mdi-format-italic",
        "mdi-format-line-spacing",
        "mdi-format-list-bulleted",
        "mdi-format-paint",
        "mdi-format-paragraph",
        "mdi-format-size",
        "mdi-format-strikethrough",
        "mdi-format-subscript",
        "mdi-format-superscript",
        "mdi-format-text",
        "mdi-format-textdirection-l-to-r",
        "mdi-format-textdirection-r-to-l",
        "mdi-format-underline",
        "mdi-format-wrap-inline",
        "mdi-format-wrap-square",
        "mdi-format-wrap-tight",
        "mdi-format-wrap-top-bottom",
        "mdi-forum",
        "mdi-forward",
        "mdi-fridge",
        "mdi-fullscreen",
        "mdi-fullscreen-exit",
        "mdi-function",
        "mdi-gamepad",
        "mdi-gamepad-variant",
        "mdi-gas-station",
        "mdi-gavel",
        "mdi-gender-female",
        "mdi-gender-male",
        "mdi-gender-male-female",
        "mdi-gender-transgender",
        "mdi-gift",
        "mdi-git",
        "mdi-github",
        "mdi-glass-flute",
        "mdi-glass-mug",
        "mdi-glass-stange",
        "mdi-glass-tulip",
        "mdi-glasses",
        "mdi-gmail",
        "mdi-google",
        "mdi-google-chrome",
        "mdi-google-circles",
        "mdi-google-circles-communities",
        "mdi-google-circles-extended",
        "mdi-google-circles-group",
        "mdi-google-controller",
        "mdi-google-controller-off",
        "mdi-google-drive",
        "mdi-google-earth",
        "mdi-google-glass",
        "mdi-google-maps",
        "mdi-google-play",
        "mdi-google-plus",
        "mdi-google-hangouts",
        "mdi-grid",
        "mdi-grid-off",
        "mdi-group",
        "mdi-guitar-pick",
        "mdi-guitar-pick-outline",
        "mdi-hand-pointing-right",
        "mdi-hanger",
        "mdi-harddisk",
        "mdi-headphones",
        "mdi-headphones-box",
        "mdi-headphones-settings",
      ],
    };
  },
});
