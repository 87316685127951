import { SyncClient } from './SyncClient';

export class SyncCoordinator {
  private syncTimeout?: NodeJS.Timeout;

  constructor(private readonly client: SyncClient,
    private intervalSeconds = 20,
    private idleIntervalSeconds = 600,
    private lockedIntervalSeconds = 600,
  ) {
  }

  public async requestPermission() {
    // TODO development paused as IdleDetector is not supported by Edge
    if ('IdleDetector' in window) {
      const state = await (window as any).IdleDetector.requestPermission();
      if (state !== 'granted') {
        // Need to request permission first.
        return console.log('Idle detection permission not granted.');
      }
    } else {
      console.log("IdleDetector not supported")
    }
  }

  public setInterval(intervalSeconds: number) {
    this.intervalSeconds = intervalSeconds;
  }

  public start() {
    this.stop();
    this.setTimer();
  }

  private setTimer() {
    let interval = 1000;

    if (this.isIdle()) {
      interval *= this.idleIntervalSeconds;
    } else {
      interval *= this.intervalSeconds;
    }

    // we use setTimeout instead of setInterval to support hot reload in dev mode
    this.syncTimeout = setTimeout(() => {
      this.client.sync();
      this.setTimer();
    }, interval);
  }

  isIdle(): boolean {
    if (!('IdleDetector' in window)) {
      return false; // not supported
    }

    return false;
    // throw new Error('Method not implemented.');
  }

  public stop() {
    if (!this.syncTimeout) return;
    clearTimeout(this.syncTimeout);
  }
}