import { builtin, dataset, datasetIcon, datasetLabel, datasetLocation, datasetQuickAccessShortcut, defaultValue, expand, format, hidden, label, minWidth, relationship, type, width } from '@/decorators';
import { BaseItem } from '@/entities/base';
import { FieldRelationship, FieldType } from '@/entities/field';

@datasetLabel("Action items")
@datasetIcon({ icon: "mdi-check-all" })
@datasetLocation("PPV")
@dataset("action-items")
@datasetQuickAccessShortcut("1")
export class ActionItem extends BaseItem {
  @builtin
  @label("Name")
  @minWidth("380px")
  @width("55%")
  name = ""

  @type(FieldType.Date)
  @defaultValue("today")
  @width("120px")
  @label("Do date")
  doDate = ""

  @type(FieldType.Date)
  @defaultValue("today")
  @width("120px")
  @label("Due date")
  dueDate = ""

  @type(FieldType.Recurrence)
  @label("Recurrence")
  @width("10%")
  recurrence = null

  @label("Project")
  @format({ disableChips: true })
  @width("10%")
  @type(FieldType.Relationship)
  @relationship(new FieldRelationship(
    "projects",
    "name",
    false,
    "actionItems",
    "name",
    true
  ))
  project = null

  @type(FieldType.Checkbox)
  @label("Delegable")
  delegable = null

  @type(FieldType.Checklist)
  @label("Checklist")
  @hidden
  checklist = null

  @hidden
  @label("Timeslots")
  @type(FieldType.Relationship)
  @relationship(new FieldRelationship("calendar-events", "name", true, "calendar", "name", false))
  timeslots = null
}

@datasetLabel("Projects")
@datasetIcon({ icon: "mdi-folder-outline" })
@datasetLocation("PPV")
@dataset("projects")
@datasetQuickAccessShortcut("2")
export class ProjectItem extends BaseItem {
  @label(" ")
  @type(FieldType.Expand)
  @expand("actionItems")
  expand = ""

  @label("Action items")
  @type(FieldType.Relationship)
  @hidden
  @relationship(new FieldRelationship(
    "action-items",
    "name",
    true,
    "project",
    "name",
    false
  ))
  actionItems = null

  @type(FieldType.Progress)
  @label("Progress")
  @width("250px")
  progress = 0

  @type(FieldType.Date)
  @label("Start date")
  @width("130px")
  @format({ disableColors: true })
  startDate = ""

  @type(FieldType.Date)
  @label("End date")
  @width("130px")
  @format({ disableColors: true })
  endDate = ""
}
