import 'reflect-metadata';

const generateDecorator = (decoratorName: string) => {
  return (value: any) => Reflect.metadata(decoratorName, value);
}
const generatePropertyDecorator = (decoratorName: string) => generateDecorator(decoratorName)(true);
const generatePropertyDecoratorReader = (decoratorName: string) => {
  return (target: any, propertyKey: string) => {
    return Reflect.getMetadata(decoratorName, target, propertyKey);
  }
}
const generateClassDecoratorReader = (decoratorName: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (target: Function) => Reflect.getMetadata(decoratorName, target);
}

export const getDecoratorValue = (decoratorName: string, target: any, propertyKey: string) => {
  return Reflect.getMetadata(decoratorName, target, propertyKey);
}

export const builtin = generatePropertyDecorator("builtin");
export const hidden = generatePropertyDecorator("hidden");
export const required = generatePropertyDecorator("required");
export const readonly = generatePropertyDecorator("readonly");
export const label = generateDecorator("label");
export const type = generateDecorator("type");
export const expand = generateDecorator("expand");
export const format = generateDecorator("format");
export const width = generateDecorator("width");
export const minWidth = generateDecorator("minWidth");
export const maxWidth = generateDecorator("maxWidth");

export const defaultValue = generateDecorator("defaultValue");
export const options = generateDecorator("options");
export const relationship = generateDecorator("relationship");

export const dataset = generateDecorator("dataset");
export const getDataset =  generateClassDecoratorReader("dataset");
export const datasetIcon = generateDecorator("datasetIcon");
export const datasetLocation = generateDecorator("datasetLocation");
export const datasetQuickAccessShortcut = generateDecorator("datasetQuickAccessShortcut");
export const getDatasetIcon = generateClassDecoratorReader("datasetIcon");
export const getDatasetLocation = generateClassDecoratorReader("datasetLocation");
export const getDatasetQuickAccessShortcut = generateClassDecoratorReader("datasetQuickAccessShortcut");
export const datasetLabel = generateDecorator("datasetLabel");
export const getDatasetLabel = generateClassDecoratorReader("datasetLabel");
