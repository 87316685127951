





import Vue from "vue";

import EditorJS, { EditorConfig, LogLevels } from "@editorjs/editorjs";

// eslint-disable-next-line
// @ts-ignore
import Header from "@editorjs/header";
// eslint-disable-next-line
// @ts-ignore
import Image from "@editorjs/image";
// eslint-disable-next-line
// @ts-ignore
import Checklist from "@editorjs/checklist";
// eslint-disable-next-line
// @ts-ignore
import Paragraph from "@editorjs/paragraph";
// eslint-disable-next-line
// @ts-ignore
import List from "@editorjs/list";
// eslint-disable-next-line
// @ts-ignore
import Embed from "@editorjs/embed";
// eslint-disable-next-line
// @ts-ignore
import Quote from "@editorjs/quote";
// eslint-disable-next-line
// @ts-ignore
import Marker from "@editorjs/marker";
// eslint-disable-next-line
// @ts-ignore
import Warning from "@editorjs/warning";
// eslint-disable-next-line
// @ts-ignore
import InlineCode from "@editorjs/inline-code";
// eslint-disable-next-line
// @ts-ignore
import Code from "@editorjs/code";
// eslint-disable-next-line
// @ts-ignore
import Table from "@editorjs/table";
// eslint-disable-next-line
// @ts-ignore
import Alert from "editorjs-alert";

export default Vue.extend({
  name: "EditorJs",
  components: {},
  props: ["data"],
  data() {
    return {
      editor: null as any,
      config: {
        defaultBlock: "paragraph",
        inlineToolbar: true,
        placeholder: "Let's create some content!",
        hideToolbar: false,
        data: this.data,
        logLevel: "ERROR" as LogLevels.ERROR,
        tools: {
          header: {
            class: Header,
            shortcut: "CTRL+SHIFT+H",
          },
          list: {
            class: List,
            shortcut: "CTRL+SHIFT+L",
            config: {
              defaultStyle: "unordered",
            },
          },
          checklist: {
            class: Checklist,
            shortcut: "CTRL+SHIFT+C",
          },
          image: {
            class: Image,
            shortcut: "CTRL+SHIFT+I",
          },
          embed: {
            class: Embed,
            shortcut: "CTRL+SHIFT+E",
          },
          quote: {
            class: Quote,
            shortcut: "CTRL+SHIFT+Q",
          },
          marker: {
            class: Marker,
          },
          warning: {
            class: Warning,
          },
          inlineCode: {
            class: InlineCode,
          },
          code: {
            class: Code,
            shortcut: "CTRL+SHIFT+X",
          },
          table: {
            class: Table,
          },
          alert: {
            class: Alert,
          },
          paragraph: {
            class: Paragraph,
            config: {
              placeholder: ".",
            },
          },
        },
      } as EditorConfig,
    };
  },
  methods: {},
  mounted() {
    this.editor = new EditorJS({
      holder: "editorjs",
      autofocus: true,
      ...this.config,
      onReady: function () {
        // console.log("ready");
      },
      onChange: async (x: any) => {
        // console.log("change", await x.saver.save());
        this.$emit("change", await x.saver.save());
      },
    });
  },
});
