















import { entities } from "@/entities";
import Vue from "vue";

export default Vue.extend({
  name: "DatasetSelectorEditor",
  components: {},
  props: ["value"],
  data() {
    return {
      internalValue: this.value || "",
      items: entities.datasets.items,
    };
  },
  async created() {
    await entities.datasets.refresh();
  },
  methods: {
    onChange() {
      this.$emit("input", this.internalValue);
      this.$emit("change", this.internalValue);
    },
  },
});
