















































import Vue from "vue";
import { Auth } from "aws-amplify";
import Loader from "@/components/Loader.vue";
import { syncClient } from "@/powerStorage";

export default Vue.extend({
  name: "LoginLayout",

  data() {
    return {
      loading: false,
      email: "",
      password: "",
    };
  },

  components: {
    Loader,
  },

  methods: {
    async login() {
      try {
        this.loading = true;
        await Auth.signIn(this.email, this.password);
        await syncClient.sync();
        location.reload();
      } catch (error) {
        alert(error);
        this.loading = false;
      }
    },
    async register() {
      try {
        this.loading = true;
        await Auth.signUp({
          username: this.email,
          password: this.password,
          attributes: {
            email: this.email,
          },
        });
        alert("User successfully registered. Please login");
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
