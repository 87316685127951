import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      routeKey: (_route: any) => 'home'
    },
  },
  {
    path: '/review/:period/:id?',
    name: 'review',
    meta: {
      routeKey: (route: any) => `review-${route.params.period}-${route.params.id}`
    },
    component: () => import(/* webpackChunkName: "weeklyView" */ '../views/PeriodReviewView.vue'),
  },
  {
    path: '/share',
    name: 'share',
    component: () => import(/* webpackChunkName: "share" */ '../views/ShareView.vue'),
  },
  {
    path: '/habits',
    name: 'habits',
    component: () => import(/* webpackChunkName: "habits" */ '../views/HabitsView.vue'),
  },
  {
    path: '/sync',
    name: 'sync',
    component: () => import(/* webpackChunkName: "sync" */ '../views/SyncView.vue'),
  },
  {
    path: '/graph/notes',
    name: 'graphNotes',
    component: () => import(/* webpackChunkName: "graphNotes" */ '../views/GraphView.vue'),
  },
  {
    path: '/graph/ppv',
    name: 'graphPpv',
    component: () => import(/* webpackChunkName: "graphPpv" */ '../views/GraphPpvView.vue'),
  },
  {
    path: '/budget',
    name: 'budget',
    component: () => import(/* webpackChunkName: "budget" */ '../views/BudgetView.vue'),
  },
  {
    path: '/budget/classify',
    name: 'classify',
    component: () => import(/* webpackChunkName: "budgetClassify" */ '../views/BudgetClassifyView.vue'),
  },
  {
    path: '/lab',
    name: 'lab',
    component: () => import(/* webpackChunkName: "lab" */ '../views/LabView.vue'),
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "calendar" */ '../views/CalendarView.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue'),
  },
  {
    path: '/datasets',
    name: 'datasets',
    component: () =>
      import(/* webpackChunkName: "datasetMain" */ '../views/DatasetMainView.vue'),
  },
  {
    path: '/chart/:id?',
    name: 'chart',
    meta: {
      routeKey: (route: any) => `chart-${route.params.id}`
    },
    component: () => import(/* webpackChunkName: "chart" */ '../views/ChartView.vue'),
  },
  {
    path: '/notes/:id',
    name: 'notes',
    meta: {
      routeKey: (route: any) => `notes-${route.params.id}`
    },
    component: () => import(/* webpackChunkName: "notes" */ '../views/NotesView.vue'),
    children: [
      {
        path: '/notes/:id/items/:itemId',
        name: 'notesItem',
        meta: {
          routeKey: (route: any) => `notes-${route.params.id}`
        },
        component: () => import(/* webpackChunkName: "notesItem" */ '../views/NotesItemView.vue'),
      },
      {
        path: '/notes/:id/name/:itemName',
        name: 'notesItemByName',
        meta: {
          routeKey: (route: any) => `notes-${route.params.id}`
        },
        component: () => import(/* webpackChunkName: "notesItem" */ '../views/NotesItemView.vue'),
      },
    ]
  },
  {
    path: '/datasets/:id',
    name: 'dataset',
    meta: {
      routeKey: (route: any) => `datasets-${route.params.id}`
    },
    component: () => import(/* webpackChunkName: "datasetSingle" */ '../views/DatasetSingleView.vue'),
    children: [
      {
        path: '/datasets/:id/import',
        name: 'importDialog',
        meta: {
          routeKey: (route: any) => `datasets-${route.params.id}`
        },
        component: () => import(/* webpackChunkName: "importDialog" */ '../components/ImportDialog.vue'),
      },
    ]
  },
  {
    path: '/datasets/:id/schema',
    name: 'datasetSchemaEditor',
    component: () => import(/* webpackChunkName: "datasetSchemaEditor" */ '../views/DatasetSchemaEditorView.vue'),
  },
  {
    path: '/onedrive',
    name: 'onedrive',
    component: () => import(/* webpackChunkName: "onedrive" */ '../views/OneDriveView.vue'),
  },
  {
    path: '/callback/onedrive',
    name: 'onedriveCallback',
    component: () => import(/* webpackChunkName: "onedriveCallback" */ '../views/OneDriveView.vue'),
  },
  {
    path: '/callback/withings',
    name: 'withingsCallback',
    component: () => import(/* webpackChunkName: "withingsCallback" */ '../components/WithingsCallback.vue'),
  },
  {
    path: '/dashboard/finance',
    name: 'financeDashboard',
    component: () => import(/* webpackChunkName: "financeDashboard" */ '../views/FinanceDashboardView.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from) => {
//   console.log("navigating", from, to);
// });

export default router;
