


























































import { generateRandomName } from "@/helpers";
import Vue from "vue";
import draggable from "vuedraggable";

export default Vue.extend({
  name: "ChecklistEditor",
  components: { draggable },
  props: ["value"],
  data() {
    return {
      internalValue: this.value || [],
    };
  },
  methods: {
    emitChange() {
      this.$emit("change", this.internalValue);
    },
    add(index = -1) {
      const obj = {
        id: generateRandomName(),
        name: "",
        done: false,
      };

      if (index == -1) {
        index = this.internalValue.length;
      }

      this.internalValue.splice(index, 0, obj);
      this.emitChange();

      this.$nextTick(() => {
        const r = this.$refs[obj.id];
        if (r) {
          (r as any)[0].focus();
        }
      });
    },
    focusOnIndex(idx: number) {
      if (idx < 0 || idx >= this.internalValue.length) {
        return;
      }
      this.$nextTick(() => {
        const r = this.$refs[this.internalValue[idx].id];
        if (r) {
          (r as any)[0].focus();
        }
      });
    },
    remove(idx: number) {
      this.internalValue.splice(idx, 1);
      this.emitChange();
    },
    removeIfEmpty(id: string, idx: number, e: KeyboardEvent) {
      const textInput = (this.$refs[id] as any)[0].$el.querySelector("input");
      if (textInput.value !== "") {
        return;
      }

      e.preventDefault();
      this.remove(idx);
      this.focusOnIndex(idx + (e.key == "Delete" ? 0 : -1));
    },
  },
});
