



import Vue from "vue";
import ixora, {
  blockquote,
  codeblock,
  frontmatter,
  headings,
  headingSlugField, image,
  imagePreview,
  lists
} from "@retronav/ixora";
import { EditorState, ChangeSet, Compartment } from "@codemirror/state";
import { basicSetup, EditorView } from "codemirror";
import { markdown, markdownLanguage } from "@codemirror/lang-markdown";
// import { javascript, javascriptLanguage } from "@codemirror/lang-javascript";
// import { highlightStyle, tagStyles, theme } from "./theme";
import { indentWithTab } from "@codemirror/commands";
import { keymap } from "@codemirror/view";
import { languages } from "@codemirror/language-data";
import { ViewUpdate } from "@codemirror/view";
import {
  defaultHighlightStyle,
  syntaxHighlighting,
} from "@codemirror/language";

import { HighlightStyle } from "@codemirror/language";
import { styleTags } from "@lezer/highlight";
import { Tag, tags as defaultTags } from "@lezer/highlight";
import { vim } from "@replit/codemirror-vim";
import { indentationMarkers } from "@replit/codemirror-indentation-markers";

import {
  highlightSpecialChars,
  drawSelection,
  highlightActiveLine,
  dropCursor,
  rectangularSelection,
  crosshairCursor,
  lineNumbers,
  highlightActiveLineGutter,
  placeholder,
} from "@codemirror/view";
import { Extension } from "@codemirror/state";
import {
  indentOnInput,
  bracketMatching,
  foldGutter,
  foldKeymap,
} from "@codemirror/language";
import { defaultKeymap, history, historyKeymap } from "@codemirror/commands";
import { searchKeymap, highlightSelectionMatches } from "@codemirror/search";
import {
  autocompletion,
  completionKeymap,
  closeBrackets,
  closeBracketsKeymap,
} from "@codemirror/autocomplete";
import { lintKeymap } from "@codemirror/lint";
import { goToLinkPlugin } from "./codemirror-plugins/internal-link-widget";
import { hideMarks } from "./codemirror-plugins/internal-link-hide-mark";
import { internalLinkAutocompletion } from "./codemirror-plugins/internal-link-autocompletion";
import { debounce, generateRandomName } from "@/helpers";
import { tagsAutocompletion } from "./codemirror-plugins/tags-autocompletion";
import { internalLink } from "./codemirror-plugins/internal-link";
import { globalLink } from "./codemirror-plugins/global-link";
import { tagsLink } from "./codemirror-plugins/tags-link";
import { attachmentLink } from "./codemirror-plugins/attachment-link";
import { mentionsLink } from "./codemirror-plugins/mentions";
import { horizontalRule } from "./codemirror-plugins/horizontal-rule";
import { table } from "./codemirror-plugins/table";
import { Storage } from "aws-amplify";
import { urlLink } from './codemirror-plugins/url-extension';
import { markdownLink } from "@/components/codemirror-plugins/markdown-link-extension";
import { slashCommand } from "./codemirror-plugins/slash-command";

export const theme = EditorView.theme(
  {
    "&": { height: "100%" },

    ".vueComponentPlugin img": {
      maxWidth: "100%",
    },

    ".cm-scroller": {
      width: "100%",
      margin: "auto",
      overflow: "auto",
      fontSize: "16px",
      lineHeight: "25px",
      fontFamily: "Roboto, sans-serif",
    },

    ".cm-content": {
      margin: "0 0.5rem",
      color: "black",
    },

    ".cm-tooltip": {
      padding: "0.5rem",
      borderRadius: "5px",
    },

    ".cm-codeblock": {
      fontFamily: "Consolas",
      padding: "0px 10px",
    },

    ".cm-heading": {
      color: "black",
    },

    ".cm-heading .ͼ7": {
      textDecoration: "none",
    },

    ".cm-gutters": {
      backgroundColor: "transparent",
      border: "none",
    },

    ".cm-activeLine": {
      borderRadius: "4px",
      // backgroundColor: "#f6f6f6",
    },

    // ".cm-cursor": {
    //   border: "1px solid #333",
    // },

    // ".cm-fat-cursor": {
    //   backgroundColor: "#333 !important",
    //   color: "white !important",
    // },

    // "&.cm-focused .cm-selectionBackground, ::selection": {
    //   backgroundColor: "#074",
    // },

    ".ͼ1.cm-editor.cm-focused": {
      outline: "0px solid transparent",
    },

    ".cm-list-bullet:has(~ .cm-task-marker-checkbox)": {
      display: "none",
    },

    ".cm-list-bullet": {
      paddingLeft: "2px",
      paddingRight: "10px",
      marginLeft: "3px",
      color: "gray",
    },
    ".cm-list-bullet::after": {
      fontSize: "25px",
      top: "-2px",
    },

    ".cm-task-marker-checkbox input[type=checkbox]": {
      width: "15px",
      height: "15px",
      marginRight: "5px",
      marginLeft: "-5px",
      align: "left",
      verticalAlign: "middle",
      marginTop: "-3px",
    },

    ".cm-link": {
      paddingLeft: "5px",
      textDecoration: "none",
    },

    ".ͼ6": {
      color: "blue",
      textDecoration: "none",
    },

    ".internalLink": {
      textDecoration: "none",
    },

    ".globalLink": {
      textDecoration: "none",
      color: "#00796B", // vuetify teal darken-2
    },

    ".globalLinkNotFound": {
      textDecoration: "none",
      color: "#FF5252", // vuetify red accent-2
    },

    "span span.cm-indentation-marker": {
      display: "none",
    },

    ".cm-indentation-marker": {
      // Note: You may have to add `!important` to overwrite the extension's base theme.
      background: "none !important",
      borderLeft: "1px solid #ddd",
      paddingRight: "5px",
      marginLeft: "6px",
    },

    ".cm-line hr": {
      borderTop: "1px solid #ddd",
      borderBottom: "none",
      borderRight: "none",
      borderLeft: "none",
    },

    ".cm-blockquote-border": {
      display: "inline-block",
      borderLeft: "4px solid rbg(85,85,85)",
      width: "8px",
      marginTop: "-4px",
      marginBottom: "-8px",
      height: "26px",
    },

    ".cm-line .v-chip": {
      marginTop: "-3px",
    },

    // hack...
    ".cm-activeLine .internalLink": {
      display: "none",
    },
    ".cm-activeLine .vueComponentPlugin": {
      display: "none",
    },
  },
  { dark: false }
);

export const tags = {
  inlineCode: Tag.define(defaultTags.monospace),
  tableCell: Tag.define(),
};

export const tagStyles = styleTags({
  InlineCode: [tags.inlineCode, defaultTags.monospace],
  TableCell: tags.tableCell,
});

export const highlightStyle = HighlightStyle.define([
  {
    tag: tags.inlineCode,
    color: "red",
    background: "#CCC8",
    borderRadius: "3px",
  },
  {
    tag: defaultTags.monospace,
    fontFamily: "Consolas",
  },
  {
    tag: tags.tableCell,
    whiteSpace: "pre",
    fontFamily: "Consolas",
  },
  {
    tag: defaultTags.link,
    color: "pink",
  }
]);

// function dropText(view: EditorView, event: DragEvent, text: string, direct: boolean) {
//   let dropPos = view.posAtCoords({x: event.clientX, y: event.clientY})
//   if (dropPos == null || !text) return

//   event.preventDefault()

//   let {mouseSelection} = view.inputState
//   let del = direct && mouseSelection && mouseSelection.dragging && mouseSelection.dragMove ?
//     {from: mouseSelection.dragging.from, to: mouseSelection.dragging.to} : null
//   let ins = {from: dropPos, insert: text}
//   let changes = view.state.changes(del ? [del, ins] : ins)

//   view.focus()
//   view.dispatch({
//     changes,
//     selection: {anchor: changes.mapPos(dropPos, -1), head: changes.mapPos(dropPos, 1)},
//     annotations: Transaction.userEvent.of("drop")
//   })
// }

export default Vue.extend({
  name: "CodeMirrorEditor",
  components: {},
  props: [
    "value",
    "placeholder",
    "tagsAutocompletionFunction",
    "fileUploadPrefix",
  ],
  data() {
    return {
      editor: {} as EditorView,
      internalValue: this.value || "",
      markdown: "",
    };
  },
  beforeDestroy() {
    if (this.markdown) {
      this.$emit("input", this.markdown);
    }
  },
  mounted() {
    const f = (m: string) => this.$emit("input", m);
    const emitDebounced = debounce(f, 2000);

    const autocompletions = [
      internalLinkAutocompletion,
      globalLink.autocompletion,
      slashCommand.autocompletion,
    ] as any;

    if (this.tagsAutocompletionFunction) {
      autocompletions.push(tagsAutocompletion(this.tagsAutocompletionFunction));
    }

    const emitFileUploaded = (e: any) => this.$emit("fileUploaded", e);
    const fileUploadPrefix = this.fileUploadPrefix;

    this.editor = new EditorView({
      state: EditorState.create({
        extensions: [
          EditorView.updateListener.of((v: ViewUpdate) => {
            if (v.docChanged) {
              const output = (v.state.doc as any).text
                ? (v.state.doc as any).text.join("\n")
                : v.state.doc.children
                  ?.flatMap((x: any) => x.text.join("\n"))
                  .join("\n");

              if (output) {
                this.markdown = output;
                emitDebounced(this.markdown);
              } else {
                console.log("not output", output, v.state.doc);
              }
            }
          }),

          syntaxHighlighting(defaultHighlightStyle),
          syntaxHighlighting(highlightStyle),

          internalLink.highlightStylePlugin,
          globalLink.highlightStylePlugin,
          tagsLink.highlightStylePlugin,
          attachmentLink.highlightStylePlugin,
          mentionsLink.highlightStylePlugin,
          urlLink.highlightStylePlugin,

          keymap.of([indentWithTab]),
          markdown({
            base: markdownLanguage,
            codeLanguages: languages,
            extensions: [
              frontmatter,

              internalLink.markdownExtension,
              attachmentLink.markdownExtension,
              globalLink.markdownExtension,
              tagsLink.markdownExtension,
              mentionsLink.markdownExtension,
              urlLink.markdownExtension,

              {
                props: [tagStyles],
              },
            ],
          }),
          EditorView.lineWrapping,
          theme,

          internalLink.componentPlugin,
          globalLink.componentPlugin,
          tagsLink.componentPlugin,
          attachmentLink.componentPlugin,
          horizontalRule.componentPlugin,
          table.componentPlugin,
          urlLink.componentPlugin,
          markdownLink.componentPlugin,

          // goToLinkPlugin,
          // hideMarks(),
          highlightSpecialChars(),
          history(),
          // foldGutter(),
          drawSelection(),
          dropCursor(),
          EditorState.allowMultipleSelections.of(true),
          indentOnInput(),
          syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
          bracketMatching(),
          closeBrackets(),
          autocompletion({
            override: autocompletions,
          }),
          rectangularSelection(),
          crosshairCursor(),
          highlightActiveLine(),
          placeholder(this.placeholder || "Create some content here"),
          highlightSelectionMatches(),
          keymap.of([
            ...closeBracketsKeymap,
            ...defaultKeymap,
            ...searchKeymap,
            ...historyKeymap,
            ...foldKeymap,
            ...completionKeymap,
            ...lintKeymap,
          ]),
          vim(),
          indentationMarkers(),

          headingSlugField,
          imagePreview,
          blockquote(),
          codeblock(),
          headings(),
          hideMarks(),
          lists(),
          image(),

          EditorView.domEventHandlers({
            drop(e: DragEvent, view: EditorView) {
              console.log("drop", e, view);
              const file = e.dataTransfer!.files[0];
              const dropPos = view.posAtCoords({ x: e.clientX, y: e.clientY });
              if (dropPos == null) {
                return;
              }

              console.log("drop file", file, dropPos);

              Storage.put(`${fileUploadPrefix}/${file.name}`, file, {
                contentType: file.type,
              }).then((result) => {
                emitFileUploaded({
                  key: result.key,
                  name: file.name,
                  size: file.size,
                  contentType: file.type,
                });

                // casting as raises error without it, but it works :)
                (view as any).dispatch({
                  changes: {
                    from: dropPos,
                    insert: `![[${result.key}|${file.name}]]`,
                  },
                });
              });

              e.preventDefault();
            },
            paste(e: ClipboardEvent | any, view: EditorView) {
              console.log("paste", e, view);
              const dropPos = view.state.selection.main.head;
              const items = (e.clipboardData || e.originalEvent.clipboardData).items;

              // find pasted image among pasted items
              let file: File | null = null;
              for (let i = 0; i < items.length; i++) {
                if (items[i].type.indexOf("image") !== 0)
                  continue;

                console.log(items[i]);
                file = items[i].getAsFile();
                if (!file)
                  continue;

                const name = generateRandomName("paste") + file.name.substring(-4);

                Storage.put(`${fileUploadPrefix}/${name}`, file, {
                  contentType: file.type,
                }).then((result) => {
                  emitFileUploaded({
                    key: result.key,
                    name: name,
                    size: file!.size,
                    contentType: file!.type,
                  });

                  // casting as raises error without it, but it works :)
                  (view as any).dispatch({
                    changes: {
                      from: dropPos,
                      insert: `![[${result.key}|${name}]]`,
                    },
                  });
                });
              }
            },
          }),

          // TODO make it configurable
          // EditorView.editable.of(false),

          // TODO after making it read-only disable current line highlight
        ],
        doc: this.internalValue,
      }),
      parent: this.$refs["editor"]! as Element,
    });
  },
  methods: {},
});
