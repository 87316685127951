



















import Vue from "vue";

export default Vue.extend({
  name: "CurrencyEditor",
  components: {},
  props: ["value", "default"],
  data() {
    return {
      internalValue: this.value ? this.value.value : 0,
      internalCurrency: this.value ? this.value.currency : '',
    };
  },
  methods: {
    onChange() {
      this.$emit("change", {
        value: this.internalValue,
        currency: this.internalCurrency,
      });
    },
  },
});
