import { PowerStorage, PowerStorageHooks } from './powerStorage';
import { relationships } from './relationships';
import { Settings } from './Settings';
import { migrateAllDatasets } from '@/migrations';
import { defaultDatasetFields } from '@/entities/base';
import { Field, FieldButton, FieldType } from '@/entities/field';

export class TableSettings {
  constructor(
    public tableId = "",
    public sortBy = [],
    public sortDesc = [] as any[],
    public groupBy = "",
    public groupsOpened = [],
    public fieldsHidden = [] as string[],
    public fieldsVisible = [] as string[],
    public hideDone = false,
    public hideNotStarted = false,
    public hideHeaders = false,
    public hideEmptyGroups = false,
    public hideRecurring = false,
  ) {
  }
}

const datasetFields = [
  Field.create("icon", "Icon", "icon"),
  Field.create("name", "Name"), //.withReadonly(),
  Field.create("label", "Label"),
  Field.create("nonIndexable", "Non indexable", "checkbox"),
  Field.create("location", "Location"),
  Field.create("quickAccessShortcut", "Quick access shortcut"),
  Field.create("fields").withHidden().withDefault(defaultDatasetFields),
  Field.create("editDatasetSchema", " ").withButton(new FieldButton("editDatasetSchema")),
  Field.create("openDataset", " ").withButton(new FieldButton("openDataset")),
  Field.create("cognitoIdentityId").withHidden(),
  Field.create("mailForwardingAlias").withHidden(),
];

const datasetHooks = new PowerStorageHooks();

datasetHooks.afterRemove = async (item: any): Promise<any> => {
  console.log("afterRemove hook (datasets)", item)
  const storage = PowerStorage.create(item.name);

  for (const field of item.fields.filter((f: Field) => f.type == FieldType.Relationship || f.type == FieldType.Reference)) {
    await relationships.destroyRelationship(field);
  }

  return storage.removeAll();
}

const tableSettingsFields = [
  Field.create("tableId"),
  Field.create("sortBy"),
  Field.create("sortDesc"),
  Field.create("groupBy"),
  Field.create("groupsOpened"),
  Field.create("fieldsHidden"),
  Field.create("fieldsVisible"),
  Field.create("dense"),
  Field.create("hideDone"),
  Field.create("hideNotStarted"),
  Field.create("hideHeaders"),
  Field.create("hideEmptyGroups"),
  Field.create("hideRecurring"),
];

const importerSettingsFields = [
  Field.create("dataset"),
  Field.create("name"),
  Field.create("skipRows"),
  Field.create("mappings"),
];

const settingsFields = [
  Field.create("name"),
  Field.create("value"),
];

const templatesFields = [
  Field.create("name"),
  Field.create("targetDataset"),
];

const weeklyReviews = [
  Field.create("name"),
  Field.create("content"),
  Field.create("checklist", undefined, "checklist"),
];

const chartSettings = [
  Field.create("name"),
  Field.create("series"),
];

const apiKeys = [
  Field.create("name"),
  Field.create("key"),
];

const oauth2Credentials = [
  Field.create("name"),
  Field.create("clientId"),
  Field.create("clientSecret"),
  Field.create("accessToken"),
  Field.create("refreshToken"),
  Field.create("expiresAt", "Expires at", "datetime"),
];

const scripts = [
  Field.create("name").withBuiltIn(),
  Field.create("content", "Code", "code").withBuiltIn(),
  // Field.create("mode", "Mode").withBuiltIn().withOptions([
  //   new FieldOption("For each item", "item", undefined, undefined, true),
  //   new FieldOption("For dataset", "dataset", undefined, undefined, true),
  // ]).withDefault("item"),
  // Field.create("cron", "Cron schedule").withBuiltIn(),
];

export class Entities {
  public static ready = false

  readonly datasets = PowerStorage.create("datasets", datasetFields, datasetHooks);
  readonly tableSettings = PowerStorage.create("tableSettings", tableSettingsFields);
  readonly importerSettings = PowerStorage.create("importerSettings", importerSettingsFields);
  readonly settings = PowerStorage.create("settings", settingsFields);
  readonly templates = PowerStorage.create("templates", templatesFields);
  readonly weeklyReviews = PowerStorage.create("weekly-reviews", weeklyReviews); // TODO refactor it one day
  readonly chartSettings = PowerStorage.create("chartSettings", chartSettings);
  readonly apiKeys = PowerStorage.create("api-keys", apiKeys); // TOTO refactor it one day...
  readonly oauth2Credentials = PowerStorage.create("oauth2Credentials", oauth2Credentials);
  readonly scripts = PowerStorage.create("scripts", scripts);

  private loadingPromise = null as Promise<void> | null;

  async registerAllDatasets() {
    if (this.loadingPromise) {
      return this.loadingPromise;
    }

    let promiseResolver = null as any;

    this.loadingPromise = new Promise((resolve) => {
      promiseResolver = resolve;
    });

    console.time("registerAllDatasets")
    // TODO it should create datasets if they don't exist
    await this.datasets.refresh();

    console.log("migrate datasets");
    await migrateAllDatasets();

    for (const ds of this.datasets.items) {
      this.ensureDefaultFields(ds);
    }

    console.timeEnd("registerAllDatasets")
    Entities.ready = true
    promiseResolver();
  }

  private ensureDefaultFields(ds: any) {
    for (const f of defaultDatasetFields) {
      if (ds.fields && !ds.fields.find((x: Field) => x.name == f.name)) {
        ds.fields.push(f);
      }
    }
  }
}

export const entities = new Entities();
export const settings = new Settings();
