import { render, staticRenderFns } from "./CurrencyEditor.vue?vue&type=template&id=569cdade&scoped=true&"
import script from "./CurrencyEditor.vue?vue&type=script&lang=ts&"
export * from "./CurrencyEditor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "569cdade",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCombobox,VTextField})
