










































import { PowerStorage } from "@/powerStorage";
import Vue from "vue";
import moment from "moment";
import { delay } from "@/helpers";

export default Vue.extend({
  name: "TagsInputWidget",
  components: {},
  props: ["autofocus"],
  data() {
    return {
      tags: "",
      time: "",
      more: false,
      showConfirmation: false,
    };
  },
  methods: {
    toggleMore() {
      this.time = moment().format("YYYY-MM-DD HH:mm:ss");
      this.more = !this.more;
    },
    async add() {
      const time = (
        this.more ? moment(this.time, moment.ISO_8601) : moment()
      ).toISOString();
      const tags = this.tags;

      this.tags = "";
      this.more = false;

      await Promise.all(
        tags.split(",").map((t) => {
          const [tag, value = 1] = t.split(":").map((x) => x.trim());

          if (!tag.trim()) {
            return;
          }

          return PowerStorage.createItem("tags", {
            name: tag,
            value: value,
            time,
          });
        })
      );

      this.showConfirmation = true;
      await delay(1500);
      this.showConfirmation = false;

      this.$emit("tagAdded");
    },
  },
});
