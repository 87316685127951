





















import Vue from "vue";
import PowerTable from "@/components/PowerTable.vue";

export default Vue.extend({
  name: "PowerTableWidget",
  components: {
    PowerTable,
  },
  props: ["datasetName", "title", "tableSettings", "filterRowFunction", "hideNewItemRow"],
  data() {
    return {};
  },
  methods: {},
});
