import { ProxiedDataset, ProxiedDatasetItem } from './sync/ProxiedDataset';
import { LocalStorageAdapter } from './sync/LocalStorageAdapter';
import { SyncClient } from './sync/SyncClient';
import { ApiConnectionAdapter } from './sync/ApiConnectionAdapter';
import { SyncCoordinator } from './sync/SyncCoordinator';
import { Field } from '@/entities/field';

type AfterHook = (item: any) => Promise<any>
type BeforeHook = (item: any) => Promise<boolean>

export class PowerStorageHooks {
  constructor(
    public beforeAdd = null as BeforeHook | null,
    public afterAdd = null as AfterHook | null,
    public beforeUpdate = null as BeforeHook | null,
    public afterUpdate = null as AfterHook | null,
    public beforeRemove = null as BeforeHook | null,
    public afterRemove = null as AfterHook | null,
  ) {
  }
}

export const storageAdapter = new LocalStorageAdapter();
export const syncClient = new SyncClient(storageAdapter, new ApiConnectionAdapter());
export const syncCoordinator = new SyncCoordinator(syncClient);

syncClient.sync();
syncCoordinator.start();

export class PowerStorage<T extends ProxiedDatasetItem = ProxiedDatasetItem> {
  public get items() {
    return this.proxiedDataset.items!;
  }

  public get loading() {
    return this.proxiedDataset.loading;
  }

  // temporary workaround during refactor to have list of all items in Datastore
  // public static allItems = [] as any[];
  public static allById = new Map<string, any>();

  private proxiedDataset: ProxiedDataset<T>;

  private constructor(
    public readonly dataset: string,
    public fields = [] as Field[],
    private hooks = null as null | PowerStorageHooks,
    lastDays = 0) {

    this.proxiedDataset = new ProxiedDataset<T>(dataset, storageAdapter, undefined, undefined, lastDays);
  }

  // this method is an optimization to directly create item in ProxiedDataset without loading all its items
  static async createItem<T extends ProxiedDatasetItem = ProxiedDatasetItem>(dataset: string, item: any) {
    return new ProxiedDataset<T>(dataset, storageAdapter, undefined, false).createItem(item)
  }

  static create<T extends ProxiedDatasetItem = ProxiedDatasetItem>(dataset: string, fields = [] as Field[], hooks = null as null | PowerStorageHooks, lastDays = 0) {
    const key = `${dataset}-${lastDays}`
    if (!this.storageByDataset.has(key)) {
      this.storageByDataset.set(key, new PowerStorage(dataset, fields, hooks, lastDays))
    }
    return this.storageByDataset.get(key)! as PowerStorage<T>;
  }

  private static storageByDataset = new Map<string, PowerStorage>()

  /**
   * @deprecated The method should not be used as it's not modifing existing item it used to when using AWS Datastore
   */
  public async save(item: any): Promise<void> {
    if (item._id) {
      console.log("save - updating entity", item);
      // return this.update(item);
    } else {
      console.log("save - creating entity", item);
      await this.create(item);
    }
  }

  public async create(item: any): Promise<T> {
    return await this.proxiedDataset.createItem(item);
  }

  public getById(id: string): T | undefined {
    return this.proxiedDataset.proxiedItems[id];
  }

  public getByName(name: string): T | undefined {
    return this.proxiedDataset.items.find((x: any) => x.name == name);
  }

  public getFieldByName(name: string): Field | undefined {
    return this.fields.find((f: Field) => f.name == name);
  }

  public getByIds(ids: string[]): T[] {
    return this.proxiedDataset.items.filter((i: any) => ids.includes(i._id));
  }

  /**
   * @deprecated The method should not be used
   */
  public getId(item: object): string {
    return (item as any)._id;
  }

  /**
   * @deprecated not needed
   */
  public async update(item: any): Promise<void> {

    // this.proxiedDataset.

    // await this.ensureFields();

    // const itemClone = this.unfreeze(item)

    // // use both fields defined in dataset (this.fields) as well as some dynamic ones (with $ at the end)
    // const fields = Array.from(new Set([...this.fields.map((f: Field) => f.name), ...Object.keys(itemClone).filter((x: string) => x.endsWith("$"))]));

    // // TODO we probably should process the result of the save and update some field like _updatedAt
    // return DataStore.save(
    //   Row.copyOf(this.originalByItem.get(item)!, (updated) => {
    //     for (const f of fields) {
    //       (updated.fields as any)[f] = itemClone[f];
    //     }
    //   })
    // );
  }

  public async remove(item: any): Promise<void> {
    if (this.hooks && this.hooks.beforeRemove) {
      if (!await this.hooks.beforeRemove(item)) {
        return Promise.reject("beforeRemove hook prevented further execution");
      }
    }

    console.log("removing", item);

    await this.proxiedDataset.deleteItem(item._id);

    if (this.hooks && this.hooks.afterRemove) {
      await this.hooks.afterRemove(item);
    }
  }

  public async ensureFields() {
    if (!this.fields || this.fields.length == 0) {
      return this.refreshFields()
    }
  }

  public async refreshFields() {
    const dataset = (await PowerStorage.create<T>("datasets").refresh(true)).getByName(this.dataset)

    if (!dataset) {
      console.error("refreshFields: cannot find dataset", this.dataset);
    }

    this.fields = dataset?.fields || []
  }

  // returning this instance for convenience of chaining
  public async refresh(skipWhenNonEmpty = false): Promise<PowerStorage<T>> {
    await this.ensureFields();
    await this.proxiedDataset.loadingPromise;
    return this;
  }

  public async getAll(): Promise<T[]> {
    return this.proxiedDataset.getAllItems();
  }

  /**
   * @deprecated not needed
   */
  public async removeAll(): Promise<void> {
    for (const item of await this.getAll()) {
      await this.remove(item);
    }
  }

  /**
   * @deprecated not needed
   */
  public async subscribe() {
    return this;
  }

  /**
   * @deprecated not needed
   */
  public unsubscribe() {
    // do nothing
  }
}

// TODO remove after troubleshooting performance
(window as any)["PowerStorage"] = PowerStorage;