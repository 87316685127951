













































































































































































import Vue from "vue";
import { relationships } from "../relationships";
import FieldEditor from "@/components/FieldEditor.vue";
import { itemsManager } from "../items";
import { Fragment } from "vue-frag";
import SourceEditorDialog from '@/components/SourceEditorDialog.vue';
import { FieldOption } from '@/entities/field';

export default Vue.extend({
  name: "InternalRow",
  components: {
    SourceEditorDialog,
    FieldEditor,
    Fragment,
  },
  data() {
    return {
      dialog: false,
      relationships,
      nestedExpand: false,
      itemsManager,
    };
  },
  props: [
    "storageEntity",
    "item",
    "fields",
    "isExpanded",
    "expand",
    "parentField",
    "level",
    "expandedField",
  ],
  methods: {
    focus(fieldName: string) {
      const r = this.$refs[fieldName] as any;
      if (r && r[0]) {
        r[0].focus();
      }
    },
    getCellStyle(field: any, item: any): string {
      const outputStyles = [];
      const fieldValue = item[field.name];

      if (field.type == "dropdown") {
        const selectedOption = field.options.find(
          (x: any) => x.value == (fieldValue?.value || fieldValue) // to support return-object for v-select
        ) as FieldOption;

        if (selectedOption && selectedOption.color) {
          outputStyles.push(`background-color: ${selectedOption.color}`);
        }

        if (selectedOption && selectedOption.colorText) {
          outputStyles.push(`color: ${selectedOption.colorText}`);
        }
      }

      if (field.width) {
        outputStyles.push(`width: ${field.width}; min-width: ${field.minWidth || field.width}`);
      }

      if (field.minWidth) {
        outputStyles.push(`min-width: ${field.minWidth}`);
      }

      if (field.maxWidth) {
        outputStyles.push(`max-width: ${field.maxWidth}`);
      }

      if (field.textAlign) {
        outputStyles.push(`text-align: ${field.textAlign}`);
      }

      if (field.style) {
        outputStyles.push(field.style);
      }

      if (field.type == "parent" || field.name == "name") {
        outputStyles.push(
          `padding-left: ${this.level * 10 + 10}px;`
        );
      }

      return outputStyles.join("; ");
    },
  },
});
