import { ConnectionAdapter, getCurrentTimestamp, setTimestampOffset, markItemSynced, isSyncNeeded } from './syncEngine';
import { StorageAdapter, VersionStorageAdapter } from './StorageAdapter';
import { SyncServer } from "./SyncServer";
import { sortByPropertyNumber } from '../helpers';

export enum SyncStatus {
  NeedsSync,
  Syncing,
  Synced,
  Failed,
}

export class SyncClient {
  private syncEngine: SyncServer;
  public status = SyncStatus.NeedsSync;

  public constructor(
    readonly storageAdapter: StorageAdapter & VersionStorageAdapter,
    readonly connectionAdapter: ConnectionAdapter,
    readonly maxItemsPerSync = 200,
  ) {
    this.syncEngine = new SyncServer(storageAdapter);

    storageAdapter.itemCreated.subscribe(_i => this.status == SyncStatus.NeedsSync);
    storageAdapter.itemDeleted.subscribe(_i => this.status == SyncStatus.NeedsSync);
    storageAdapter.itemUpdated.subscribe(_i => this.status == SyncStatus.NeedsSync);
  }

  private async syncInternal(limit: number) {
    // get last sync timestamp
    const version = await this.storageAdapter.getVersion();

    const allChangedItems = await this.storageAdapter.getItemsForSync(0); // TODO refactor as it's not needed for LocalDB
    allChangedItems.sort(sortByPropertyNumber("syncTimestamp"));
    const changedItems = allChangedItems.slice(0, limit + 1);

    // send outbox content to server
    console.log("Sync: sending client changed items", version, changedItems);
    if (changedItems.length > 0) {
      console.table(changedItems);
    }
    // console.log("********************* SENDING TO THE SERVER");
    const serverResponse = await this.connectionAdapter.sendChangeset(version, changedItems, Math.floor(limit * 5));

    setTimestampOffset(serverResponse.serverTimestamp);

    console.log("Sync: response from sync server", serverResponse.version, serverResponse.changedItems);
    if (serverResponse.changedItems.length > 0) {
      console.table(serverResponse.changedItems);
    }

    // console.log("********************* SERVER CALL FINISHED");
    // console.log("sync get server changed items", serverChangedItems);

    // should be empty for updates
    const _processedItems = await this.syncEngine.processChanges(getCurrentTimestamp(), serverResponse.changedItems);
    // console.log("sync processes client items", processedClientItems);

    // we get delete confirmations that indicate that the server knows about the deletion, so we can remove item locally
    for (const deletedItem of serverResponse.changedItems.filter(i => i.deletedAt)) {
      // console.log("deleting", deletedItem.id, "from local storage");
      await this.storageAdapter.deleteItem(deletedItem.id);
    }

    // in case the item is not changed on the server we should clear syncNeeded flag
    // TODO
    if (serverResponse.changedItems.length == 0) {
      console.log("marking all items as synced");
      for (const item of changedItems) {
        if (!isSyncNeeded(item)) continue;
        console.log("marking as synced", item);
        markItemSynced(item);
        await this.storageAdapter.updateItem(item);
      }
    }

    // update version
    await this.storageAdapter.setVersion(serverResponse.version);
  }

  public async sync() {
    if (this.status == SyncStatus.Syncing) {
      console.log("sync is already in progress, ignoring");
      return;
    }
    this.status = SyncStatus.Syncing;

    try {
      // retry with lower limits in case payload is too big for API Gateway
      for (let limit = this.maxItemsPerSync; limit > 1; limit /= 2) {
        try {
          await this.syncInternal(limit);
          break;
        } catch (e: any) {
          if (e.message == "Network Error") {
            continue;
          }

          this.status = SyncStatus.Failed;
          console.error("sync error", JSON.stringify(e));
          return;
        }
      }
      this.status = SyncStatus.Synced;
    } finally {
      // to prevent locking the sync process
      if (this.status != SyncStatus.Synced && this.status != SyncStatus.Failed) {
        this.status = SyncStatus.NeedsSync;
      }
    }
  }
}
