import moment from 'moment';
import { relationships } from './relationships';
import { PowerStorage } from './powerStorage';
import { Storage } from "aws-amplify";
import { Field, FieldType } from '@/entities/field';

// TODO unify usage of static and non-static members
export class ItemsManager {

  static getFieldForItem(item: any, ...types: string[]): Field | undefined {
    if (!item._dataset) {
      console.log("no _dataset set for item", item);
      return undefined;
    }

    return PowerStorage.create(item._dataset).fields.find((f: Field) => types.length == 0 || types.includes(f.type));
  }

  static hasRecurrenceSet(item: any): boolean {
    const recurrenceFields = ItemsManager.getFieldsForItem(item, "recurrence");

    return recurrenceFields.some(
      (f: any) =>
        item[f.name] &&
        item[f.name].repeatEvery &&
        item[f.name].repeatUnit
    );
  }

  static getFieldByName(item: any, name: string): Field | undefined {
    if (!item._dataset) {
      console.log("no _dataset set for item", item);
      return undefined;
    }

    return PowerStorage.create(item._dataset).getFieldByName(name);
  }

  static getFieldsForItem(item: any, ...types: string[]): Field[] {
    if (!item._dataset) {
      console.log("no _dataset set for item", item);
      return [];
    }

    return PowerStorage.create(item._dataset).fields.filter((f: Field) => types.length == 0 || types.includes(f.type));
  }

  static getFieldsForDataset(dataset : string, ...types: string[]): Field[] {
    return PowerStorage.create(dataset).fields.filter((f: Field) => types.length == 0 || types.includes(f.type));
  }

  static saveItem(item: any) {
    if (!item._dataset) {
      console.log("no _dataset set for item", item);
      return;
    }

    return PowerStorage.create(item._dataset).save(item);
  }

  private async onStatusChange(item: any, field: Field, event: any) {
    const storageEntity = PowerStorage.create(item._dataset);

    if (!item.statusHistory$) {
      item.statusHistory$ = [];
    }

    item.statusHistory$.push({
      time: moment().toISOString(),
      to: event,
      from: item.status,
    });

    // check if status transitioned to "done" state
    const selectedOption = field.options!.find((fo) => fo.value == event);
    if (selectedOption?.statusGroup != "done") {
      item.doneAt$ = undefined;
      return;
    }

    console.log("item", item, "transitioned to done status");

    // update doneAt$ field (ugly, I know)
    item.doneAt$ = moment().toISOString();

    // get field with recurring settings
    const recurringFields = ItemsManager.getFieldsForItem(item, "recurrence");
    for (const recurringField of recurringFields) {

      // check if recurring settings are set
      if (
        !recurringField ||
        !item[recurringField.name] ||
        !item[recurringField.name].repeatEvery ||
        !item[recurringField.name].repeatUnit ||
        !item[recurringField.name].repeatBasedOn
      ) {
        console.log("no recurrence field found");
        return;
      }

      // duplicate item

      // TODO refactor and extract somewhere else

      const replacer = (key: string, value: any): any => {
        if (key.startsWith("_") || key.endsWith("$") || key.endsWith("&")) return undefined;
        else return value;
      };

      let newItem = JSON.parse(JSON.stringify(item, replacer));

      // update dates for recurrence
      const recurrence = item[recurringField.name];
      let baseDate = null as any;

      switch (recurrence.repeatBasedOn) {
        case "now":
        case "date of closure":
          baseDate = moment();
          break;

        case "field":
          // handled below
          break;

        case "date":
          // TODO add field for specific date
          break;
      }

      for (const dateField of ItemsManager.getFieldsForItem(item, "date", "datetime")) {
        if (!item[dateField.name]) {
          continue;
        }

        if (recurrence.repeatBasedOn == "field") {
          baseDate = item[dateField.name];
        }

        const targetDate = moment(baseDate, "YYYY-MM-DD").add(
          recurrence.repeatEvery,
          recurrence.repeatUnit
        );

        newItem[dateField.name] = targetDate.format("YYYY-MM-DD");

        console.log(
          "field",
          dateField,
          "baseDate",
          baseDate,
          "targetDate",
          targetDate.toString()
        );
      }

      // update status
      if (recurrence.setStatus) {
        newItem.status = recurrence.setStatus;
      }

      if (newItem.checklist && newItem.checklist.length > 0) {
        for (const checklistItem of newItem.checklist) {
          checklistItem.done = false;
        }
      }

      // create new item with recalculated dates
      // we create using PowerStorage as copied item doesn't have _dataset property
      newItem = await storageEntity.create(newItem);

      for (const relField of ItemsManager.getFieldsForItem(item, FieldType.Relationship, FieldType.Reference)) {
        await relationships.updateRelationships(
          newItem,
          relField,
          item[relField.name] || [],
          storageEntity
        );
      }
    }
  }

  public async updateItem(item: any, updatedField: Field, newValue: any) {
    console.log("updateItem", item, updatedField, newValue);

    if (updatedField.type == "dropdown" && updatedField.name == "status") {
      // handle status change
      await this.onStatusChange(item, updatedField, newValue);
    }

    if (updatedField.type == "date") {
      this.checkPostponed(item, updatedField, "doDate", newValue);
      this.checkPostponed(item, updatedField, "dueDate", newValue);
    }

    item[updatedField.name] = newValue;

    for (const relField of ItemsManager.getFieldsForItem(item, FieldType.Relationship, FieldType.Reference)) {
      if (
        relField.relationship && // check if relationship is properly setup
        (
          relField.relationship.writeSourceProperty == updatedField.name ||
          (
            relField.relationship.writeSourceAdditionalProperties &&
            relField.relationship.writeSourceAdditionalProperties.includes(updatedField.name)
          )
        )
        // check if the field is referenced as the source for other side (no need to update other item if the change doesn't matter)
      ) {
        await ItemsManager.saveItem(item);
        await relationships.updateRelationships(
          item,
          relField,
          item[relField.name] || [],
          PowerStorage.create(item._dataset)
        );
        return;
      }
    }

    await ItemsManager.saveItem(item);
  }

  public async remove(item: any) {
    const storageEntity = PowerStorage.create(item._dataset);

    console.log("starting removing item", item.name, item)

    for (const f of ItemsManager.getFieldsForItem(item)) {
      if (f.type == FieldType.Relationship || f.type == FieldType.Reference) {
        console.log("removing relationships");
        await relationships.removeRelationships(item, f);
      }
      if (f.type == "parent") {
        for (const child of storageEntity.items.filter(
          (x: any) => x[f.name] == item._id
        )) {
          console.log("removing child item", child.name, child);
          await itemsManager.remove(child);
        }
      }
      if (f.type == "files") {
        console.log("removing files");
        for (const file of item[f.name] || []) {
          await Storage.remove(file.key);
        }
      }
    }

    console.log("removing item", item.name, item);
    await storageEntity.remove(item);
  }

  private checkPostponed(item: any, field: Field, fieldName: string, newValue: any) {
    if (field.name != fieldName || !item[fieldName] || !newValue) {
      return;
    }

    const currentDate = moment(item[fieldName], "YYYY-MM-DD");
    const newDate = moment(newValue, "YYYY-MM-DD");

    if (newDate.isAfter(currentDate)) {
      const postponedFieldName = `${fieldName}PostponedCount$`;
      item[postponedFieldName] = (item[postponedFieldName] || 0) + 1
      item["postponedCount$"] = (item["doDatePostponedCount$"] || 0) + (item["dueDatePostponedCount$"] || 0);
    }
  }
}

export const itemsManager = new ItemsManager();