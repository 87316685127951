




































































import Vue from "vue";
import DropdownEditor from "./DropdownEditor.vue";
import { FieldRecurrence } from '@/entities/field';

export default Vue.extend({
  name: "RecurrenceEditor",
  components: { DropdownEditor },
  props: ["value", "statusOptions", "defaultStatus", "singleItemView"],
  data() {
    return {
      menu: false,
      internalValue:
        this.value ||
        ({
          repeatBasedOn: "field",
          setStatus: this.defaultStatus,
        } as FieldRecurrence),
    };
  },
  watch: {
    value() {
      this.internalValue =
        this.value ||
        ({
          repeatBasedOn: "field",
          setStatus: this.defaultStatus,
        } as FieldRecurrence);
    },
  },
  methods: {
    getDisplayValue() {
      if (this.internalValue.repeatEvery && this.internalValue.repeatUnit) {
        return `every ${this.internalValue.repeatEvery} ${this.internalValue.repeatUnit}`;
      }

      return "not recurring";
    },
    getStyle() {
      if (!this.internalValue.repeatEvery || !this.internalValue.repeatUnit) {
        return "color: #ddd";
      }
    },
  },
});
