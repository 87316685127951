import { generateRandomName, onlyUnique, debounce } from '../helpers';
import { Item, getCurrentTimestamp, markItemForSync } from './syncEngine';
import { StorageAdapter } from "./StorageAdapter";
import Vue from 'vue';
import { filter } from 'rxjs';
import moment from 'moment';

export interface ProxiedDatasetItem {
  [key: string]: any

  readonly _id: string
  readonly _updatedAt: string
  readonly _createdAt: string
  readonly _dataset: string
}

export class ProxiedDataset<T extends ProxiedDatasetItem = ProxiedDatasetItem> {
  private readonly updatesChannelSourceId = generateRandomName();
  // private readonly updatesChannel: BroadcastChannel;
  public readonly proxiedItems = {} as any;
  // consider moving that to child class
  public readonly loadingPromise?: Promise<T[]>;
  public loading = true;

  public get items(): T[] {
    return Object.values(this.proxiedItems);
  }

  private updateFunc = Vue.set;
  private deleteFunc = Vue.delete;

  constructor(public readonly datasetName: string,
              private readonly storageAdapter: StorageAdapter,
              private readonly autosave = true,
              autoload = true,
              private lastDays = 0) {

    storageAdapter.itemCreated.pipe(filter(i => i.dataset == this.datasetName)).subscribe(i => this.createProxy(i));
    storageAdapter.itemDeleted.subscribe(i => this.onItemDeleted(i)); // TODO check that for filtering
    storageAdapter.itemUpdated.pipe(filter(i => i.dataset == this.datasetName)).subscribe(i => this.onItemUpdated(i));

    // if (window.BroadcastChannel) {
    //   this.updatesChannel = new BroadcastChannel("events-" + datasetName);
    //   this.updatesChannel.onmessage = (e: MessageEvent<any>) => this.onBroadcastEventReceived(e);
    // }

    if (autoload) {
      this.loadingPromise = this.getAllItems();
    }
  }

  private onBroadcastEventReceived(e: MessageEvent<any>) {
    if (!e || !e.data || !e.data.id) return;
    if (e.data.sourceId == this.updatesChannelSourceId) return; // ignore own messages
    // TODO probably not so optimal...

    this.getItem(e.data.id);
  }

  private onItemDeleted(id: string) {
    this.deleteFunc(this.proxiedItems, id)
  }

  private onItemUpdated(item: Item) {
    if (!item.deletedAt)
      this.createProxy(item); // probably not so optimal...

    // this.updatesChannel.postMessage({
    //   id: item.id,
    //   sourceId: this.updatesChannelSourceId
    // });
  }

  async createItem(template?: any): Promise<T> {
    const item = {} as Item;

    const currentTimestamp = getCurrentTimestamp();

    item.id = template?._id ?? generateRandomName();
    item.dataset = template?._dataset || this.datasetName; // for special cases like migrating items to new storage
    item.createdAt = template?._createdAt ?? currentTimestamp;
    item.updatedAt = template?._updatedAt ?? currentTimestamp;
    item.fields = {};
    item.fieldsUpdatedAt = {};
    item.syncTimestamp = currentTimestamp;
    markItemForSync(item);

    if (template._fields) {
      for (const f of Object.keys(template._fields).filter(f => !f.startsWith("_"))) {
        console.log("set field from template", f, "to", template._fields[f]);
        item.fields[f] = template._fields[f];
        item.fieldsUpdatedAt[f] = currentTimestamp;
      }
    }
    if (template) {
      for (const f of Object.keys(template).filter(f => !f.startsWith("_"))) {
        console.log("set field", f, "to", template[f]);
        item.fields[f] = template[f];
        item.fieldsUpdatedAt[f] = currentTimestamp;
      }
    }

    return this.createProxy(await this.storageAdapter.createItem(item))!;
  }

  private createProxy(item: Item | null): T | null {
    if (item == null)
      return null;

    const proxiedItem = new Proxy<T>(item.fields || {}, this.proxyHandler(item));

    this.updateFunc(this.proxiedItems, item.id, proxiedItem);

    return proxiedItem;
  }

  private proxyHandler(item: Item): ProxyHandler<any> {
    return {
      set: (target, key: string, value, _receiver) => {
        if ((item.fields[key] && item.fields[key] == value) || key == "isTrusted") return true;

        // console.log("proxy set for item", item.dataset, item.id, "target", target, "key", key, "value", value, "existing value", item.fields[key]);

        if (key.startsWith("_") || key.endsWith("&")) {
          target[key] = value;
          return true;
        }

        const currentTimestamp = getCurrentTimestamp();
        this.updateFunc(item.fields, key, value);

        this.markFieldAsChanged(item, key, currentTimestamp);

        return true;
      },
      get: (target, key: string, _receiver) => {
        if (key == "_isProxy") return true; // meta property for tracking proxies
        if (typeof key === "string" && key.endsWith("&")) { // calculated properties
          return target[key];
        }

        if (!item || !item.fields) // handle case where item is deleted and fields are undefined
          return undefined;

        if (typeof item.fields[key] === "object" && item.fields[key] != null && !item.fields[key]._isProxy && !key.startsWith("_")) {
          // console.log("creating proxy for", key, "existing value", item.fields[key]);
          item.fields[key] = new Proxy(item.fields[key], this.nestedObjectProxy(item, key));
          return item.fields[key];
        }

        switch (key) {
          case "_dataset":
            return item.dataset;
          case "_id":
            return item.id;
          case "_updatedAt":
            if(!item.updatedAt)
              return moment(item.createdAt).toISOString();
            return moment(item.updatedAt).toISOString();
          case "_createdAt":
            return moment(item.createdAt).toISOString();
          case "_deletedAt":
            return moment(item.deletedAt).toISOString();
          default:
            return item.fields[key];
        }
      },
      ownKeys: (_target) => {
        return [...Object.keys(item.fields), "_id", "_dataset", "_updatedAt", "_createdAt"]
          .filter(onlyUnique)
          .filter(f => f != "isTrusted");
      },
      getOwnPropertyDescriptor: (target, prop: string) => {
        if (["_id", "_dataset", "_updatedAt", "_createdAt"].includes(prop)) {
          return {
            enumerable: true,
            configurable: true,
            writable: false,
          };
        }
        return Reflect.getOwnPropertyDescriptor(target, prop);
      }
    };
  }

  private nestedObjectProxy(item: Item, baseField: string): ProxyHandler<any> {
    return {
      get: (target, key: string, _receiver) => {
        if (key == "_isProxy") return true; // meta property for tracking proxies

        if (target[key] && typeof target[key] === "object" && !target[key]._isProxy && !key.startsWith("_")) {
          target[key] = new Proxy(target[key], this.nestedObjectProxy(item, baseField));
          return target[key];
        }

        return target[key];
      },
      set: (target, key: string, value) => {
        if (target[key] === value || key == "isTrusted") return true;

        if (!key.startsWith("_")) {
          // console.log("nestedObjectProxy", "set", baseField, key, " = ", value);
          this.markFieldAsChanged(item, baseField);
        }

        target[key] = value;
        return true;
      },
    }
  }

  private debounceCache = {} as any;

  private markFieldAsChanged(item: Item, field: string, timestamp = 0) {
    const t = timestamp || getCurrentTimestamp();
    this.updateFunc(item.fieldsUpdatedAt, field, t);
    if (!field.endsWith("$")) {
      this.updateFunc(item, "updatedAt", t);
    }
    item.syncTimestamp = Math.max(item.syncTimestamp || 0, t);
    markItemForSync(item);

    if (!this.autosave) return;

    if (!this.debounceCache[item.id]) {
      this.debounceCache[item.id] = debounce(() => {
        this.storageAdapter.updateItem(item);
        delete this.debounceCache[item.id];
      }, 100);

      this.debounceCache[item.id]();
    }
  }

  async updateItem(item: T): Promise<boolean> {
    const existingItem = await this.storageAdapter.getItem(item._id, true);
    if (!existingItem)
      return false;

    const currentTimestamp = getCurrentTimestamp();
    let changesMade = false;

    if(existingItem.deletedAt) {
      console.log("updating of deleted item - undeleting it for consistency");
      existingItem.fields = {}
      existingItem.fieldsUpdatedAt = {}
      existingItem.createdAt = currentTimestamp;
      existingItem.deletedAt = undefined;
      changesMade = true;
    }

    for (const f in item) {
      if (f.startsWith("_") || f == "isTrusted") continue;
      if (item[f] == existingItem.fields[f]) continue;
      existingItem.fields[f] = item[f];
      existingItem.fieldsUpdatedAt[f] = currentTimestamp;

      changesMade = true;
    }

    if (changesMade) {
      existingItem.updatedAt = currentTimestamp;
      existingItem.syncTimestamp = currentTimestamp;
      markItemForSync(existingItem);
    }

    return this.storageAdapter.updateItem(existingItem);
  }

  async deleteItem(id: string): Promise<boolean> {
    this.deleteFunc(this.proxiedItems, id);

    const existingItem = await this.storageAdapter.getItem(id);
    if (!existingItem)
      return true;

    const currentTimestamp = getCurrentTimestamp();
    existingItem.deletedAt = currentTimestamp;
    existingItem.syncTimestamp = currentTimestamp;
    markItemForSync(existingItem);

    return this.storageAdapter.updateItem(existingItem);
  }

  async getItem(id: string, includeDeleted?: boolean | undefined): Promise<T | null> {
    return this.createProxy(await this.storageAdapter.getItem(id, includeDeleted));
  }

  async getAllItems(includeDeleted?: boolean | undefined): Promise<T[]> {
    try {
      return (await this.storageAdapter.getItemsByDataset(this.datasetName, includeDeleted))
        .filter(i => !this.lastDays || moment(i.syncTimestamp || i.updatedAt).isSameOrAfter(moment().add(-this.lastDays, "days"), "days")) // TODO consider using index by time for that
        .map(i => this.createProxy(i)) as T[];
    } finally {
      this.loading = false;
    }
  }
}
