



























































import { PowerStorage } from "@/powerStorage";
import Vue from "vue";
import moment from "moment";
import { sortByProperty } from "../../helpers";
import TimeAgo from "../TimeAgo.vue";

export default Vue.extend({
  name: "TagsLatestWidget",
  components: { TimeAgo },
  props: ["count"],
  data() {
    return {
      storage: PowerStorage.create("tags", undefined, undefined, 4),
    };
  },
  computed: {
    latestTags() : any {
      return this.storage.items
        .slice()
        .sort(sortByProperty("_createdAt"))
        .reverse()
        .slice(0, this.count || 12);
    },
  },
  methods: {
    async addTag(tag: any) {
      await this.storage.create({
        name: tag.name,
        value: tag.value,
        time: moment().toISOString(),
      });

      this.$emit("tagAdded");
    },
    async deleteTag(tag: any) {
      await this.storage.remove(tag);
      this.$emit("tagDeleted");
    },
  },
});
