import { getDataset, getDatasetIcon, getDatasetLabel, getDatasetLocation, getDatasetQuickAccessShortcut, getDecoratorValue } from '@/decorators';
import { PowerStorage } from '@/powerStorage';
import { Calendar, CalendarEvent, CalendarRule } from '@/entities/calendar';
import { DatasetItem } from '@/entities/base';
import { Field } from '@/entities/field';
import { MonthlyReview, QuarterlyReview, WeeklyReview } from '@/entities/journal';
import { ActionItem, ProjectItem } from '@/entities/ppv';
import { FileItem } from '@/entities/files';
import { AccountItem, CategorizationRuleItem, CategoryItem, TransferItem } from '@/entities/finance';

export const migrateAllDatasets = async () => {
  await migrateDataset(CalendarEvent);
  await migrateDataset(Calendar);
  await migrateDataset(CalendarRule);
  await migrateDataset(WeeklyReview);
  await migrateDataset(MonthlyReview);
  await migrateDataset(ActionItem)
  await migrateDataset(ProjectItem);
  await migrateDataset(QuarterlyReview);
  await migrateDataset(FileItem);
  await migrateDataset(TransferItem);
  await migrateDataset(AccountItem);
  await migrateDataset(CategoryItem);
  await migrateDataset(CategorizationRuleItem);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const migrateDataset = async (T: Function, datasetName = "") => {
  if (!datasetName) {
    datasetName = getDataset(T);
  }
  if (!datasetName) {
    throw new Error("cannot determine dataset name");
  }

  console.log("migrating dataset", datasetName);

  const storageEntityDatasets = await PowerStorage.create<DatasetItem>("datasets").refresh();

  const desiredFields = getFieldsFromClass(T);

  const dataset = storageEntityDatasets.getByName(datasetName);
  if (!dataset) {
    console.log("creating dataset", datasetName)
    await storageEntityDatasets.create(JSON.parse(JSON.stringify({
      name: datasetName,
      label: getDatasetLabel(T) || datasetName,
      icon: getDatasetIcon(T),
      location: getDatasetLocation(T),
      quickAccessShortcut: getDatasetQuickAccessShortcut(T),
      fields: desiredFields,
    })) as DatasetItem);
  } else {
    for (const f of desiredFields) {
      // TODO add updating of null fields based on decorators for the dataset
      // this will allow e.g. adding icons or location later
      if (dataset.fields && !dataset.fields.find(x => x.name == f.name)) {
        console.log("adding field to existing dataset", f);
        dataset.fields.push(f);
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const getFieldsFromClass = (T: Function): Field[] => {
  const output: Field[] = [];
  // eslint-disable-next-line
  // @ts-ignore
  const keys = Object.keys(new T()).filter(x => !x.startsWith("_"));

  for (const itemProp of keys) {
    const f = new Field();
    // fill all fields based on the Field class
    for (const fieldProp in f) {
      if (!(f as any)[fieldProp]) {
        const valueToSet = getDecoratorValue(fieldProp, T.prototype, itemProp);
        if (valueToSet != undefined) {
          (f as any)[fieldProp] = valueToSet;
        }
      }
    }

    // override some system fields
    f.name = itemProp;

    const type = getDecoratorValue("type", T.prototype, itemProp);
    if (type) {
      f.type = type;
    }

    output.push(f);
  }

  return output;
}