import { render, staticRenderFns } from "./GlobalSearchDialog.vue?vue&type=template&id=49e560c6&scoped=true&"
import script from "./GlobalSearchDialog.vue?vue&type=script&lang=ts&"
export * from "./GlobalSearchDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e560c6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VAutocomplete,VCard,VCardText,VDialog})
