























import Vue from "vue";
import { Configuration, OpenAIApi } from "openai";
import { settings } from "@/entities";

export default Vue.extend({
  name: "GptChat",
  components: {},
  props: ["contextMessages", "initialQuery"],
  data() {
    return {
      openai: null as any,
      query: "",
      messages: [] as { role: string, content: string }[],
    };
  },
  async created() {
    await settings.load();
    const configuration = new Configuration({
      apiKey: settings.get("openaiApiKey"),
    });
    this.openai = new OpenAIApi(configuration);

    this.messages.push({
      role: "system",
      content: "respond with short sentences",
    });

    if (this.contextMessages) {
      this.messages.push(...this.contextMessages.map((m: string) => ({
        role: "system",
        content: m,
      })));
    }

    if (this.initialQuery) {
      await this.sendChatMessage(this.initialQuery);
    }
  },
  methods: {
    async sendChatMessage(content: string) {
      this.messages.push({
        role: "user",
        content,
      });

      const completion = await this.openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: this.messages
      });

      const reply = completion.data.choices[0].message;

      console.log(reply);
      console.log("tokens used:", completion.data.usage.total_tokens);

      this.messages.push(reply);
    },
  },
});
