






























import Vue from "vue";
import AppLayout from "@/layouts/AppLayout.vue";
import LoginLayout from "@/layouts/LoginLayout.vue";
import Loader from "@/components/Loader.vue";
import { Auth } from "aws-amplify";
import update from "./mixins/update";
// eslint-disable-next-line
// @ts-ignore
import config from "./aws-exports";
import { parseJwt } from "./helpers";
import moment from "moment";
import * as scheduler from "@/scheduler";

export default Vue.extend({
  name: "App",

  mixins: [update],

  components: {
    AppLayout,
    LoginLayout,
    Loader,
  },

  async mounted() {
    scheduler.setup();

    try {
      let canAuthenticateOnline = true;
      try {
        // TODO check if this is good way to determine offline mode
        // const session = await Auth.currentSession();

        this.user = await Auth.currentAuthenticatedUser();
      } catch (e) {
        if (e == "The user is not authenticated") {
          this.user = null;
          canAuthenticateOnline = false;
        }
      }

      if (!canAuthenticateOnline) {
        console.log("performing offline check of access token");

        const lastUser = window.localStorage.getItem(
          `CognitoIdentityServiceProvider.${config.aws_user_pools_web_client_id}.LastAuthUser`
        );

        const accessTokenString = window.localStorage.getItem(
          `CognitoIdentityServiceProvider.${config.aws_user_pools_web_client_id}.${lastUser}.accessToken`
        );

        if (accessTokenString) {
          const accessToken = parseJwt(accessTokenString);
          const exp = moment(accessToken.exp * 1000);

          console.log("token expiration", exp.toISOString());

          // extend the token expiration (unsecure!)
          if (moment().isBefore(exp.add(1, "day"))) {
            // auth ok
            this.user = {
              username: lastUser, // TODO check if this is valid
            };
          } else {
            // token expired
            console.log("token expired, unable to auth offline");
            this.user = null;
          }
        }
      }
    } finally {
      this.loading = false;
    }
  },

  data() {
    return {
      loading: true,
      user: null as any,
    };
  },
});
