


































import Vue from "vue";
import { Auth } from "aws-amplify";
import { entities } from "@/entities";
import { generateRandomName } from "@/helpers";

export default Vue.extend({
  name: "MailAddressManager",
  props: ["dataset"],
  data() {
    return {
      items: [] as any[],
      internalDataset: this.dataset,
      copied: false,
    };
  },
  methods: {
    async copy() {
      if (!navigator.clipboard) {
        return;
      }
      await navigator.clipboard.writeText(
        `${this.internalDataset.mailForwardingAlias}@items.bluetable.app`
      );
      this.copied = true;
    },
    async resetAddress() {
      this.internalDataset.mailForwardingAlias = generateRandomName(
        this.internalDataset.name
      );
      this.internalDataset.cognitoIdentityId = (
        await Auth.currentCredentials()
      ).identityId;

      await entities.datasets.save(this.internalDataset);
    },
  },
  async created() {
    if(!this.internalDataset) {
      return;
    }
    if (!this.internalDataset.mailForwardingAlias) {
      await this.resetAddress();
    }
  },
});
