


























































































































































































































































































































import Vue from "vue";
import {
  entities,
  TableSettings,
  settings,

} from "../entities";
import InternalPowerTable from "@/components/InternalPowerTable.vue";
import { PowerStorage } from "../powerStorage";
import moment from "moment";
import Loader from "@/components/Loader.vue";
import MailAddressManager from "@/components/MailAddressManager.vue";
import { runUserCode } from "@/evaluator";
import { Field, FieldOption, FieldType } from '@/entities/field';

export default Vue.extend({
  name: "PowerTable",
  components: {
    InternalPowerTable,
    Loader,
    MailAddressManager,
  },
  data() {
    return {
      loadingStatus: "loading...",
      settings: {} as any,
      addingInProgress: false,
      search: "",
      fieldsVisibility: {} as any,
      dataset: null as any,
      datasetNotExisting: false,
      ready: false,
      scriptsEntity: PowerStorage.create("scripts"),

      storageEntity: null as any,
      nestedStorageEntity: null as any,
      nestedStorageDatasetName: "",
      nestedFilterField: null as Field | null,
      parentField: null as Field | null,
    };
  },
  props: [
    "id",
    "filterLinkedItem",
    "filterField",
    "hideToolbar",
    "datasetName",
    "title",
    "defaultTableSettings",
    "overrideTableSettings",
    "filterRowFunction",
    "hideNewItemRow",
  ],
  async created() {
    // console.time(`created()-${this.datasetName}`);
    this.loadingStatus = "loading datasets...";
    await entities.datasets.refresh(true);

    // console.timeLog(`created()-${this.datasetName}`, "after datasets refresh");

    this.dataset = entities.datasets.items.find(
      (x: any) => x.name == this.datasetName
    );

    if (!this.dataset && this.datasetName != "datasets") {
      console.log("no such dataset");
      this.datasetNotExisting = true;
      return;
    }

    this.loadingStatus = "loading items...";
    const storage = PowerStorage.create(this.datasetName);
    await storage.refresh();

    // console.timeLog(`created()-${this.datasetName}`, "after storage refresh");

    this.loadingStatus = "loading related items...";
    for (const f of storage.fields) {
      if (f.type == FieldType.Relationship) {
        await PowerStorage.create(f.relationship!.dataset).subscribe();
      }

      if (f.type == "expand" && f.expand) {
        const field = storage.getFieldByName(f.expand)!;

        if (field.type == FieldType.Relationship) {
          this.nestedFilterField = field;

          this.nestedStorageEntity = PowerStorage.create(
            field.relationship!.dataset
          );

          // console.log("!!! nested dataset", field.relationship?.dataset);

          await this.nestedStorageEntity.subscribe();

          this.nestedStorageDatasetName = this.nestedStorageEntity.dataset;
        }
      }

      if (f.type == "parent") {
        this.parentField = f;
      }

      if (f.type == "rollup") {
        const relationshipField = storage.fields.find(
          (fx: Field) => fx.name == f.rollup?.relationshipField
        );

        await PowerStorage.create(
          relationshipField!.relationship!.dataset
        ).subscribe();
      }
    }

    // console.timeLog(
    //   `created()-${this.datasetName}`,
    //   "after related fields subscription"
    // );

    storage.subscribe();
    this.storageEntity = storage;

    // console.timeLog(`created()-${this.datasetName}`, "after subscription");
    this.loadingStatus = "loading table settings...";

    await this.loadTableSettings();

    // console.timeLog(`created()-${this.datasetName}`, "after loadTableSettings");

    this.loadingStatus = "rendering table...";
    this.ready = true;
    // console.timeEnd(`created()-${this.datasetName}`);
  },
  methods: {
    getItems() {
      let hideStatuses = null as null | any[];

      if (this.settings.hideDone || this.settings.hideNotStarted) {
        const field = this.storageEntity.fields.find(
          (x: any) => x.name == "status"
        );
        hideStatuses = field.options
          .filter(
            (x: FieldOption) =>
              (this.settings.hideDone && x.statusGroup == "done") ||
              (this.settings.hideNotStarted && x.statusGroup == "notStarted")
          )
          .map((x: FieldOption) => x.value);
      }

      let recurringField = null as null | any;

      if (this.settings.hideRecurring) {
        recurringField = this.storageEntity.fields.find(
          (x: any) => x.type == "recurrence"
        );
      }

      let filterLinkedIds = null as null | any[];
      let filterLinkedFieldNameCheckRelated: string | undefined = "";
      if (this.filterLinkedItem && this.filterField) {
        filterLinkedIds = (
          this.filterLinkedItem[this.filterField.name] || []
        ).map((x: any) => (x.id || x._id));

        // we're in the context of linked dataset
        // we need to get FieldRelationship from filterLinkedItem dataset and then read which property
        // should be used to read ID for comparison with filterLinkedItem
        const f = PowerStorage.create(this.filterLinkedItem._dataset).getFieldByName(this.filterField.name);
        filterLinkedFieldNameCheckRelated = f?.relationship?.writeTargetProperty;
      }

      if (
        this.filterRowFunction ||
        hideStatuses ||
        recurringField ||
        filterLinkedIds ||
        filterLinkedFieldNameCheckRelated
      ) {
        return this.storageEntity.items.filter(
          (x: any) =>
            (
              // this search is faster, so we do it first for typical relationships
              !filterLinkedIds
              || filterLinkedIds.includes(x._id)
              || filterLinkedIds.includes(x.id)
              // this is for "weak" relationships where only one side of relationship contains references
              || (
                filterLinkedFieldNameCheckRelated
                && x[filterLinkedFieldNameCheckRelated]
                && x[filterLinkedFieldNameCheckRelated].some((y: any) => (y._id || y.id) == this.filterLinkedItem._id)
              )
            ) &&
            (!recurringField || !x[recurringField.name]) &&
            (!this.filterRowFunction || this.filterRowFunction(x)) &&
            (!hideStatuses || !hideStatuses.includes(x.status))
        );
      }

      return this.storageEntity.items;
    },
    async loadTableSettings() {
      if (this.overrideTableSettings) {
        this.settings = this.overrideTableSettings;
        return;
      }
      if (!this.id) {
        return;
      }
      await entities.tableSettings.refresh(true);

      this.settings = entities.tableSettings.items.find(
        (x: any) => x.tableId == this.id
      );

      if (!this.settings) {
        const fieldsHidden = [] as string[];

        if (this.nestedFilterField) {
          fieldsHidden.push(
            this.nestedFilterField!.relationship!.writeTargetProperty
          );
        }

        const tableSettings = (this.defaultTableSettings ||
          {}) as TableSettings;

        tableSettings.tableId = this.id;
        tableSettings.fieldsHidden = fieldsHidden;

        this.settings = tableSettings;
      }
    },
    async optionsUpdated(event: any) {
      let needsUpdate = false;

      event.sortBy = event.sortBy || [];
      event.sortDesc = event.sortDesc || [];
      this.settings.sortBy = this.settings.sortBy || [];
      this.settings.sortDesc = this.settings.sortDesc || [];

      if (event.sortBy.length != this.settings.sortBy.length) {
        needsUpdate = true;
      } else {
        for (let i = 0; i < event.sortBy.length; i++) {
          if (event.sortBy[i] != this.settings.sortBy[i]) {
            needsUpdate = true;
            break;
          }
        }
      }
      if (event.sortDesc.length != this.settings.sortDesc.length) {
        needsUpdate = true;
      } else {
        for (let i = 0; i < event.sortDesc.length; i++) {
          if (event.sortDesc[i] != this.settings.sortDesc[i]) {
            needsUpdate = true;
            break;
          }
        }
      }

      if (event.sortDesc.length > 0) {
        this.settings.sortDesc = event.sortDesc;
      }

      if (event.sortBy.length == 0 && this.settings.sortDesc.length > 0) {
        this.settings.sortDesc = [];
        needsUpdate = true;
      }

      if (!needsUpdate) {
        return;
      }

      this.settings.sortBy = event.sortBy;

      await this.updateSettings();
    },
    async updateSettings(): Promise<any> {
      if (
        !this.settings ||
        !this.settings.tableId ||
        this.overrideTableSettings
      ) {
        return;
      }
      console.log("saving settings", this.settings);
      return entities.tableSettings.save(this.settings);
    },
    getGroupedItems(items: any, groupByField: string) {
      const output = [] as any[];
      const field = this.storageEntity.fields.find(
        (x: any) => x.name == groupByField
      );

      if (field.type == FieldType.Relationship) {
        for (const item of items) {
          for (const relatedItem of item[field.name] || []) {
            let found = false;
            for (const i in output) {
              if (output[i].key == relatedItem[field.relationship.property]) {
                found = true;
                // push to existing group
                output[i].items.push(item);
              }
            }
            if (!found) {
              // create new group
              output.push({
                key: relatedItem[field.relationship.property],
                value: relatedItem[field.relationship.property],
                add: [relatedItem],
                field,
                items: [item],
              });
            }
          }
        }
        output.push({
          key: "Not set", // display name for the group // TODO rename
          value: "notSet", // value is used for saving opened groups
          add: [], // default value for new items
          field,
          items: items.filter(
            (x: any) => !x[field.name] || x[field.name].length == 0
          ),
        });
      }

      if (field.type == "dropdown") {
        for (const option of field.options) {
          output.push({
            key: option.label,
            value: option.value,
            add: option.value,
            color: option.color, // for coloring group
            colorText: option.colorText,
            field,
            items: items.filter((x: any) => x[field.name] == option.value),
          });
        }
        output.push({
          key: "Not set",
          value: "notSet",
          add: "",
          field,
          items: items.filter((x: any) => !x[field.name]),
        });
        // TODO handle items without value set or with invalid value
      }

      if (["date", "datetime", "createdAt", "updatedAt"].includes(field.type)) {
        const today = moment().startOf("day");

        output.push({
          key: "Older than last week",
          value: "olderThanLastWeek",
          add: moment().add(-8, "day").format("YYYY-MM-DD"),
          field,
          items: items.filter(
            (x: any) =>
              x[field.name] && moment(x[field.name]).diff(today, "days") < -7
          ),
        });

        output.push({
          key: "Last week",
          value: "lastWeek",
          add: moment().add(-1, "day").format("YYYY-MM-DD"),
          field,
          items: items.filter(
            (x: any) =>
              x[field.name] &&
              moment(x[field.name]).diff(today, "days") > -7 &&
              moment(x[field.name]).diff(today, "days") < 0
          ),
        });

        output.push({
          key: "Today",
          value: "today",
          add: today.format("YYYY-MM-DD"),
          field,
          items: items.filter(
            (x: any) =>
              x[field.name] && moment(x[field.name]).diff(today, "days") == 0
          ),
        });

        output.push({
          key: "Tomorrow",
          value: "tomorrow",
          add: moment().add(1, "day").format("YYYY-MM-DD"),
          field,
          items: items.filter(
            (x: any) =>
              x[field.name] && moment(x[field.name]).diff(today, "days") == 1
          ),
        });

        output.push({
          key: "Upcoming week",
          value: "upcomingWeek",
          add: moment().add(2, "day").format("YYYY-MM-DD"),
          field,
          items: items.filter(
            (x: any) =>
              x[field.name] &&
              moment(x[field.name]).diff(today, "days") > 1 &&
              moment(x[field.name]).diff(today, "days") <= 7
          ),
        });

        output.push({
          key: "Upcoming month",
          value: "upcomingMonth",
          add: moment().add(8, "day").format("YYYY-MM-DD"),
          field,
          items: items.filter(
            (x: any) =>
              x[field.name] &&
              moment(x[field.name]).diff(today, "days") > 7 &&
              moment(x[field.name]).diff(today, "days") <= 31
          ),
        });

        output.push({
          key: "Future",
          value: "future",
          add: moment().add(32, "day").format("YYYY-MM-DD"),
          field,
          items: items.filter(
            (x: any) =>
              x[field.name] && moment(x[field.name]).diff(today, "days") > 31
          ),
        });

        output.push({
          key: "Not set",
          value: "notSet",
          add: "",
          field,
          items: items.filter((x: any) => !x[field.name]),
        });
      }

      return output
        .map((o: any) => {
          return {
            ...o,
            itemsCount: o.items.filter((x: any) => !x.parent).length,
          };
        })
        .filter((o: any) => !this.settings.hideEmptyGroups || o.itemsCount > 0);
    },
    getGroupingFields() {
      const fields = this.storageEntity.fields.filter(
        (x: any) =>
          (x.type == FieldType.Relationship && x.relationship.writeMultiple) ||
          ["dropdown", "date", "datetime", "createdAt", "updatedAt"].includes(
            x.type
          )
      );
      fields.push({
        label: "No grouping",
        name: "",
      });
      // console.log("grouping", fields);
      return fields;
    },
    isFieldHidden(field: Field): boolean {
      if (this.settings.fieldsHidden) {
        return this.settings.fieldsHidden.includes(field.name);
      } else {
        return false;
      }
    },
    async switchFieldHidden(field: Field, event: any) {
      if (!event) {
        if (!this.settings.fieldsHidden) {
          this.settings.fieldsHidden = [];
        }
        this.settings.fieldsHidden.push(field.name);
      } else {
        this.settings.fieldsHidden = this.settings.fieldsHidden.filter(
          (x: string) => x != field.name
        );
      }
      // console.log("switchFieldHidden", event, this.settings);
      await this.updateSettings();
    },
    async resetView() {
      await entities.tableSettings.remove(this.settings);
      await this.loadTableSettings();
    },
    runScript(s: any) {
      try {
        runUserCode(s.content, {
          dataset: this.storageEntity.dataset,
        });
      } catch (e) {
        return e;
      }
    },
    getOpenedGroupsFromSettings() {
      if (
        !this.settings.groupsOpened ||
        this.settings.groupsOpened.length == 0
      ) {
        return [];
      }
      // todo extract groups to data() and compute it only once
      const groups = this.getGroupedItems(
        this.storageEntity.items,
        this.settings.groupBy
      );

      const output = [];
      for (let i = 0; i < groups.length; i++) {
        if (this.settings.groupsOpened.includes(groups[i].value)) {
          output.push(i);
        }
      }

      return output;
    },
    async groupsOpenedOnChange(e: any) {
      console.log("groupsOpenedOnChange", e);

      const groups = this.getGroupedItems(
        this.storageEntity.items,
        this.settings.groupBy
      );

      this.settings.groupsOpened = e.map((x: number) => groups[x].value);

      await this.updateSettings();
    },
  },
  computed: {
    isDev() {
      return window.location.port == "8080";
    },
  },
});
