


































import Vue from "vue";
import { entities } from "@/entities";

export default Vue.extend({
  name: "DatasetSelectorDialog",
  components: {},
  data() {
    return {
      show: false,
      selected: null as any,
      items: [] as any[],
    };
  },
  async created() {
    await entities.datasets.refresh();
    this.items = entities.datasets.items;
  },
  methods: {
    select(item: any) {
      this.show = false;
      if (item && this.$route.params.id != item.name) {
        this.$router.push(`/datasets/${item.name}`);
      }
    },
  },
});
