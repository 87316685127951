import { regexParserExtension } from './regex-parser-extension';
import { HighlightStyle, syntaxHighlighting } from "@codemirror/language"
import { vueInlineComponentPlugin } from './vue-inline-component-plugin.ts';
import UrlWidget from "@/components/codemirror-plugins/UrlWidget.vue";

// TODO change to "URL" after fixing ixora plugin
const markdownExtension = regexParserExtension("url", /()(https?:\/\/[-a-zA-Z0-9()@:%-_+.~#?&//=]*[^)\s]+)()/, "Link")

const highlightStylePlugin = syntaxHighlighting(HighlightStyle.define([
  {
    tag: markdownExtension.resolveTag,
    color: "blue",
    textDecoration: "underline"
  },
  {
    tag: markdownExtension.markTag,
    color: "#999",
    textDecoration: "none",
  }
]));

const componentPlugin = vueInlineComponentPlugin(markdownExtension.delimResolveName, markdownExtension.delimMarkName, UrlWidget)

export const urlLink = {
  ...markdownExtension,
  highlightStylePlugin,
  componentPlugin,
}