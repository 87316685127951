











import Vue from "vue";
import WidgetFrame from '@/components/widgets/WidgetFrame.vue';
import Calendar from '@/components/Calendar.vue';

export default Vue.extend({
  name: "CalendarWidget",
  components: {
    WidgetFrame,
    Calendar,
  },
  props: [],
  data() {
    return {};
  },
  methods: {},
});
