




















import Vue from "vue";
import HabitsTable from "@/components/HabitsTable.vue";

export default Vue.extend({
  name: "HabitsRoutinesWidget",
  components: {
    HabitsTable,
  },
  props: ["maxDays", "fromDate", "toDate", "filterHomeScreen"],
});
