



import Vue from "vue";

export default Vue.extend({
  name: "UrlWidget",
  components: {},
  props: ["value"],
  computed: {
    displayValue() : string {
      const match = this.value.match(
        /\[(?<name>[^\]|]+)\]\((?<href>[^)]+)\)/
      );
      if (!match) {
        return this.value;
      }

      return match[1];
    },
    href() : string {
      const match = this.value.match(
        /\[(?<name>[^\]|]+)\]\((?<href>[^)]+)\)/
      );
      if (!match) {
        return this.value;
      }

      return match[2];
    }
  }
});
