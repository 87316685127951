

























import Vue from "vue";
import Calendar from '@/components/Calendar.vue';

export default Vue.extend({
  name: "SidebarRight",
  components: {
    Calendar,
  },
  props: [],
  data() {
    return {
      tabs: [
        {
          title: "Calendar",
          icon: "mdi-calendar-outline",
        },
        // {
        //   title: "Tags",
        //   icon: "mdi-tag-multiple-outline",
        // },
      ],
    };
  },
  methods: {},
});
