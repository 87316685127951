var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.parentItems,"headers":_vm.headers,"hide-default-footer":_vm.parentItems.length <= 100,"items-per-page":_vm.parentItems.length <= 100 ? -1 : 100,"custom-filter":_vm.customFilter,"search":"n/a","multi-sort":"","loading":_vm.storageEntity.loading && _vm.storageEntity.items == 0,"item-key":"_id","sort-by":_vm.settings.sortBy,"sort-desc":_vm.settings.sortDesc,"hide-default-header":_vm.settings.hideHeaders,"dense":_vm.settings.dense},on:{"update:options":function($event){return _vm.$emit('optionsUpdated', $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('internal-row',{ref:item._id,attrs:{"storageEntity":_vm.storageEntity,"item":item,"isExpanded":isExpanded,"expand":expand,"expandedField":_vm.expandedFields[item._id],"fields":_vm.filteredFields,"parentField":_vm.parentField,"level":0},on:{"expand":function($event){return _vm.expandByField($event, item, expand)},"itemUpdated":_vm.itemsManager.updateItem,"remove":_vm.itemsManager.remove,"add":_vm.add,"promoteToItem":_vm.promoteToItem}})]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [(_vm.fields.some(function (f) { return f.summaryType; }))?_c('tr',{staticClass:"summary"},_vm._l((_vm.filteredFields),function(field){return _c('th',{key:field.name,style:(("text-align: " + (field.textAlign)))},[_vm._v(" "+_vm._s(field.summaryType ? _vm.summary(field) : " ")+" ")])}),0):_vm._e(),(!_vm.hideNewItemRow)?_c('tr',{staticClass:"add"},[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":headers.length}},[(!_vm.addingInProgress)?_c('v-btn',{attrs:{"color":"primary","x-small":"","text":""},on:{"click":function($event){return _vm.add()}}},[_vm._v(" New item "),_c('v-icon',{attrs:{"right":"","x-small":""}},[_vm._v("mdi-plus-circle-outline")])],1):_vm._e(),(_vm.addingInProgress)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e()],1)]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"expandedRow",attrs:{"colspan":headers.length}},[_vm._t("row",null,{"item":item,"expandedField":_vm.expandedFields[item._id]})],2)]}}],null,true)}),_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', 'i']),expression:"['ctrl', 'i']"}],on:{"shortkey":function($event){return _vm.add()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }