var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.field.relationship)?_c('span',[_vm._v("Relationship not configured")]):_vm._e(),(_vm.field.relationship && !_vm.field.relationship.multiple)?_c('v-autocomplete',{attrs:{"auto-select-first":"","value":_vm.relationships.getValueSingle(_vm.item, _vm.field),"items":_vm.items,"hide-details":"","search-input":_vm.searchInput,"dense":"","flat":"","return-object":"","item-text":_vm.field.relationship.property},on:{"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event},"change":function($event){_vm.relationships.updateRelationships(_vm.item, _vm.field, [$event], _vm.storageEntity);
      _vm.searchInput = '';}},scopedSlots:_vm._u([(!_vm.field.format || _vm.field.format.showHierarchy)?{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"v-list-item__content"},[_c('div',{staticClass:"v-list-item__title"},[_vm._v(" "+_vm._s(_vm.getParentPrefix(item))+" "+_vm._s(item.name)+" ")])])]}}:null,(!_vm.field.format || !_vm.field.format.disableChips)?{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.openItemDialog(item)}}},[_vm._v(_vm._s(item[_vm.field.relationship.property])+" ")])]}}:null],null,true)}):_vm._e(),(_vm.field.relationship && _vm.field.relationship.multiple)?_c('v-autocomplete',{attrs:{"value":_vm.relationships.getValueMultiple(_vm.item, _vm.field),"items":_vm.items,"hide-details":"","search-input":_vm.searchInput,"dense":"","small-chips":"","flat":"","multiple":"","return-object":"","prepend-icon":_vm.singleItemView
        ? ''
        : !_vm.expandedField || _vm.expandedField.name != _vm.field.name
        ? 'mdi-chevron-down'
        : 'mdi-chevron-up',"item-text":_vm.field.relationship.property},on:{"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event},"click:prepend":function($event){return _vm.$emit('expand', _vm.field)},"change":function($event){_vm.relationships.updateRelationships(_vm.item, _vm.field, $event, _vm.storageEntity);
      _vm.searchInput = '';}},scopedSlots:_vm._u([(!_vm.field.format || _vm.field.format.showHierarchy)?{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"v-list-item__content"},[_c('div',{staticClass:"v-list-item__title"},[_vm._v(" "+_vm._s(_vm.getParentPrefix(item))+" "+_vm._s(item.name)+" ")])])]}}:null,{key:"selection",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [(
          index <
          (_vm.field.format && _vm.field.format.maxItems ? _vm.field.format.maxItems : 10)
        )?_c('v-chip',{attrs:{"small":"","label":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.openItemDialog(item)}}},[_vm._v(_vm._s(item[_vm.field.relationship.property])+" ")]):_vm._e()]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }