













































































import { itemsManager } from "@/items";
import { PowerStorage } from "@/powerStorage";
import moment from "moment";
import Vue from "vue";
import { ProxiedDatasetItem } from '@/sync/ProxiedDataset';
import { eventBus } from '@/bus';

export default Vue.extend({
  name: "NotesFileList",
  components: {},
  props: ["items", "hideMenu"],
  data() {
    return {};
  },
  methods: {
    openItemDialog(item: ProxiedDatasetItem) {
      eventBus.$emit("openItemDialog", item);
    },
    momentFrom(timestamp: any) {
      return moment(timestamp).fromNow();
    },
    none() {
      return; // just a hack for click
    },
    setFavorite(item: any, status: boolean) {
      itemsManager.updateItem(
        item,
        PowerStorage.create(item._dataset).getFieldByName("favorite")!,
        status
      );
    },
  },
});
