









































import { settings } from "@/entities";
import { PowerStorage } from "@/powerStorage";
import Vue from "vue";
import DatasetSelectorEditor from "./DatasetSelectorEditor.vue";
import { Field } from '@/entities/field';

export default Vue.extend({
  name: "QuickAddDialog",
  components: { DatasetSelectorEditor },
  data() {
    return {
      show: false,
      name: "",
      dataset: "",
    };
  },
  async created() {
    await settings.load();
    this.reloadSettings();
  },
  methods: {
    reloadSettings() {
      this.dataset = settings.get("quickAddDefaultDataset");
    },
    async add() {
      const storage = PowerStorage.create(this.dataset);

      const obj = Field.createItemWithDefaults(
        { name: this.name },
        storage.fields
      );

      await storage.save(obj);

      this.name = "";
      this.show = false;
    },
  },
});
