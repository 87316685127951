import { MarkdownExtension, DelimiterType } from '@lezer/markdown';
import { styleTags, Tag, tags } from '@lezer/highlight';
import { HighlightStyle, syntaxHighlighting } from "@codemirror/language"

export const regexParserExtension = (name: string, matchPattern: RegExp, before = "Link", skipMatchPattern?: RegExp) => {
  const delimResolveName = name.charAt(0).toUpperCase() + name.slice(1);
  const delimMarkName = delimResolveName + "Mark";

  const resolveTag = Tag.define();
  const markTag = Tag.define();

  const delim: DelimiterType = {
    resolve: delimResolveName,
    mark: delimMarkName,
  };

  const highlightStylePlugin = syntaxHighlighting(HighlightStyle.define([
    {
      tag: resolveTag,
      color: "orange",
      textDecoration: "none"
    },
    {
      tag: markTag,
      color: "#999",
      textDecoration: "none",
    }
  ]));

  let skipChars = 0;
  const markdownExtension: MarkdownExtension = {
    defineNodes: [delimResolveName, delimMarkName],
    props: [
      styleTags({
        [delimMarkName]: markTag, // tags.processingInstruction,
        [delimResolveName]: resolveTag,
      }),
    ],
    parseInline: [
      {
        before: before,
        name: delimResolveName,
        parse: (cx, next, pos) => {
          if (skipChars > 0) {
            skipChars--;
            return -1;
          }

          const textPart = cx.text.slice(pos - cx.offset);
          if (skipMatchPattern) {
            const match = textPart.match(skipMatchPattern);
            if (match && match.index == 0) {
              skipChars = match[0].length;
              return -1;
            }
          }

          const match = textPart.match(matchPattern);
          if (!match) {
            return -1;
          }
          if (match.index && match.index > 0) {
            return -1;
          }
          // if (match[0].startsWith(" ")) // special case for some regexes like attachment
          // {
          //   return -1;
          // }

          // const matchOffset = textPart.indexOf(match[1]);
          const textPos = pos + match[1].length; //+ matchOffset;

          skipChars = match[0].length - 1;

          // console.log(cx.text, pos, pos - cx.offset, match, textPos);

          cx.addDelimiter(delim, textPos - match[1].length, textPos, true, false);
          cx.addDelimiter(delim, textPos + match[2].length, textPos + match[2].length + match[3].length, false, true);

          return -1;
        }
      }
    ]
  };

  return {
    highlightStylePlugin,
    markdownExtension,
    resolveTag,
    markTag,
    delimResolveName,
    delimMarkName,
  };
}